import React from 'react';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import quizUrl from './urls';

const Quiz = React.lazy(() => import(/* webpackChunkName: "Quiz" */ '.'));

const route = <AuthenticatedLayoutRoute path={`${quizUrl}/:slug/:id`} exact component={Quiz} />;

const generateQuizRoute = (slug, id) => `${quizUrl}/${slug}/${btoa(id)}`;

export { generateQuizRoute };

export default route;
