import React, { useState } from 'react';
import { Translation } from '../../components/Translation';
import RESOURCE_TYPES from './helpers/constants';
import {
    ResourcesWrapper,
    ContentSection,
    DisplayDataSection,
    // FiltersSection,
    HeadingText,
    SubheadingText,
    TilesSection,
    BodyWrapper,
    ColoredText,
} from './index.styled';
import TileData from './components/TilePage';
import LandingPage from './components/LandingPage';
import BoxWithIconAndText from './components/BoxWithIconAndText';

const TsResources = () => {
    const [selectedResourceTile, setSelectedResourceTile] = useState(null);

    const handleTileClick = val => {
        if (val === selectedResourceTile) {
            setSelectedResourceTile(null);
        } else {
            setSelectedResourceTile(val);
        }
    };

    return (
        <ResourcesWrapper>
            <HeadingText>
                <Translation translationKey="ts_resources.heading" defaultValue="Resources" />
            </HeadingText>
            <BodyWrapper>
                <ContentSection>
                    <SubheadingText>
                        <Translation translationKey="ts_resources.subheading1" defaultValue="What can we help you" />
                        <ColoredText>
                            <Translation translationKey="ts_resources.subheading2" defaultValue="find?" />
                        </ColoredText>
                    </SubheadingText>
                    <TilesSection>
                        {RESOURCE_TYPES.map(({ label, value, icon }) => (
                            <BoxWithIconAndText
                                key={value}
                                icon={icon}
                                label={label}
                                value={value}
                                isSelected={value === selectedResourceTile}
                                handleTileClick={handleTileClick}
                            />
                        ))}
                    </TilesSection>
                    <DisplayDataSection>
                        {selectedResourceTile !== null ? (
                            <TileData resourceType={selectedResourceTile} />
                        ) : (
                            <LandingPage />
                        )}
                    </DisplayDataSection>
                </ContentSection>
                {/* <FiltersSection>
                    <h3>Filters</h3>
                </FiltersSection> */}
            </BodyWrapper>
        </ResourcesWrapper>
    );
};

export default TsResources;
