import store from '../../store/store';

export const setHeader = ({ headers = {} }) => ({
    headers: {
        ...headers,
        'x-territory': store.getState().subscriptions.territory.name,
    },
});

export default (operation, forward) => {
    const hasTerritory = store.getState().subscriptions && store.getState().subscriptions.territory;
    if (hasTerritory) {
        operation.setContext(setHeader);
    }
    return forward(operation);
};
