import store from '../../store/store';

export const setHeader = ({ headers = {} }) => ({
    headers: {
        ...headers,
        'x-pva-feature-flag': store.getState().featureFlags.productVariantAutomationFF,
    },
});

export default (operation, forward) => {
    const hasPVAFeatureFlag = store.getState().featureFlags && store.getState().featureFlags.productVariantAutomationFF;
    if (hasPVAFeatureFlag) {
        operation.setContext(setHeader);
    }
    return forward(operation);
};
