export const actions = {
    SET_MESSAGE: 'SET_MESSAGE',
    REMOVE_MESSAGE: 'REMOVE_MESSAGE',
};

export const setMessage = value => ({
    type: actions.SET_MESSAGE,
    value,
});

export const removeMessage = value => ({
    type: actions.REMOVE_MESSAGE,
    value,
});
