import React from 'react';
import { AsyncComponent } from '@twigeducation/async-component';
import LayoutRoute from '../../layout/LayoutRoute';
import AsyncComponentError from '../../components/Error/AsyncComponentError';

const AssessmentReports = props => (
    <AsyncComponent
        appName="ts-assessment-reports-mfe"
        componentName="AssessmentReports"
        errorComponent={AsyncComponentError}
        {...props}
    />
);

const routes = [
    <LayoutRoute
        key="/assessment-reports"
        path="/assessment-reports"
        component={() => <AssessmentReports />}
        withConstellation={false}
    />,
];

export default routes;
