import React from 'react';
import Route from '../../layout/AuthenticatedLayoutRoute';
import studentDashboardUrl from './urls';

const StudentDashboard = React.lazy(() => import(/* webpackChunkName: "StudentDashboard" */ '.'));

const allowedRoles = ['STUDENT', 'SUPERUSER'];

const routes = [
    <Route
        path={studentDashboardUrl}
        exact
        component={StudentDashboard}
        key={StudentDashboard}
        roles={allowedRoles}
        withConstellation={false}
        withSwoosh
    />,
    <Route
        path={`${studentDashboardUrl}/:gradeId`}
        exact
        component={StudentDashboard}
        key={StudentDashboard}
        roles={allowedRoles}
        withConstellation={false}
        withSwoosh
    />,
    <Route
        path={`${studentDashboardUrl}/:gradeId/:classRosterId`}
        exact
        component={StudentDashboard}
        key={StudentDashboard}
        roles={allowedRoles}
        withConstellation={false}
        withSwoosh
    />,
];

export default routes;
