export const actions = {
    UNSAVED_ANSWERS_CONFIRMATION_DIALOG_OPEN: 'UNSAVED_ANSWERS_CONFIRMATION_DIALOG_OPEN',
    UNSAVED_ANSWERS_CONFIRMATION_DIALOG_CLOSE: 'UNSAVED_ANSWERS_CONFIRMATION_DIALOG_CLOSE',
};

export const unsavedAnswersConfirmationDialogOpen = () => ({
    type: actions.UNSAVED_ANSWERS_CONFIRMATION_DIALOG_OPEN,
});

export const unsavedAnswersConfirmationDialogClose = () => ({
    type: actions.UNSAVED_ANSWERS_CONFIRMATION_DIALOG_CLOSE,
});
