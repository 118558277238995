import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dropdown, LoadingPlaceholder, Pill } from '@twigeducation/ts-fe-components';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../hooks/useTranslation';
import contentsExplorerUrl from './urls';
import {
    StyledContainer,
    StyledContents,
    DropdownsContainer,
    StyledContentExplorer,
    StyledContentBackground,
} from './ContentsExplorer.styles';
import TableOfContents from './components/TableOfContents';
import { generateDropdownOptions, defaultModule } from '../helpers';
import ContentMissing from '../LessonExplorer/ContentMissing';
import { gradeSummaryQuery } from './ContentsExplorer.query';

const ContentsExplorer = () => {
    const { t } = useTranslation();
    const { gradeId, moduleId } = useParams();
    const [gradeFilter, setGradeFilter] = useState(null);
    const [moduleFilter, setModuleFilter] = useState(null);
    const defaultLanguage = useSelector(state => state.i18n.primaryLanguage);
    const { data, loading } = useQuery(gradeSummaryQuery, {
        variables: {
            language: defaultLanguage,
        },
        fetchPolicy: 'no-cache',
    });
    const options = !loading && generateDropdownOptions(data?.TSGradeSummaries, t);
    const history = useHistory();
    useEffect(() => {
        if (!loading && gradeId) {
            setGradeFilter(options?.find(grade => grade?.value === gradeId));
        }
    }, [loading, defaultLanguage]);
    useEffect(() => {
        // to update module when language changes
        if (gradeFilter && moduleId) {
            const modulePackId = moduleId?.slice(4, moduleId?.length);
            const newModule = gradeFilter?.modules?.find(module => {
                const newModuleId = defaultLanguage === 'es-US' ? `USES${modulePackId}` : `USEN${modulePackId}`;
                return newModuleId === module?.value;
            });
            setModuleFilter(newModule);
        }
    }, [gradeFilter]);

    useEffect(() => {
        if (!loading && data?.TSGradeSummaries) {
            // to update dropdown values when URL changes
            if (gradeId && gradeFilter === null) {
                setGradeFilter(options?.find(grade => grade?.value === gradeId));
            }
            if (moduleId && gradeFilter !== null && moduleFilter === null) {
                setModuleFilter(gradeFilter?.modules?.find(module => module?.value === moduleId));
            }
            // to update URL when dropdown values change
            if (moduleFilter && moduleFilter?.value !== moduleId) {
                history.push(`${contentsExplorerUrl}/${gradeFilter?.value}/${moduleFilter?.value}`);
            }
            if (gradeFilter && gradeFilter?.value !== gradeId) {
                history.push(`${contentsExplorerUrl}/${gradeFilter?.value}`);
            }
        }
    }, [gradeFilter, moduleFilter, data, loading]);
    const isError = (gradeId && gradeId !== gradeFilter?.value) || (moduleId && moduleId !== moduleFilter?.value);
    const isTableOfContents = gradeFilter && moduleFilter;
    if (loading) {
        return <LoadingPlaceholder />;
    }
    return (
        <StyledContainer>
            <StyledContents>
                <h1>{t('contents_explorer.title', 'Contents Explorer')}</h1>
                <Pill />
                <DropdownsContainer>
                    <Dropdown
                        placeholder="Please select a Grade"
                        value={gradeFilter ? options?.find(option => option?.value === gradeFilter?.value) : options[0]}
                        onChange={option => setGradeFilter(options?.find(grade => grade?.value === option?.value))}
                        options={options}
                        hideSelectedOptions
                        aria-label="Grades Dropdown"
                    />
                    {gradeFilter && (
                        <Dropdown
                            placeholder="Please select a Module"
                            value={
                                moduleFilter
                                    ? gradeFilter.modules.find(option => option?.value === moduleFilter?.value)
                                    : defaultModule(gradeFilter?.color)
                            }
                            onChange={option =>
                                setModuleFilter(gradeFilter?.modules?.find(module => module?.value === option?.value))
                            }
                            options={gradeFilter?.modules}
                            hideSelectedOptions
                            aria-label="Modules Dropdown"
                        />
                    )}
                </DropdownsContainer>
            </StyledContents>
            {!loading && isError && <ContentMissing />}
            <StyledContentBackground>
                <StyledContentExplorer>
                    {isTableOfContents && (
                        <TableOfContents
                            gradeId={gradeFilter?.value}
                            moduleId={moduleFilter?.value}
                            versionId={gradeFilter?.versionId}
                        />
                    )}
                </StyledContentExplorer>
            </StyledContentBackground>
        </StyledContainer>
    );
};
export default ContentsExplorer;
