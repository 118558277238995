import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { LoadingPlaceholder } from '@twigeducation/ts-fe-components';
import { CloudinaryImageWithInterchange } from '@twigeducation/react-cloudinary-image';
import { PageError, NotFound } from '@twigeducation/allsorts';
import getGameType from '../../../../../apps/Game/helpers/getGameType';
import DownloadButton from '../../../../DownloadButton';
import { getDownloadVisualOptions } from '../../../../../apps/Visual/Visual';
import assetProptypes from './assetProptypes';
import AssetComponentRenderer from './AssetComponentRenderer';
import GameProvider from '../../../../GameProvider';
import QuizProvider from '../../../../QuizProvider';
import AudioProvider from '../../../../AudioProvider';
import VideoProvider from '../../../../VideoProvider';
import Transcripts from '../../../../Transcripts';
import useElementSize from '../../../../../hooks/useElementSize';
import { useTranslation } from '../../../../../hooks/useTranslation';
import './scss/AssetModalContent.scss';

const StyledTranscripts = styled(Transcripts)`
    border-top: 1px solid #eee;
    padding: 0 2rem;
    margin: 2rem -2rem 0 -2rem;
`;

const AssetModalContent = ({ asset: assetInfo, maxHeight, setIsisFullScreenReadAloud }) => {
    const supportedLanguages = useSelector(store => store.i18n.supportedLanguages);
    const [Component, setComponent] = useState(() => () => <LoadingPlaceholder />);
    const [transcripts, setTranscripts] = useState([]);
    const transcriptsRef = useRef({});
    transcriptsRef.current = transcripts;
    const modalRef = useRef(null);
    const modalSize = useElementSize(modalRef);
    const videoHeight = modalSize.width ? (modalSize.width * 9) / 16 : 400;

    const getComponentWrapperStyle = mediaType =>
        mediaType === 'film' ? { height: `${videoHeight}px`, maxHeight } : { height: '100%' };

    const { t } = useTranslation();

    const getAssetComponent = async (mediaType, id) => {
        let AssetComponent;

        switch (mediaType) {
            case 'activitySheet':
            case 'file': {
                const module = await import('../../../../FileProvider');
                const FileProvider = module.default;

                // eslint-disable-next-line react/prop-types
                AssetComponent = ({ asset }) => (
                    <div className="asset-modal-content__main-file">
                        <FileProvider
                            id={id}
                            title={id === asset.id ? asset.title : null}
                            fileUrl={id === asset.id ? asset.fileUrl : null}
                            displayedInModal
                            setIsisFullScreenReadAloud={setIsisFullScreenReadAloud}
                        />
                    </div>
                );

                break;
            }
            case 'audio': {
                AssetComponent = () => (
                    <div className="asset-modal__audio">
                        <AudioProvider id={id} verticalResponsive />
                    </div>
                );
                break;
            }
            case 'film': {
                AssetComponent = () => (
                    <VideoProvider
                        id={id}
                        Loading={() => <LoadingPlaceholder />}
                        NotFound={NotFound}
                        onDataLoaded={film => {
                            const filmTranscripts = [
                                ...film.alternativeVersions.map(version => ({
                                    id: version.id,
                                    languageCode: version.language.code,
                                    languageName: version.language.name,
                                    transcript: version.transcript,
                                })),
                            ].filter(transcript => transcript.transcript !== '');
                            if (film.transcript) {
                                filmTranscripts.unshift({
                                    id: film.id,
                                    languageCode: film.language.code,
                                    languageName: film.language.name,
                                    transcript: film.transcript,
                                });
                            }
                            if (transcriptsRef.current.length === 0 && filmTranscripts.length !== 0) {
                                setTranscripts(filmTranscripts);
                            }
                        }}
                        PageError={PageError}
                        theme={{ focusColor: '#ff126d' }}
                        verticalResponsive
                    />
                );
                break;
            }
            case 'game': {
                AssetComponent = ({ asset }) => (
                    <GameProvider
                        id={id}
                        type={getGameType(asset.classification.contentCategory)}
                        Loading={() => <LoadingPlaceholder />}
                        NotFound={NotFound}
                        PageError={PageError}
                        staticFilePath={window.config.GAME_FILE_PATH}
                    />
                );
                break;
            }
            case 'interactive': {
                const module = await import('../../../../InteractiveProvider');
                const InteractiveProvider = module.default;

                AssetComponent = () => (
                    <div className="asset-modal__interactive">
                        <InteractiveProvider id={id} />
                    </div>
                );
                break;
            }
            case 'quiz': {
                AssetComponent = () => (
                    <QuizProvider
                        id={id}
                        Loading={() => <LoadingPlaceholder />}
                        NotFound={NotFound}
                        PageError={PageError}
                        staticFilePath={window.config.QUIZ_FILE_PATH}
                    />
                );
                break;
            }
            case 'image': {
                // eslint-disable-next-line react/prop-types
                AssetComponent = ({ asset }) => (
                    <div className="asset-modal__image">
                        <CloudinaryImageWithInterchange asset={asset} />
                    </div>
                );
                break;
            }
            case 'visual': {
                const { VisualProvider } = await import('@twigeducation/react-visuals');
                const { cloudinary } = await import('@twigeducation/react-cloudinary-image');

                // eslint-disable-next-line react/prop-types
                AssetComponent = ({ asset }) => {
                    const downloadVisualUrl = image =>
                        cloudinary.url(
                            image.cloudinary.publicId,
                            getDownloadVisualOptions(image.cloudinary.overlay.watermark),
                        );
                    const downloadButton = image => (
                        <DownloadButton
                            fileUrl={downloadVisualUrl(image)}
                            title={image.title}
                            fileExtension="jpg"
                            className="asset-modal__download-button"
                            buttonText={t('general.visual_provider_download_btn', 'Download')}
                        />
                    );
                    return (
                        <div className="asset-modal__visual" data-at="asset-modal__visual-wrapper">
                            <VisualProvider
                                id={id}
                                type={asset.classification.contentCategory}
                                Loading={() => <LoadingPlaceholder />}
                                NotFound={NotFound}
                                PageError={PageError}
                                options={{ crop: 'fit' }}
                                download={downloadButton}
                            />
                        </div>
                    );
                };
                break;
            }
            default:
                AssetComponent = () => <div data-at="asset-modal__not-found">Classification not found</div>;
                break;
        }
        return AssetComponent;
    };

    useEffect(() => {
        let Comp;
        try {
            // eslint-disable-next-line react/prop-types
            Comp = ({ asset, versionId }) => (
                <AssetComponentRenderer asset={asset} versionId={versionId} getAssetComponent={getAssetComponent} />
            );
        } catch (e) {
            Comp = () => <div data-at="asset-modal__error">Sorry, there was a problem loading this asset.</div>;
        }
        setComponent(() => Comp);
    }, [assetInfo.id]);

    if (assetInfo.alternativeVersions && assetInfo.alternativeVersions.length > 0) {
        return (
            <div className="asset-modal-content" data-at="asset-modal-tabs-wrapper" ref={modalRef}>
                <Tabs
                    defaultIndex={assetInfo.language ? 0 : 1}
                    selectedTabPanelClassName="asset-modal-content__tabs-panel--selected"
                    className="asset-modal-content__tabs"
                    data-at="asset-modal-tabs"
                >
                    <div className="asset-modal-content__tabs-wrapper">
                        <TabPanel data-at="asset-modal-tabs__panel">
                            <div style={getComponentWrapperStyle(assetInfo.classification.mediaType)}>
                                <Component asset={assetInfo} versionId={assetInfo.id} />
                            </div>
                        </TabPanel>
                        {assetInfo.alternativeVersions.map(({ versionId }) => (
                            <TabPanel key={versionId} data-at="asset-modal-tabs__panel">
                                <div style={getComponentWrapperStyle(assetInfo.classification.mediaType)}>
                                    <Component asset={assetInfo} versionId={versionId} />
                                </div>
                            </TabPanel>
                        ))}
                        <TabList className="asset-modal-content__tabs-list" data-at="asset-modal-tabs__list">
                            <Tab className="asset-modal-content__tabs-tab" data-at="asset-modal-tabs__item">
                                {assetInfo.language.englishName}
                            </Tab>
                            {assetInfo.alternativeVersions
                                .filter(alternateVersion =>
                                    supportedLanguages.some(
                                        supportedLanguage => supportedLanguage.code === alternateVersion.ietfTag,
                                    ),
                                )
                                .map(alternativeVersion => (
                                    <Tab
                                        key={alternativeVersion.versionId}
                                        className="asset-modal-content__tabs-tab"
                                        data-at="asset-modal-tabs__item"
                                    >
                                        {alternativeVersion.language.englishName}
                                    </Tab>
                                ))}
                        </TabList>
                    </div>
                </Tabs>
                {assetInfo.classification.mediaType === 'film' && (
                    <StyledTranscripts transcripts={transcripts} film={{ id: assetInfo.id, slug: assetInfo.slug }} />
                )}
            </div>
        );
    }

    return (
        <div className="asset-modal-content" data-at="asset-modal-content" ref={modalRef}>
            <div style={getComponentWrapperStyle(assetInfo.classification.mediaType)}>
                <Component asset={assetInfo} versionId={assetInfo.id} />
            </div>
            {assetInfo.classification.mediaType === 'film' && (
                <StyledTranscripts transcripts={transcripts} film={{ id: assetInfo.id, slug: assetInfo.slug }} />
            )}
        </div>
    );
};

AssetModalContent.defaultProps = {
    maxHeight: '75vh',
    setIsisFullScreenReadAloud: () => {},
};

AssetModalContent.propTypes = {
    asset: assetProptypes.isRequired,
    maxHeight: PropTypes.string,
    setIsisFullScreenReadAloud: PropTypes.func,
};

export default AssetModalContent;
