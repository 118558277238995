// eslint-disable-next-line import/no-extraneous-dependencies
import UAParser from 'ua-parser-js';
import React from 'react';
import { browserChecker, checkifTablet } from '../Helpers/HelperFunctions';
import { Translation } from '../../../components/Translation';

function getBrowserMessage() {
    const browserSupport = [
        <li key="Chrome">
            Google Chrome™ 90 or{' '}
            <a href="https://www.google.com/intl/en_us/chrome/update/" target="_blank" rel="noreferrer">
                newer
            </a>
        </li>,
        <li key="Firefox">
            Firefox™ 85 or{' '}
            <a
                href="https://support.mozilla.org/en-US/kb/update-firefox-latest-release"
                target="_blank"
                rel="noreferrer"
            >
                newer
            </a>
        </li>,
        <li key="Edge">
            Microsoft® Edge versions 94 or{' '}
            <a
                href="https://support.microsoft.com/en-us/topic/microsoft-edge-update-settings-af8aaca2-1b69-4870-94fe-18822dbb7ef1"
                target="_blank"
                rel="noreferrer"
            >
                newer
            </a>
        </li>,
        <li key="Safari">
            Apple® Safari® 13.1 or{' '}
            <a href="https://support.apple.com/en-gb/118575" target="_blank" rel="noreferrer">
                newer
            </a>{' '}
            for iOS
        </li>,
        <li key="ChromeAndroid">
            Google Chrome™ for Android 86.0.4240.93 or{' '}
            <a href="https://www.google.com/intl/en_us/chrome/update/" target="_blank" rel="noreferrer">
                newer
            </a>
        </li>,
    ];
    return {
        heading: (
            <Translation
                translationKey="troubleshooter.fail.browser_header"
                defaultValue="Your browser is not supported"
            />
        ),
        body: (
            <div>
                <Translation
                    translationKey="troubleshooter.fail.browser_body.start"
                    defaultValue="The site will probably still work for you, but things may get ugly. We recommend using "
                />
                <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
                    <Translation translationKey="troubleshooter.fail.browser_body.link" defaultValue="Google Chrome" />
                </a>
                <Translation
                    translationKey="troubleshooter.fail.browser_body.end"
                    defaultValue=". We support the following browsers: "
                />
                <ul>{browserSupport}</ul>
            </div>
        ),
    };
}

async function browserMainCheck() {
    const parser = new UAParser();
    const result = parser.getResult();

    const deviceType = checkifTablet(result.ua) ? 'tablet' : 'desktop';
    const { name: browser, version: browserVersion } = result.browser;
    const [validBrowser, validBrowserVersion] = browserChecker(browser, browserVersion, deviceType);
    const hasError = !(validBrowserVersion && validBrowser);

    return {
        errorStatus: hasError,
        message: hasError ? getBrowserMessage() : null,
    };
}

export default browserMainCheck;
