import React from 'react';
import PropTypes from 'prop-types';
import { AsyncComponent } from '@twigeducation/async-component';
import { connect } from 'react-redux';
import AsyncComponentError from '../Error/AsyncComponentError';

const Footer = ({ territory, ...props }) => (
    <AsyncComponent
        appName="ts-footer"
        assessmentsPlatformUrl={window.config.ASSESSMENTS_PLATFORM_URL}
        componentName="Footer"
        errorComponent={AsyncComponentError}
        territory={territory}
        product="twigscienceK-6"
        {...props}
    />
);

Footer.defaultProps = {
    territory: 'unknown-territory',
};

Footer.propTypes = {
    territory: PropTypes.string,
};

export default connect(state => ({
    territory: state && state.subscriptions && state.subscriptions.territory && state.subscriptions.territory.name,
}))(Footer);
