import PropTypes from 'prop-types';

const assetProptypes = PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    fileUrl: PropTypes.string,
    classification: PropTypes.shape({
        mediaType: PropTypes.string,
        contentCategory: PropTypes.string,
    }),
    alternativeVersions: PropTypes.arrayOf(
        PropTypes.shape({
            ietfTag: PropTypes.string.isRequired,
            versionId: PropTypes.string.isRequired,
        }),
    ),
});

export default assetProptypes;
