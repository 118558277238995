import styled from 'styled-components';
import { Button as StyledButton, Panel as StyledPanel } from '@twigeducation/ts-fe-components';

export const Panel = styled(StyledPanel)`
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    border: ${props => `1px solid ${props.theme.formInputDisabledBackground}`};
    border-radius: 0.667rem;
    margin-bottom: 15px;
    min-width: 100%;
    padding: 1rem;
    font-family: 'Bariol';
`;

export const PanelMainContainer = styled.div`
    display: flex;
    min-height: 100%;
`;

export const DynamicContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin-top: 2rem;

    div {
        &:last-child a {
            width: 9.5rem;
        }
    }
`;
export const SpanishDynamicContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
`;
export const RightSection = styled.div`
    padding: 10px 0 10px 10px;
    font-size: 16px;
    width: 20%;
`;

export const LeftSection = styled.div`
    background-color: #f9f9f9;
    padding: 10px;
    min-height: 100%;
    position: relative;
    width: 80%;
    line-height: 1rem;
`;

export const PanelHead = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const HeadBreadcrumb = styled.div`
    margin-right: 15px;
`;

export const BreadcrumbItem = styled.button`
    border-radius: 5px;
    border: ${props => `1px solid ${props.theme.formInputDisabledBackground}`};
    color: #fff;
    padding: 5px 10px;
    background: ${props => props.theme.twigPinkColor};
    font-size: 16px;
    font-family: 'Bariol-bold';
`;

export const ContentTypeKey = styled.span`
    color: ${props => props.theme.twigPinkColor};
    font-size: 16px;
    font-style: italic;
    display: inline-block;
    margin-right: 5px;
`;

export const ContentKey = styled.span`
    color: ${props => props.theme.twigPinkColor};
    font-size: 16px;
    font-style: italic;
    display: inline-block;
    margin-right: 5px;
`;

export const ContentValue = styled.span`
    font-size: 16px;
    color: ${props => props.theme.primaryColor};
`;
export const ContentText = styled.span`
    font-size: 16px;
    color: ${props => props.theme.primaryColor};
    font-weight: bold;
`;
export const ContentType = styled.span`
    font-size: 16px;
    display: inline-flex;
    word-wrap: break-word;
    white-space: pre-wrap;
    color: ${props => props.theme.primaryColor};
`;
export const ContentValuePage = styled.span`
    font-size: 15px;
    color: ${props => props.theme.primaryColor};
    padding-bottom: 8px;
`;

export const StyledSpan = styled.span`
    font-size: 16px;
    color: ${props => props.theme.primaryColor};
    white-space: pre-wrap;
    font-style: italic;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const FlexChild = styled.div`
    flex: 1;
    padding: 0 5px;
    ul li {
        font-style: italic;
        line-height: 1rem;
    }
`;

export const PrimaryButton = styled(StyledButton)`
    margin-bottom: 8px;
    font-size: 16px;
    text-align: center;
    box-shadow: 0 1px 4px #00000099;
    font-weight: bold;
    padding: 7px;
    :focus {
        color: #fff;
        border-color: #128383;
        text-decoration: none;
    }
    &:hover {
        background-color: #38ba92;
        transform: none;
        color: #fff;
    }
`;

export const SecondaryButton = styled(PrimaryButton)`
    :focus {
        border-color: #128383;
        color: #128383;
        text-decoration: none;
    }
    margin-right: 5px;
    width: ${props => (props.language === 'es-US' ? '8.7rem' : '6.5rem')};
    font-size: ${props => (props.language === 'es-US' ? '12px' : '')};
    text-align: center;
    box-shadow: 0 1px 4px #00000099;
    white-space: nowrap;
`;

export const SecondaryButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ToolSection = styled.div`
    display: flex;
`;

export const ToolButton = styled(StyledButton)`
    height: 1rem;
    width: 1rem;
    padding: 0.7rem;
    margin-left: 5px;
    box-shadow: 0 1px 4px #00000099;
    :hover {
        background-color: #38ba92;
        transform: none;
        svg {
            fill: #fff;
        }
    }
`;

export const ToolButtonIcon = styled.div`
    min-height: 100%;
    margin: -5px;
`;
