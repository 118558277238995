import {
    ADD_TERRITORY,
    ADD_PRODUCT_VARIANT_CODE,
    ADD_CONTENT_ENTITLEMENT,
    ADD_TOCS_APP,
    ADD_PRODUCT_CONFIGURATION,
} from '../../actions/subscriptions/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case ADD_TERRITORY:
            return {
                ...state,
                territory: action.value,
            };
        case ADD_PRODUCT_VARIANT_CODE:
            return {
                ...state,
                productVariantCode: action.value,
            };
        case ADD_CONTENT_ENTITLEMENT:
            return {
                ...state,
                contentEntitlement: action.value,
            };
        case ADD_TOCS_APP:
            return {
                ...state,
                tocsApp: action.value,
            };
        case ADD_PRODUCT_CONFIGURATION:
            return {
                ...state,
                configuration: action.value,
            };
        default:
            return state;
    }
};
