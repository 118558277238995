import styled from 'styled-components';
import { Button as StyledButton } from '@twigeducation/ts-fe-components';

export const HeaderContainer = styled.div`
    border-radius: 5px;
    box-shadow: 0px 1px 4px #00000099;
    flex-wrap: wrap;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
    padding: 10px;
`;

export const HeadButton = styled(StyledButton)`
    margin: 0 auto;
    display: flex;
    border-radius: 12px;
    margin: 6.5px;
    height: 60px;
    align-items: center;
    word-break: keep-all;
    font-family: 'Bariol-bold';
    font-size: 16px;
    padding: 20px;
    background-color: ${props => (props.assessmentDataLength > 0 ? '#128381' : '#cccccc')};
    &:not(.disabled):hover {
        cursor: ${props => (props.assessmentDataLength > 0 ? 'pointer' : 'not-allowed')} !important;
    }
    &:hover {
        background-color: #38ba92;
        transform: none;
        color: #fff;
    }
`;
