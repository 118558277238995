import React from 'react';
import PropTypes from 'prop-types';
import IframePage from '../../IframePages';

const WIX_ROOT = 'https://www.go4.twigscience.com';

const SpanishStudentIFrame = ({ forwardedPath }) => {
    const target = forwardedPath || '/esv1';
    return <IframePage title="Español Estudiante" urls={{ default: `${WIX_ROOT}${target}` }} />;
};

SpanishStudentIFrame.defaultProps = {
    forwardedPath: null,
};

SpanishStudentIFrame.propTypes = {
    forwardedPath: PropTypes.string,
};

export default SpanishStudentIFrame;
