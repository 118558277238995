import Collapse from 'rc-collapse';
import styled from 'styled-components';
import { Arrow } from '@twigeducation/ts-fe-components';

const StyledArrow = styled(Arrow)`
    order: ${props => (props.arrowPosition !== 'left' ? 1 : 0)};
    margin: 0;
    margin-right: ${props => (props.arrowPosition === 'center' ? 'calc(50% - 36px)' : null)};
    align-self: end;
    bottom: -18px;
    box-shadow: -0.15rem 0.02rem 0.4rem 0.1rem #ccc;
`;

const ThemedCollapseContainer = styled(Collapse)`
    .rc-collapse-item {
        &:last-child {
            margin-bottom: ${props =>
                props.styles.lastPanelMarginBottom ? props.styles.lastPanelMarginBottom : '2.5rem'};
        }
    }
    .rc-collapse-header {
        cursor: pointer;
        display: flex;
        font-weight: bold;
        justify-content: space-between;
        align-items: center;
        border-bottom: ${props => props.styles.accordionLineStyle};
        margin: 1.1rem 0;
    }
`;
export { ThemedCollapseContainer, StyledArrow };
