import React from 'react';
import requirements from '../Assets/requirements.json';
import { Translation } from '../../../components/Translation';

function getResolutionFailureMessage() {
    return {
        heading: (
            <Translation
                defaultValue="Your Screen is too small"
                translationKey="troubleshooter.fail.resolution_header"
            />
        ),
        body: (
            <Translation
                translationKey="troubleshooter.fail.resolution_body"
                defaultValue='Your screen resolution or browser tab size is smaller than our recommended 1024 x 768 or higher for desktops, or at least 8" for tablets.'
            />
        ),
    };
}

async function ResolutionChecker() {
    const requirement = requirements.resolution;
    const { innerWidth: width, innerHeight: height } = window;
    const invalid = width < requirement[0] || height < requirement[1];

    return {
        errorStatus: invalid,
        message: invalid ? getResolutionFailureMessage() : null,
    };
}

export default ResolutionChecker;
