import React from 'react';
import { Switch } from 'react-router-dom';
import AccountRoutes from './apps/Accounts/routes';
import AssignedLessonsRoutes from './apps/AssignedLessons/routes';
import CreditsRoute from './apps/Credits/routes';
import DashboardRoute from './apps/Dashboard/routes';
import DrivingQuestionRoute from './apps/DrivingQuestion/routes';
import ModuleRoute from './apps/Module/routes';
import NotFoundRoute from './pages/NotFound/routes';
import FileViewerRoute from './apps/FileViewer/routes';
import GameRoute from './apps/Game/routes';
import ScienceGlossary from './apps/ScienceGlossary/routes';
import IframeRoutes from './apps/IframePages/routes';
import ImageRoute from './apps/Image/routes';
import InteractiveRoute from './apps/Interactive/routes';
import LessonExplorerRoute from './apps/LessonExplorer/routes';
import ContentExplorerRoute from './apps/ContentsExplorer/routes';
import AssessmentExplorerRoute from './apps/AssessmentsExplorer/routes';
import LessonObjectivesRoute from './apps/LessonObjectives/routes';
import LessonRoutes from './apps/Lesson/routes';
import QuizRoute from './apps/Quiz/routes';
import StudentDashboardRoute from './apps/StudentDashboard/routes';
import TeacherDashboardRoute from './apps/TeacherDashboard/routes';
import TwigAdminDashboardRoute from './apps/TwigAdminDashboard/routes';
import StudentLessonRoute from './apps/StudentLesson/routes';
import SubjectKnowledgeRoute from './apps/SubjectKnowledge/routes';
import VideoRoute from './apps/Video/routes';
import VisualRoute from './apps/Visual/routes';
import AboutRoute from './apps/About/routes';
import AudioRoute from './apps/Audio/routes';
import PresenterRoute from './apps/Presenter/routes';
import AssessmentReportsRoute from './apps/AssessmentReports/routes';
import RosteringHeavenRoutes from './apps/RosteringHeaven/routes';
import { loginCallbackRoute, logoutCallbackRoute, loginRoute } from './apps/Authentication/routes';
import SpanishIFrameRoutes from './apps/SpanishIFrames/routes';
import SystemRequirementsRoute from './apps/SystemRequirements/routes';
import EvaluationRubricsRoute from './apps/EvaluationRubrics/routes';
import TermsOfUseRoutes from './apps/TermsOfUse/routes';
import TsResourcesRoutes from './apps/TsResources/routes';
import TroubleShooterRoute from './apps/Troubleshooter/routes';

const Routes = () => (
    <Switch>
        {AboutRoute}
        {AccountRoutes}
        {AssessmentExplorerRoute()}
        {AssessmentReportsRoute}
        {AssignedLessonsRoutes}
        {AudioRoute}
        {ContentExplorerRoute()}
        {CreditsRoute}
        {DashboardRoute}
        {DrivingQuestionRoute}
        {EvaluationRubricsRoute}
        {FileViewerRoute}
        {GameRoute}
        {IframeRoutes()}
        {ImageRoute}
        {InteractiveRoute}
        {LessonExplorerRoute}
        {LessonObjectivesRoute}
        {LessonRoutes}
        {loginCallbackRoute}
        {loginRoute}
        {logoutCallbackRoute}
        {ModuleRoute}
        {PresenterRoute}
        {QuizRoute}
        {RosteringHeavenRoutes}
        {ScienceGlossary}
        {SpanishIFrameRoutes}
        {StudentDashboardRoute}
        {StudentLessonRoute}
        {SubjectKnowledgeRoute}
        {SystemRequirementsRoute()}
        {TeacherDashboardRoute}
        {TermsOfUseRoutes()}
        {TroubleShooterRoute}
        {TsResourcesRoutes}
        {TwigAdminDashboardRoute}
        {VideoRoute}
        {VisualRoute}
        {NotFoundRoute}
    </Switch>
);

export default Routes;
