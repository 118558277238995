import React from 'react';
import { Icon } from '@twigeducation/ts-fe-components';
import { HeadingContainer, StyledHeading, StyledIconLabel, StyledIconThreeDLabel } from './HeaderIcons.styled';
import { useTranslation } from '../../../../hooks/useTranslation';
import { activeIcon } from '../../../../constants/tableOfContents';

const HeaderIcons = () => {
    const { t } = useTranslation();
    return (
        <HeadingContainer>
            <StyledHeading>
                <Icon.ClickOnScreen size={30} color={activeIcon} />
                <StyledIconLabel>
                    {t('table_of_contents.digital_investigation', 'Digital Investigation')}
                </StyledIconLabel>
            </StyledHeading>
            <StyledHeading>
                <Icon.HandsOnActivity size={30} color={activeIcon} />
                <StyledIconLabel>
                    {t('table_of_contents.hands_on_investigation', 'Hands-On Investigation')}
                </StyledIconLabel>
            </StyledHeading>
            <StyledHeading>
                <Icon.BookMagnifyingGlass size={30} color={activeIcon} />
                <StyledIconLabel>{t('table_of_contents.reading_for_evidence', 'Reading for Evidence')}</StyledIconLabel>
            </StyledHeading>
            <StyledHeading>
                <Icon.CheckedBox size={14} color={activeIcon} />
                <StyledIconLabel>
                    <StyledIconThreeDLabel>{t('table_of_contents.three_d', '3-D')} </StyledIconThreeDLabel>
                    {t('table_of_contents.assessment', 'Assessment')}
                </StyledIconLabel>
            </StyledHeading>
            <StyledHeading>
                <Icon.Film size={30} color={activeIcon} />
                <StyledIconLabel>{t('table_of_contents.video_investigation', 'Video Investigation')}</StyledIconLabel>
            </StyledHeading>
            <StyledHeading>
                <Icon.PaintPallet size={30} color={activeIcon} />
                <StyledIconLabel>{t('table_of_contents.art_and_design', 'Art and Design')}</StyledIconLabel>
            </StyledHeading>
        </HeadingContainer>
    );
};

export default HeaderIcons;
