import React from 'react';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import tsResourcesUrl from './urls';
import TsResourcesComponent from '.';

const routes = [
    <AuthenticatedLayoutRoute
        path={tsResourcesUrl}
        component={TsResourcesComponent}
        key={tsResourcesUrl}
        allowedRoles={['TWIGADMIN', 'SUPERUSER', 'TEACHER']}
        withSwoosh
    />,
];

export default routes;
