import React from 'react';
import PropTypes from 'prop-types';
import './scss/Messages.scss';

const Messages = ({ message, removeMessage }) =>
    message ? (
        <div className="messages" onClick={removeMessage} role="presentation" aria-label="Close message">
            <div className="messages__content">
                {message}
                <button aria-label="Close dialog" className="messages__close" onClick={removeMessage}>
                    <i aria-hidden="true" className="icon icon-close" />
                </button>
            </div>
        </div>
    ) : null;

Messages.defaultProps = {
    removeMessage: null,
};

Messages.propTypes = {
    message: PropTypes.string.isRequired,
    removeMessage: PropTypes.func,
};

export default Messages;
