export const actions = {
    IN_PROGRESS: 'IN_PROGRESS',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    NO_NETWORK: 'NO_NETWORK',
    RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',
    CUSTOM: 'CUSTOM',
};

export const notificationCustom = payload => ({
    type: actions.CUSTOM,
    payload,
});

export const notificationInProgress = notificationType => ({
    type: actions.IN_PROGRESS,
    payload: notificationType,
});

export const notificationSuccess = notificationType => ({
    type: actions.SUCCESS,
    payload: notificationType,
});

export const notificationError = notificationType => ({
    type: actions.ERROR,
    payload: notificationType,
});

export const notificationNoNetwork = notificationType => ({
    type: actions.NO_NETWORK,
    payload: notificationType,
});

export const notificationReset = () => ({
    type: actions.RESET_NOTIFICATIONS,
    payload: null,
});
