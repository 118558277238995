import { createStore, combineReducers, compose } from 'redux';
import { reducer as authenticationReducer } from '@twigeducation/oidc-client-react';
import * as Sentry from '@sentry/react';
import {
    answersInputsReducer,
    answersReducer,
    exampleAnswersReducer,
    answersUserIdReducer,
    hasUnsavedAnswersReducer,
    isUnsavedAnswersConfirmationDialogOpenReducer,
} from './reducers/answers';
import embedReducers from './reducers/embeds';
import lessonAnswersReducer from './reducers/lessonAnswers';
import notificationsReducer from './reducers/notifications';
import assetsReducer from '../components/ContentHandler/Components/Asset/store/reducers';
import scheduledContentReducer from '../apps/TeacherDashboard/ScheduledContent/store/reducers';
import messagesReducer from '../components/Messages/store/reducers';
import articleNotesReducer from './reducers/articleNotes';
import subscriptionsReducer from './reducers/subscriptions';
import marketingSitesReducer from './reducers/marketingSites';
import turnInReducer from './reducers/turnIn/turnInButtonState';
import i18nReducer from './reducers/i18n';
import featureFlagsReducer from './reducers/featureFlags';
import showWelcomeTourReducer from './reducers/teacherOnboarding/showWelcomeTour';
import printModeForLessonReducer from './reducers/printModeForLesson';
import isLtiReducer from './reducers/isLti/isLti';

const initialI18nState = {
    primaryLanguage: 'en-US',
    supportedLanguages: [
        {
            name: 'English',
            headerLabel: 'US English',
            code: 'en-US',
            translateKey: 'general.languages.english',
            shortName: 'EN',
        },
    ],
};

const initialStoreState = {
    userInfo: {},
    embeds: [],
    scheduledContent: {},
    messages: {},
    answers: {},
    lessonAnswers: {
        answers: [],
    },
    notifications: {},
    showExampleAnswers: false,
    answersInputsEnabled: true,
    disableTurnIn: false,
    articleNotes: {},
    subscriptions: {},
    marketingSites: {},
    i18n: initialI18nState,
    featureFlags: {},
    showWelcomeTour: false,
    isUnsavedAnswersConfirmationDialogOpen: false,
    printModeForLesson: false,
    isLti: false,
};

const configureStore = ({ initialState = initialStoreState } = {}) => {
    const appReducers = {
        userInfo: authenticationReducer,
        embeds: embedReducers,
        hideAssetAssignOptions: assetsReducer,
        scheduledContent: scheduledContentReducer,
        messages: messagesReducer,
        answers: answersReducer,
        lessonAnswers: lessonAnswersReducer,
        notifications: notificationsReducer,
        showExampleAnswers: exampleAnswersReducer,
        answersInputsEnabled: answersInputsReducer,
        hasUnsavedAnswers: hasUnsavedAnswersReducer,
        disableTurnIn: turnInReducer,
        articleNotes: articleNotesReducer,
        answersUserId: answersUserIdReducer,
        subscriptions: subscriptionsReducer,
        marketingSites: marketingSitesReducer,
        i18n: i18nReducer,
        featureFlags: featureFlagsReducer,
        showWelcomeTour: showWelcomeTourReducer,
        isUnsavedAnswersConfirmationDialogOpen: isUnsavedAnswersConfirmationDialogOpenReducer,
        printModeForLesson: printModeForLessonReducer,
        isLti: isLtiReducer,
    };

    const sentryReduxEnhancer = Sentry.createReduxEnhancer();

    const store = window.config.ENABLE_REDUX_DEV_TOOLS
        ? createStore(
              combineReducers(appReducers),
              initialState,
              // eslint-disable-next-line no-underscore-dangle
              ((typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose)(
                  sentryReduxEnhancer,
              ),
          )
        : createStore(combineReducers(appReducers), initialState, sentryReduxEnhancer);

    store.registeredReducers = { ...appReducers };

    store.registerNewReducersToStore = (name, newReducers) => {
        store.registeredReducers = {
            ...store.registeredReducers,
            [name]: newReducers,
        };

        store.replaceReducer(combineReducers({ ...store.registeredReducers, [name]: newReducers }));
    };

    return store;
};

export default configureStore();
export { configureStore as configureStoreForTests, initialStoreState, initialI18nState };
