import React from 'react';
import troubleshooterUrl from './urls';
import TroubleShooter from './TroubleShooter';
import LayoutRoute from '../../layout/LayoutRoute';

const route = (
    <LayoutRoute
        component={TroubleShooter}
        exact
        key={TroubleShooter}
        path={troubleshooterUrl}
        withConstellation={false}
        withFooter={false}
        withHeader={false}
        withStickyHeader={false}
    />
);
export default route;
