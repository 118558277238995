import React from 'react';
import { AsyncComponent } from '@twigeducation/async-component';
import { FeatureFlag } from '@twigeducation/unleash-client-react';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import { generateTitle } from '../../helpers/titleGenerator';
import { generateBreadcrumbsRoute } from '../../helpers/pathHelpers';
import { generateStudentLessonUrl } from '../StudentLesson/routes';
import assignedLessonsUrl from './urls';
import RoleErrorComponent from '../../components/Error/RoleErrorComponent';
import AsyncComponentError from '../../components/Error/AsyncComponentError';

const AssignedLessons = props => (
    <FeatureFlag
        flagKey="assigned-lessons"
        renderFeatureCallback={() => (
            <AsyncComponent
                appName="ts-assigned-lessons-mfe"
                componentName="AssignedLessons"
                errorComponent={AsyncComponentError}
                generateTitle={generateTitle}
                generateLessonUrl={generateStudentLessonUrl}
                generateBreadcrumbsRoute={generateBreadcrumbsRoute}
                unleash={{
                    clientId: window.config.UNLEASH_CLIENT_ID,
                    url: window.config.UNLEASH_URL,
                }}
                {...props}
            />
        )}
    />
);

const routes = [
    <AuthenticatedLayoutRoute
        path={assignedLessonsUrl}
        component={AssignedLessons}
        key={assignedLessonsUrl}
        withConstellation
        roles={['TWIGADMIN', 'SUPERUSER', 'TEACHER']}
        RoleErrorComponent={RoleErrorComponent}
    />,
];

export default routes;
