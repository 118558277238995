// eslint-disable-next-line import/prefer-default-export
export const getSlideData = translationFunc => {
    // Shorten the name to reduce noise.
    const t = translationFunc;

    const data = [
        {
            title: t('teacher_onboarding.onboarding_slide_1.title', 'Getting to know your Dashboard'),
            description: t(
                'teacher_onboarding.onboarding_slide_1.description',
                'Here you can: switch between classes, jump to the Lesson Explorer, jump to the Assessment Center and pin lessons',
            ),
            assetId: 'USENMKT00646',
        },
        {
            title: t('teacher_onboarding.onboarding_slide_2.title', 'Getting to know the Lesson Explorer'),
            description: t(
                'teacher_onboarding.onboarding_slide_2.description',
                "Here you can: jump to teaching resources, check aligned standards, view DQ's, view module resources and find Leveled Readers",
            ),
            assetId: 'USENMKT00649',
        },
        {
            title: t('teacher_onboarding.onboarding_slide_3.title', 'Getting to know Lessons'),
            description: t(
                'teacher_onboarding.onboarding_slide_3.description',
                'View full Lessons, Fast Track Lessons and Twig Coach Lessons. You can also assign multiple lessons in English or Spanish.',
            ),
            assetId: 'USENMKT00650',
        },
        {
            title: t('teacher_onboarding.onboarding_slide_4.title', 'Getting to know the Assigned Lesson Center'),
            description: t(
                'teacher_onboarding.onboarding_slide_4.description',
                'Here you can: assign and share lessons, close and revoke access and view student lessons',
            ),
            assetId: 'USENMKT00648',
        },
        {
            title: t('teacher_onboarding.onboarding_slide_5.title', 'Getting to know Teach and Present Mode'),
            description: t(
                'teacher_onboarding.onboarding_slide_5.description',
                'Each lesson has: Teacher Background, Lesson Overview, Lesson Breakdown, Digital Resources and Present mode',
            ),
            assetId: 'USENMKT00651',
        },
        {
            title: t('teacher_onboarding.onboarding_slide_6.title', 'Getting to know Assessments'),
            description: t(
                'teacher_onboarding.onboarding_slide_6.description',
                'Get to grips with the Assessment Center, Assessment Library and Assessment Reports',
            ),
            assetId: 'USENMKT00647',
        },
    ];

    return data;
};
