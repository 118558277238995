import React from 'react';
import { LoadingHeader, StyledLoadingSpinner, StyledMagnifyingGlass } from './LoadingSection.styled';
import { Translation } from '../../../components/Translation';

function LoadingSection() {
    return (
        <>
            <LoadingHeader>
                <h3>
                    <StyledMagnifyingGlass size={37} theme={{ iconSet: 'MIDDLE_SCHOOL' }} color="#343a73" />
                    <Translation
                        translationKey="troubleshooter.loading_title"
                        defaultValue="Hold tight, checking your system..."
                    />
                </h3>
            </LoadingHeader>
            <div>
                <Translation
                    translationKey="troubleshooter.loading_content"
                    defaultValue="Hold tight, we are testing your connection. This could take up to 10 seconds. We ask that you don’t adjust your browser window or leave the page while you wait. Taking longer than 10 seconds? Try refreshing the page. Still not working, contact our "
                />
                <a target="_blank" rel="noreferrer" href="https://help.twigscience.com/hc/en-us/requests/new">
                    <Translation translationKey="troubleshooter.loading_support_link" defaultValue="Support team." />
                </a>
                <StyledLoadingSpinner />
            </div>
        </>
    );
}
export default LoadingSection;
