import React from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';
import { useTranslation } from '../../../../hooks/useTranslation';
import { UnderlineSection } from '../TableOfContents/TableOfContents.styled';
import { StyledQuestion, DrivingQuestion } from './DQItem.styled';

const DQItem = ({ dq }) => {
    const { t } = useTranslation();
    return (
        <>
            <StyledQuestion>
                <DrivingQuestion>
                    {dq.typeTitle} {dq.dqNumber}
                </DrivingQuestion>
                <DrivingQuestion drivingQuestion>{dq.title}</DrivingQuestion>
                <DrivingQuestion descHeading>
                    ({t('table_of_contents.investigative_phenomena', 'Investigative Phenomena')})
                </DrivingQuestion>
                <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(dq.description, {
                            allowedAttributes: {
                                span: ['style'],
                            },
                            allowedStyles: {
                                span: {
                                    // Match HEX and RGB
                                    color: [
                                        /^#(0x)?[0-9a-f]+$/i,
                                        /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
                                    ],
                                },
                            },
                        }),
                    }}
                />
            </StyledQuestion>
            <UnderlineSection className="underline_section" />
        </>
    );
};

DQItem.propTypes = {
    dq: PropTypes.shape({
        application: PropTypes.string,
        art: PropTypes.string,
        description: PropTypes.string,
        digital: PropTypes.string,
        dqNumber: PropTypes.string,
        grade: PropTypes.string,
        gradePackId: PropTypes.string,
        handsOn: PropTypes.string,
        language: PropTypes.string,
        moduleNumber: PropTypes.string,
        modulePackId: PropTypes.string,
        order: PropTypes.string,
        reading: PropTypes.string,
        threeD: PropTypes.string,
        title: PropTypes.string,
        typeTitle: PropTypes.string,
        url: PropTypes.string,
        video: PropTypes.string,
        id: PropTypes.string,
    }).isRequired,
};

export default DQItem;
