import React from 'react';
import PropTypes from 'prop-types';

export const DEFAULT_TERRITORY = {
    id: 'ckadsexif005s07673w7to8ty',
    name: 'CA NGSS',
};

const withTerritory = Component => {
    const WithTerritory = props => {
        if (!props.data) return <Component {...props} />;
        const { territoryName, territoryCode } = props.data.getUserProductSubscription;

        // this is a fallback to be removed once all districts are migrated to
        // subscriptions and territories
        if (!territoryName) return <Component {...props} territory={DEFAULT_TERRITORY} />;
        return <Component {...props} territory={{ id: territoryCode, name: territoryName }} />;
    };

    WithTerritory.defaultProps = {
        data: null,
    };

    WithTerritory.propTypes = {
        data: PropTypes.shape({
            getUserProductSubscription: PropTypes.shape({
                territoryCode: PropTypes.string,
                territoryName: PropTypes.string,
            }),
        }),
    };

    return WithTerritory;
};

export default withTerritory;
