import styled from 'styled-components';

const Box = styled.button`
    height: 7rem;
    width: 7rem;
    background-color: #0b7777;
    color: #fff;
    border-radius: 1rem;
    padding-top: 1rem;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
`;
const IconWrapper = styled.div``;
const TextWrapper = styled.div`
    text-align: left;
    flex: 1;
    display: flex;
    align-items: center;
    line-height: 1;
`;

export { Box, IconWrapper, TextWrapper };
