import React from 'react';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import imageUrl from './urls';

const Image = React.lazy(() => import(/* webpackChunkName: "Image" */ '.'));

const route = <AuthenticatedLayoutRoute path={`${imageUrl}/:slug/:id`} exact component={Image} />;

const generateImageRoute = (slug, id) => `${imageUrl}/${slug}/${btoa(id)}`;

export { generateImageRoute };

export default route;
