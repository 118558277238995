import React from 'react';
import PropTypes from 'prop-types';
import IFramePage from '../../components/IFramePage';
import { territories } from '../../constants/territories';

const supportPathInNewTab = ['Correlations Maps', 'Review Program', 'Evaluation Rubrics'];

const IframePage = props => {
    const { title, urls, territory, sites } = props;
    urls[territory.name] =
        sites.marketingSites?.length > 0
            ? sites.marketingSites.filter(id => id.title === title)[0]?.url
            : urls[territory.name];

    if (
        territory &&
        (territory?.name === territories.WEST_VIRGINIA ||
            territory?.name === territories.NEW_YORK ||
            territory?.name === territories.INDIANA ||
            territory?.name === territories.PENNSYLVANIA ||
            territory?.name === territories.OREGON) &&
        supportPathInNewTab.includes(title)
    ) {
        window.open(urls[territory.name] || urls.default, '_blank');
        window.history.back();
        return null;
    }
    return <IFramePage title={title} src={urls[territory.name] || urls.default} />;
};

IframePage.defaultProps = {
    sites: { marketingSites: [] },
};

const siteShape = PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
});
IframePage.propTypes = {
    title: PropTypes.string.isRequired,
    urls: PropTypes.shape({
        default: PropTypes.string.isRequired,
    }).isRequired,
    territory: PropTypes.shape({
        name: PropTypes.oneOf([
            territories.CALIFORNIA,
            territories.NATIONAL,
            territories.OKLAHOMA,
            territories.WEST_VIRGINIA,
            territories.INDIANA,
            territories.PENNSYLVANIA,
            territories.OREGON,
        ]).isRequired,
    }).isRequired,
    sites: PropTypes.shape({
        marketingSites: PropTypes.arrayOf(siteShape),
    }),
};

export default IframePage;
