import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { GraphQLStateHandlerFactory } from '@twigeducation/graphql-state-handler';
import {
    addTerritory,
    addProductVariantCode,
    addContentEntitlement,
    addTocsApp,
    addConfiguration,
} from '../../store/actions/subscriptions';
import { setAvailableLanguages, updatePrimaryLanguage } from '../../store/actions/i18n';
import Subscriptions from './Subscriptions';
import { query, params } from './Subscriptions.query';
import withTerritory from './withTerritory';
import withProductVariantCode from './withProductVariantCode';
import withProductConfiguration from './withProductConfiguration';
import withContentEntitlement from './withContentEntitlement';
import withSupportedLanguages from './withSupportedLanguages';
import withTocsApp from './withTocsApp';

const mapStateToProps = state =>
    state.userInfo.districtId && state.userInfo.id
        ? { districtId: state.userInfo.districtId, userId: state.userInfo.id }
        : null;

const mapDispatchToProps = dispatch => ({
    addTerritoryToStore: territory => dispatch(addTerritory(territory)),
    addProductVariantCodeToStore: productVariantCode => dispatch(addProductVariantCode(productVariantCode)),
    addContentEntitlementToStore: contentEntitlement => dispatch(addContentEntitlement(contentEntitlement)),
    addConfigurationToStore: configuration => dispatch(addConfiguration(configuration)),
    setAvailableLanguages: languages => dispatch(setAvailableLanguages(languages)),
    updatePrimaryLanguage: language => dispatch(updatePrimaryLanguage(language)),
    addTocsAppToStore: tocsApp => dispatch(addTocsApp(tocsApp)),
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(query, params),
    GraphQLStateHandlerFactory('data', true),
    withTerritory,
    withProductVariantCode,
    withProductConfiguration,
    withContentEntitlement,
    withSupportedLanguages,
    withTocsApp,
);

export default enhance(Subscriptions);
