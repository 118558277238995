import React from 'react';
import PropTypes from 'prop-types';

const withSupportedLanguages = Component => {
    const WithSupportedLanguages = props => {
        if (!props.data) return <Component {...props} />;
        const availableLanguages = [
            // only codes come from BE, but this should all eventually be defined there
            {
                name: 'English',
                headerLabel: 'US English',
                code: 'en-US',
                translateKey: 'general.languages.english',
                shortName: 'EN',
            },
            {
                name: 'Spanish',
                headerLabel: 'Español',
                code: 'es-US',
                translateKey: 'general.languages.spanish',
                shortName: 'ES',
            },
        ];
        const { languages } = props.data.getUserProductSubscription;
        const subscribedLanguageCodes = languages.filter(lang => lang.purchased === true).map(lang => lang.ietfTag);
        const subscribedLanguages = availableLanguages.filter(availableLang =>
            subscribedLanguageCodes.includes(availableLang.code),
        );

        return <Component {...props} languages={subscribedLanguages} />;
    };

    WithSupportedLanguages.defaultProps = {
        data: null,
    };

    WithSupportedLanguages.propTypes = {
        data: PropTypes.shape({
            getUserProductSubscription: PropTypes.shape({
                languages: PropTypes.arrayOf(
                    PropTypes.shape({
                        ietfTag: PropTypes.string.isRequired,
                    }),
                ).isRequired,
            }),
        }),
    };

    return WithSupportedLanguages;
};

export default withSupportedLanguages;
