import React from 'react';
import { LoadingPlaceholder } from '@twigeducation/ts-fe-components';
import { AsyncComponent } from '@twigeducation/async-component';
import { PageError } from '@twigeducation/allsorts';
import AsyncComponentError from '../Error/AsyncComponentError';

const AudioProvider = props => (
    <AsyncComponent
        componentName="AudioProvider"
        appName="twig-video-mfe"
        errorComponent={AsyncComponentError}
        providerType="AUDIO"
        Loading={LoadingPlaceholder}
        PageError={PageError}
        {...props}
    />
);

export default AudioProvider;
