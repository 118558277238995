import React from 'react';
import PropTypes from 'prop-types';

const withProductVariantCode = Component => {
    const WithProductVariantCode = props => {
        if (!props.data) return <Component {...props} />;
        const { productVariantCode } = props.data.getUserProductSubscription;

        return <Component {...props} productVariantCode={productVariantCode} />;
    };

    WithProductVariantCode.defaultProps = {
        data: null,
    };

    WithProductVariantCode.propTypes = {
        data: PropTypes.shape({
            getUserProductSubscription: PropTypes.shape({
                productVariantCode: PropTypes.string,
            }),
        }),
    };

    return WithProductVariantCode;
};

export default withProductVariantCode;
