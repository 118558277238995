export const actions = {
    SET_HIDE_ASSETS_ASSIGN_OPTIONS: 'SET_HIDE_ASSETS_ASSIGN_OPTIONS',
    REMOVE_HIDE_ASSETS_ASSIGN_OPTIONS: 'REMOVE_HIDE_ASSETS_ASSIGN_OPTIONS',
};

export const setHideAssetsAssignOptions = () => ({
    type: actions.SET_HIDE_ASSETS_ASSIGN_OPTIONS,
    value: true,
});

export const removeHideAssetsAssignOptions = () => ({
    type: actions.REMOVE_HIDE_ASSETS_ASSIGN_OPTIONS,
    value: false,
});
