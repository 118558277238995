import React from 'react';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import { studentLessonUrl, studentLessonUrlModifier, viewByStudentModifier, viewByQuestionModifier } from './urls';
import isIframe from '../../helpers/isIframe';

const TeacherViewStudentLesson = React.lazy(() =>
    import(/* webpackChunkName: "TeacherViewStudentLesson" */ './Teacher'),
);

const StudentViewStudentLesson = React.lazy(() =>
    import(/* webpackChunkName: "StudentViewStudentLesson" */ './Student'),
);

export const generateStudentLessonUrl = (slug, id, modifier = false, crId = false, sId = false, view = false) =>
    `/${studentLessonUrl}/${slug}/${btoa(id)}` +
    `${modifier ? `/${modifier}` : ''}` +
    `${crId ? `/${btoa(crId)}` : ''}` +
    `${view ? `/${view}` : ''}` +
    `${sId ? `/${btoa(sId)}` : ''}`;

const StudentLessonRoute = (
    <AuthenticatedLayoutRoute
        key={StudentViewStudentLesson}
        path={`/${studentLessonUrl}/:slug/:id`}
        exact
        component={StudentViewStudentLesson}
        withFooter={false}
        withConstellation={false}
        roles={['STUDENT']}
        getRedirects={params => [
            {
                roles: ['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER'],
                redirectUrl:
                    generateStudentLessonUrl(params.slug, atob(params.id), studentLessonUrlModifier) +
                    window.location.hash,
            },
        ]}
    />
);

const StudentLessonRouteTeacher = (
    <AuthenticatedLayoutRoute
        key={TeacherViewStudentLesson}
        path={`/${studentLessonUrl}/:slug/:id/${studentLessonUrlModifier}`}
        exact
        component={TeacherViewStudentLesson}
        withFooter={false}
        withConstellation={false}
        withHeader={!isIframe()}
        roles={['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER']}
        getRedirects={params => [
            {
                roles: ['STUDENT'],
                redirectUrl: generateStudentLessonUrl(params.slug, atob(params.id)),
            },
        ]}
    />
);

const StudentLessonRouteTeacherClassRosterId = (
    <AuthenticatedLayoutRoute
        key={TeacherViewStudentLesson}
        path={`/${studentLessonUrl}/:slug/:id/${studentLessonUrlModifier}/:classRosterId`}
        exact
        component={TeacherViewStudentLesson}
        withFooter={false}
        withConstellation={false}
        withHeader={!isIframe()}
        roles={['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER']}
        getRedirects={params => [
            {
                roles: ['STUDENT'],
                redirectUrl: generateStudentLessonUrl(params.slug, atob(params.id), studentLessonUrlModifier),
            },
        ]}
    />
);

const StudentLessonRouteTeacherClassRosterIdStudentId = (
    <AuthenticatedLayoutRoute
        key={TeacherViewStudentLesson}
        path={`/${studentLessonUrl}/:slug/:id/${studentLessonUrlModifier}/:classRosterId/:studentId`}
        exact
        component={TeacherViewStudentLesson}
        withFooter={false}
        withConstellation={false}
        withHeader={!isIframe()}
        roles={['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER']}
        getRedirects={params => [
            {
                roles: ['STUDENT'],
                redirectUrl: generateStudentLessonUrl(params.slug, atob(params.id), studentLessonUrlModifier),
            },
        ]}
    />
);

const StudentLessonRouteTeacherClassRosterIdStudent = (
    <AuthenticatedLayoutRoute
        key={TeacherViewStudentLesson}
        path={`/${studentLessonUrl}/:slug/:id/${studentLessonUrlModifier}/:classRosterId/${viewByStudentModifier}`}
        exact
        component={TeacherViewStudentLesson}
        withFooter={false}
        withConstellation={false}
        withHeader={!isIframe()}
        roles={['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER']}
        getRedirects={params => [
            {
                roles: ['STUDENT'],
                redirectUrl: generateStudentLessonUrl(params.slug, atob(params.id), studentLessonUrlModifier),
            },
        ]}
    />
);

const StudentLessonRouteTeacherClassRosterIdStudentStudentId = (
    <AuthenticatedLayoutRoute
        key={TeacherViewStudentLesson}
        path={
            `/${studentLessonUrl}/:slug/:id/${studentLessonUrlModifier}/:classRosterId/` +
            `${viewByStudentModifier}/:studentId`
        }
        exact
        component={TeacherViewStudentLesson}
        withFooter={false}
        withConstellation={false}
        withHeader={!isIframe()}
        roles={['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER']}
        getRedirects={params => [
            {
                roles: ['STUDENT'],
                redirectUrl: generateStudentLessonUrl(params.slug, atob(params.id), studentLessonUrlModifier),
            },
        ]}
    />
);

const StudentLessonRouteTeacherClassRosterIdQuestion = (
    <AuthenticatedLayoutRoute
        key={TeacherViewStudentLesson}
        path={`/${studentLessonUrl}/:slug/:id/${studentLessonUrlModifier}/:classRosterId/${viewByQuestionModifier}`}
        exact
        component={TeacherViewStudentLesson}
        withFooter={false}
        withConstellation={false}
        withHeader={!isIframe()}
        roles={['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER']}
        getRedirects={params => [
            {
                roles: ['STUDENT'],
                redirectUrl: generateStudentLessonUrl(params.slug, atob(params.id), studentLessonUrlModifier),
            },
        ]}
    />
);

const StudentLessonRouteTeacherClassRosterIdQuestionQuestionId = (
    <AuthenticatedLayoutRoute
        key={TeacherViewStudentLesson}
        path={
            `/${studentLessonUrl}/:slug/:id/${studentLessonUrlModifier}/:classRosterId/` +
            `${viewByQuestionModifier}/:questionId`
        }
        exact
        component={TeacherViewStudentLesson}
        withFooter={false}
        withConstellation={false}
        withHeader={!isIframe()}
        roles={['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER']}
        getRedirects={params => [
            {
                roles: ['STUDENT'],
                redirectUrl: generateStudentLessonUrl(params.slug, atob(params.id), studentLessonUrlModifier),
            },
        ]}
    />
);

const routes = [
    StudentLessonRouteTeacherClassRosterIdQuestion,
    StudentLessonRouteTeacherClassRosterIdQuestionQuestionId,
    StudentLessonRouteTeacherClassRosterIdStudentStudentId,
    StudentLessonRouteTeacherClassRosterIdStudent,
    StudentLessonRouteTeacherClassRosterIdStudentId,
    StudentLessonRouteTeacherClassRosterId,
    StudentLessonRouteTeacher,
    StudentLessonRoute,
];

export default routes;
