import React from 'react';
import Route from '../../layout/LayoutRoute';

const NotFoundComponent = React.lazy(() => import('.'));

const LoadableNotFound = () => <NotFoundComponent />;

const route = <Route path="*" component={LoadableNotFound} />;

export default route;
