import React from 'react';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import lessonObjectivesUrl from './urls';

const LessonObjectives = React.lazy(() => import(/* webpackChunkName: "LessonObjectives" */ '.'));

const routes = [
    <AuthenticatedLayoutRoute
        path={`${lessonObjectivesUrl}/:slug/:id`}
        exact
        component={LessonObjectives}
        key={LessonObjectives}
    />,
];

const generateLessonObjectivesUrl = (slug, id) => `${lessonObjectivesUrl}/${slug}/${btoa(id)}`;

export { generateLessonObjectivesUrl };

export default routes;
