import React from 'react';
import { node, number, string } from 'prop-types';
import { Trans } from 'react-i18next';
import AstronautImagePath from './images/astronaut.svg';
import { Layout, Image, Heading } from './ContentMissing.styles';
import { useTranslation } from '../../../hooks/useTranslation';
import { Translation } from '../../../components/Translation';

const ContentMissing = ({ headingKey, headingLevel, headingValue, bodyKey, bodyValue }) => {
    const { t } = useTranslation();
    return (
        <Layout>
            <Image src={AstronautImagePath} alt={t('lesson_explorer.no_content_image_alt_text', 'Twig Astronaut')} />
            <Heading as={`h${headingLevel}`}>
                <Translation translationKey={headingKey} defaultValue={headingValue} />
            </Heading>
            <p>
                <Trans i18nKey={bodyKey}>{bodyValue}</Trans>
            </p>
        </Layout>
    );
};

ContentMissing.defaultProps = {
    headingKey: 'lesson_explorer.content_unavailable_heading_text',
    headingValue: 'This content is not available in your chosen language',
    headingLevel: 4,
    bodyKey: 'lesson_explorer.content_unavailable_info_text',
    bodyValue: 'Try switching to US English',
};

ContentMissing.propTypes = {
    headingKey: string,
    headingValue: string,
    headingLevel: number,
    bodyKey: string,
    bodyValue: node,
};

export default ContentMissing;
