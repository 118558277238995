import React from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import * as Sentry from '@sentry/react';
import { Button } from '@twigeducation/ts-fe-components';
import { Download } from '@twigeducation/ts-fe-components/components/Icons';

class DownloadButton extends React.Component {
    downloadFile(fileUrl, title, extension) {
        const pdfRequest = new XMLHttpRequest();
        pdfRequest.open('GET', fileUrl, true);
        pdfRequest.responseType = 'blob';

        pdfRequest.onload = () => {
            if (pdfRequest.status !== 200) {
                Sentry.captureMessage(`There was an error downloading PDF file - ${title}`);
            } else {
                const file = new Blob([pdfRequest.response], {
                    type: 'binary/octet-stream',
                });
                FileSaver.saveAs(file, `${title}.${extension}`);
            }
        };
        pdfRequest.send();
    }

    render() {
        const {
            fileUrl,
            title,
            buttonText,
            fileExtension,
            className,
            analyticsEventForDownloadButtonClick,
        } = this.props;

        return (
            <Button
                className={className}
                iconLeft
                onClick={() => {
                    analyticsEventForDownloadButtonClick(this.downloadFile(fileUrl, title, fileExtension));
                }}
                secondary
                data-at="download-btn"
            >
                <Download size={14} />
                {buttonText}
            </Button>
        );
    }
}

DownloadButton.defaultProps = {
    title: 'download',
    fileUrl: null,
    buttonText: 'Download',
    fileExtension: null,
    className: 'download-button',
    analyticsEventForDownloadButtonClick: () => {},
};

DownloadButton.propTypes = {
    fileUrl: PropTypes.string,
    title: PropTypes.string,
    buttonText: PropTypes.string,
    fileExtension: PropTypes.string,
    className: PropTypes.string,
    analyticsEventForDownloadButtonClick: PropTypes.func,
};

export default DownloadButton;
