const generateDropdownOptions = (grades, t) => {
    const labels = [
        t('contents_explorer.grade_placeholder', 'Please select a Grade'),
        ...grades.map(grade => grade.title),
    ];
    const values = ['Default', ...grades.map(grade => grade.id)];

    return labels.map((item, index) => {
        const grade = grades.find(g => g.id === values[index]);
        const color = grade && grade.colorCode ? grade.colorCode : 'transparent';
        const versionId = grade ? grade.versionId : '';

        return {
            value: values[index],
            label: item,
            isDisabled: values[index] === 'Default',
            modules:
                index === 0
                    ? {}
                    : grades[index - 1]?.modules.map(module => ({
                          value: module.id,
                          label: `${t('contents_explorer.module', 'Module')} ${module.order}: ${module.title}`,
                          isDisabled: module.id === 'Default',
                          color,
                      })),
            color,
            versionId,
        };
    });
};

const defaultModule = color => ({
    value: 'Default',
    label: 'Please select a Module',
    isDisabled: module.id === 'Default',
    color,
});

export { generateDropdownOptions, defaultModule };
