import React from 'react';
import ReactDom from 'react-dom';
import * as StyledComponents from 'styled-components';
import * as Redux from 'redux';
import * as ReactInterchange from '@twigeducation/react-interchange';
import * as ReactRedux from 'react-redux';
import * as ReactRouter from 'react-router';
import * as ReactRouterDom from 'react-router-dom';
import * as Unleash from '@twigeducation/unleash-client-react';
import * as ApolloClient from '@apollo/client';
import * as ApolloClientComponents from '@apollo/client/react/components';
import * as ApolloClientHOC from '@apollo/client/react/hoc';
import * as GraphQLErrors from '@twigeducation/react-graphql-errors';
import * as lodash from 'lodash';
import * as ReactLazyLoad from 'react-lazyload';
import * as ReactCloudinaryImage from '@twigeducation/react-cloudinary-image';
import * as ReactVisuals from '@twigeducation/react-visuals';
import * as FormsyReact from 'formsy-react';
import * as ReactI18Next from 'react-i18next';
import * as ReactGA from 'react-ga';
import * as ReactGA4 from 'react-ga4';
import ErrorBoundary from './components/ErrorBoundary';

window.react = React;
window.ReactDom = ReactDom;
window.ReactRouterDom = ReactRouterDom;
window.ReactRouter = ReactRouter;
window.StyledComponents = StyledComponents;
window.Redux = Redux;
window.ReactRedux = ReactRedux;
window.Unleash = Unleash;
window.ApolloClient = ApolloClient;
window.ApolloClientComponents = ApolloClientComponents;
window.ApolloClientHOC = ApolloClientHOC;
window.ReactInterchange = ReactInterchange;
window.GraphQLErrors = GraphQLErrors;
window.lodash = lodash;
window.ReactLazyLoad = ReactLazyLoad;
window.ReactCloudinaryImage = ReactCloudinaryImage;
window.ReactErrorBoundary = ErrorBoundary;
window.ReactVisuals = ReactVisuals;
window.FormsyReact = FormsyReact;
window.ReactI18Next = ReactI18Next;
window.ReactGA = ReactGA;
window.ReactGA4 = ReactGA4;
