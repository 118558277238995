export const actions = {
    SET_ANSWERS_USER_ID: 'SET_ANSWERS_USER_ID',
};

const setAnswersUserId = id => ({
    type: actions.SET_ANSWERS_USER_ID,
    payload: id,
});

export default setAnswersUserId;
