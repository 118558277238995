import styled from 'styled-components';
import { Tick } from '@twigeducation/ts-fe-components/components/Icons';

export const StyledTick = styled(Tick)`
    margin-right: 15px;
`;

export const SuccessHeader = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #15a19a;
    }
    img {
        transform: scale(75%);
    }
`;

export const StyledLink = styled.a`
    text-decoration: none;
`;
