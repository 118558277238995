import React from 'react';
import lessonExplorerUrl from './urls';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import getResourceId from '../../helpers/getResourceId';
import RoleErrorComponent from '../../components/Error/RoleErrorComponent';

const LessonExplorer = React.lazy(() => import(/* webpackChunkName: "LessonExplorer" */ '.'));
const allowedRoles = ['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER'];

const routes = [
    <AuthenticatedLayoutRoute
        component={LessonExplorer}
        exact
        key={LessonExplorer}
        path={lessonExplorerUrl}
        RoleErrorComponent={RoleErrorComponent}
        roles={allowedRoles}
    />,
    <AuthenticatedLayoutRoute
        component={LessonExplorer}
        key={LessonExplorer}
        path={`${lessonExplorerUrl}/:gradeid/:moduleid?`}
        roles={allowedRoles}
        RoleErrorComponent={RoleErrorComponent}
    />,
];

export const generateLessonExplorerUrl = (gradeId, moduleId) => {
    if (gradeId && moduleId) {
        const resourceId = getResourceId(moduleId);
        return `${lessonExplorerUrl}/${gradeId}/${btoa(resourceId)}`;
    }
    if (gradeId) {
        return `${lessonExplorerUrl}/${gradeId}`;
    }
    return lessonExplorerUrl;
};

export default routes;
