import React from 'react';
import { PropTypes } from 'prop-types';
import { HeaderContainer, HeadButton } from './HeaderComponent.styled';

const HeaderComponent = ({ assessmentData, handleNavClick }) => (
    <HeaderContainer>
        {assessmentData.map(item => (
            <HeadButton
                disabled={item.data.length <= 0}
                assessmentDataLength={item.data.length}
                key={item.id}
                primary
                onClick={() => item.data.length > 0 && handleNavClick(item.id)}
            >
                {item.navigationTitle}
            </HeadButton>
        ))}
    </HeaderContainer>
);
HeaderComponent.propTypes = {
    handleNavClick: PropTypes.func.isRequired,
    assessmentData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            navigationTitle: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default HeaderComponent;
