import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Dropdown, Pill, LoadingPlaceholder } from '@twigeducation/ts-fe-components';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useTranslation } from '../../hooks/useTranslation';
import Assessments from './Assessments/Assessments';
import { generateDropdownOptions, defaultModule } from '../helpers';
import {
    StyledContainer,
    StyledContents,
    DropdownsContainer,
    StyledContentBackground,
    StyledContent,
} from './AssessmentsExplorer.styled';
import ContentMissing from '../LessonExplorer/ContentMissing';
import { AssessmentExplorerUrl, AssessmentExplorerReviewUrl } from './urls';
import { gradeSummaryQuery } from './AssessmentsExplorer.query';

const AssessmentsExplorer = () => {
    const { t } = useTranslation();
    const { gradeId, moduleId, assessmentTabRef } = useParams();
    const location = useLocation();
    const [gradeFilter, setGradeFilter] = useState(null);
    const [moduleFilter, setModuleFilter] = useState(null);

    const defaultLanguage = useSelector(state => state.i18n.primaryLanguage);
    const { data, loading } = useQuery(gradeSummaryQuery, {
        variables: {
            language: defaultLanguage,
        },
        fetchPolicy: 'no-cache',
    });
    let options = !loading && generateDropdownOptions(data?.TSGradeSummaries, t);
    options = !loading && options.filter(ele => ele.value !== 'TK' && ele.value !== 'PK');
    const history = useHistory();

    useEffect(() => {
        if (!loading && gradeId) {
            setGradeFilter(options?.find(grade => grade?.value === gradeId));
        }
    }, [loading, defaultLanguage]);

    useEffect(() => {
        // to update module when language changes
        if (gradeFilter && moduleId) {
            const modulePackId = moduleId?.slice(4, moduleId?.length);
            const newModule = gradeFilter?.modules?.find(module => {
                const newModuleId = defaultLanguage === 'es-US' ? `USES${modulePackId}` : `USEN${modulePackId}`;
                return newModuleId === module?.value;
            });
            setModuleFilter(newModule);
        }
    }, [gradeFilter]);

    useEffect(() => {
        const url =
            location.pathname.indexOf('/assessments-explorer-review') === -1
                ? AssessmentExplorerUrl
                : AssessmentExplorerReviewUrl;

        if (!loading && data?.TSGradeSummaries) {
            // to update dropdown values when URL changes
            if (gradeId && gradeFilter === null) {
                setGradeFilter(options?.find(grade => grade?.value === gradeId));
            }
            if (moduleId && gradeFilter !== null && moduleFilter === null) {
                setModuleFilter(gradeFilter?.modules?.find(module => module?.value === moduleId));
            }

            // to update URL when dropdown values change
            if (moduleFilter && moduleFilter?.value !== moduleId) {
                history.push(`${url}/${gradeFilter?.value}/${moduleFilter?.value}`);
            }
            if (gradeFilter && gradeFilter?.value !== gradeId) {
                history.push(`${url}/${gradeFilter?.value}`);
            }
        }
    }, [gradeFilter, moduleFilter, location.pathname, data, loading]);

    const isError = (gradeId && gradeId !== gradeFilter?.value) || (moduleId && moduleId !== moduleFilter?.value);
    const assignments = gradeFilter && moduleFilter;

    if (loading) {
        return <LoadingPlaceholder />;
    }
    return (
        <StyledContainer>
            <StyledContents>
                <h1>{t('assessment_explorer.title', 'Assessment Explorer')}</h1>
                <Pill />
                <DropdownsContainer>
                    <Dropdown
                        placeholder="Please select a Grade"
                        value={gradeFilter ? options?.find(option => option?.value === gradeFilter?.value) : options[0]}
                        onChange={option => setGradeFilter(options?.find(grade => grade?.value === option?.value))}
                        options={options}
                        hideSelectedOptions
                        aria-label="Grades Dropdown"
                    />
                    {gradeFilter && (
                        <Dropdown
                            placeholder="Please select a Module"
                            value={
                                moduleFilter
                                    ? gradeFilter.modules.find(option => option?.value === moduleFilter?.value)
                                    : defaultModule(gradeFilter?.color)
                            }
                            onChange={option =>
                                setModuleFilter(gradeFilter?.modules?.find(module => module?.value === option?.value))
                            }
                            options={gradeFilter?.modules}
                            hideSelectedOptions
                            aria-label="Modules Dropdown"
                        />
                    )}
                </DropdownsContainer>
            </StyledContents>
            {!loading && isError && <ContentMissing />}
            <StyledContentBackground>
                <StyledContent>
                    {assignments && (
                        <Assessments
                            gradeId={gradeFilter?.versionId}
                            moduleId={moduleFilter?.value}
                            assessmentTabRef={assessmentTabRef}
                        />
                    )}
                </StyledContent>
            </StyledContentBackground>
        </StyledContainer>
    );
};

export default AssessmentsExplorer;
