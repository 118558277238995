import * as Sentry from '@sentry/react';
import { makeMultiplexedTransport } from '@sentry/core';
import i18next from 'i18next';
import history from './history';

const getSentryTracePropagationTargets = () => {
    if (window.config.SENTRY_TRACE_PROPAGATION_TARGETS) {
        return window.config.SENTRY_TRACE_PROPAGATION_TARGETS.split(',');
    }
    return ['localhost', 'api.twigscience.com'];
};
if (
    window.config.SENTRY_ENABLED === true &&
    typeof window.config.SENTRY_PUBLIC_DSN === 'string' &&
    window.config.SENTRY_PUBLIC_DSN !== ''
) {
    const dsnFromFeature = ({ getEvent }) => {
        const event = getEvent();
        switch (event?.tags?.mfe) {
            case 'ts-accounts':
                return ['https://f04813f27bbd4930bf0f7101e0467111@o11481.ingest.sentry.io/1423030'];
            case 'ts-assessment-reports-mfe':
                return ['https://44b26238cd934cc3b3e80036502e0a8e@o11481.ingest.sentry.io/4505204267614208'];
            case 'ts-assigned-lessons-mfe':
                return ['https://fd6f5bcd0f164ab9b0d57857ad20958b@o11481.ingest.sentry.io/4505204270759936'];
            case 'ts-breadcrumbs':
                return ['https://f20c8055d32b480fb20e91a89fa10f55@o11481.ingest.sentry.io/4505204272529408'];
            case 'ts-footer':
                return ['https://861a3b88d80a4ace86052422e455d731@o11481.ingest.sentry.io/4505204256276480'];
            case 'ts-header':
                return ['https://ffaef44acded4e7a8de205247a3957d8@o11481.ingest.sentry.io/4505204274036736'];
            case 'ts-rostering-heaven-mfe':
                return ['https://f2bc59ba4bf84fb997cc2becea390418@o11481.ingest.sentry.io/4505204275478528'];
            case 'ts-standards-mfe':
                return ['https://4ef67461cd2f41948a71635405b05074@o11481.ingest.sentry.io/4505204276854784'];
            case 'ts-teacher-feedback-mfe':
                return ['https://042761262ae7489c9222ab429244b644@o11481.ingest.sentry.io/4505204278231040'];
            case 'twig-drawing-tool':
                return ['https://ef5c7ac707d0478f9a22cdcc21e3a1bb@o11481.ingest.sentry.io/4505204279672832'];
            case 'twig-games':
                return ['https://d1ef6862d9434343813820d84595b1a2@o11481.ingest.sentry.io/4505204280918016'];
            case 'twig-pdf':
                return ['https://83b7eeb2140b4ff4885b8d5e5db23821@o11481.ingest.sentry.io/4505204282294272'];
            case 'twig-presenter':
                return ['https://171650c359364ada9eec086bcaad1faf@o11481.ingest.sentry.io/4505204283604993'];
            case 'twig-quill-mfe':
                return ['https://0d95183b4dbe45c5aa8da9a2fdc45578@o11481.ingest.sentry.io/4505204286947328'];
            case 'twig-video-mfe':
                return ['https://271e538a72f04bc99ac5531dbb599d9e@o11481.ingest.sentry.io/4505204288192512'];
            default:
        }
        return [];
    };

    Sentry.init({
        dsn: window.config.SENTRY_PUBLIC_DSN,
        transport: makeMultiplexedTransport(Sentry.makeFetchTransport, dsnFromFeature),
        release: window.config.IMAGE_GIT_COMMIT_SHA,
        environment: window.config.SENTRY_ENVIRONMENT,
        tracesSampleRate: 1,
        attachStacktrace: true,
        beforeSend: event => {
            /* eslint-disable no-param-reassign */
            const addTags = tags => {
                if (!event.tags) {
                    event.tags = {};
                }

                Object.entries(tags).forEach(([tagName, value]) => {
                    if (value != null && value !== '') {
                        event.tags[tagName] = value;
                    }
                });
            };
            /* eslint-enable no-param-reassign */

            const reactUnmounted = (document.getElementById('app').childElementCount === 0).toString();

            const language = i18next.isInitialized ? i18next.language : undefined;

            addTags({
                reactUnmounted,
                language,
            });

            return event;
        },
        normalizeDepth: 10,
        integrations: [
            new Sentry.Replay({
                maskAllInputs: true,
            }),
            new Sentry.BrowserTracing({
                tracePropagationTargets: getSentryTracePropagationTargets(),
                // eslint-disable-next-line new-cap
                routingInstrumentation: new Sentry.reactRouterV5Instrumentation({ history }),
            }),
        ],
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            /analytics\.js/i,
            /scripts\.hotjar\.com/i,
            /js-agent\.newrelic\.com/i,
            /acsbapp\.com/i,
            /g1980843350\.co/i,
        ],
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            "Can't find variable: ZiteReader",
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
            /\?\(ga\)/i,
            /acsb/i,
            // Rapid page reflows triggers this. Doesn't affect the site.
            'ResizeObserver loop limit exceeded',
            // Closing the quiz modal before the quiz finishes loading causes this. We can't catch it. Doesn't affect users.
            "Cannot read properties of null (reading 'loadComplete')", // Chromium
            `can't access property "loadComplete", this.game.state is null`, // Firefox
            "null is not an object (evaluating 'this.game.state.loadComplete')", // Safari
        ],
    });
}
