import React from 'react';
import PropTypes from 'prop-types';

const withMarketingSites = Component => {
    const WithMarketingSites = props => {
        if (!props.data) {
            return <Component {...props} />;
        }

        const sites = props.data.navigationData?.marketingsites?.relatedTo.all.edges.map(id => ({
            url: id.node.attributes.url,
            title: id.node.attributes.title,
        }));
        return <Component {...props} marketingSites={sites} />;
    };

    WithMarketingSites.defaultProps = {
        data: null,
    };

    WithMarketingSites.propTypes = {
        data: PropTypes.shape({
            navigationData: PropTypes.shape({
                marketingsites: PropTypes.shape({
                    relatedTo: PropTypes.shape({
                        all: PropTypes.shape({
                            edges: PropTypes.any,
                        }),
                    }),
                }),
            }),
        }),
    };

    return WithMarketingSites;
};

export default withMarketingSites;
