import React from 'react';
import PropTypes from 'prop-types';

const RedirectToShortCodeUrl = ({ districtId }) => {
    window.location.href = `${window.config.OIDC_PROVIDER_URL}/accounts/login/${districtId}/?next=${window.location.origin}`;
    return <p>Redirecting...</p>;
};

RedirectToShortCodeUrl.propTypes = {
    districtId: PropTypes.string.isRequired,
};

export default RedirectToShortCodeUrl;
