import React from 'react';
import PropTypes from 'prop-types';
import { LoadingPlaceholder } from '@twigeducation/ts-fe-components';
import assetProptypes from './assetProptypes';

class AssetComponentRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { Component: () => <LoadingPlaceholder /> };
    }

    async componentDidMount() {
        const { asset, getAssetComponent, versionId } = this.props;
        const Component = await getAssetComponent(asset.classification.mediaType, versionId);
        this.setState({ Component });
    }

    render() {
        const { Component } = this.state;
        const { asset, versionId } = this.props;
        return <Component asset={asset} versionId={versionId} />;
    }
}

AssetComponentRenderer.propTypes = {
    asset: assetProptypes.isRequired,
    getAssetComponent: PropTypes.func.isRequired,
    versionId: PropTypes.string.isRequired,
};

export default AssetComponentRenderer;
