import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import Layout from './Layout';
import LayoutWithSwoosh from './LayoutWithSwoosh';
import LayoutWithStickyHeader from './LayoutWithStickyHeader';
import UpdatePasswordRequired from '../components/UpdatePasswordRequired';
import './scss/LayoutRoute.scss';

const AuthenticatedLayoutRoute = ({
    component: Component,
    roles,
    withConstellation,
    withFooter,
    withHeader,
    withStickyHeader,
    withSwoosh,
    getRedirects,
    ...props
}) => {
    let LayoutComponent = Layout;
    if (withSwoosh) {
        LayoutComponent = LayoutWithSwoosh;
    }
    if (withStickyHeader) {
        LayoutComponent = LayoutWithStickyHeader;
    }

    return (
        <Route
            {...props}
            render={matchProps => (
                <WithAuthentication roles={roles} redirects={getRedirects(matchProps.match.params)} {...props}>
                    <UpdatePasswordRequired>
                        <LayoutComponent
                            component={Component}
                            withConstellation={withConstellation}
                            withFooter={withFooter}
                            withHeader={withHeader}
                            {...props}
                            {...matchProps}
                        />
                    </UpdatePasswordRequired>
                </WithAuthentication>
            )}
        />
    );
};

AuthenticatedLayoutRoute.defaultProps = {
    getRedirects: () => [{ roles: [], redirectUrl: '' }],
    roles: [],
    withConstellation: true,
    withFooter: true,
    withHeader: true,
    withStickyHeader: false,
    withSwoosh: false,
};

AuthenticatedLayoutRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    withConstellation: PropTypes.bool,
    withFooter: PropTypes.bool,
    withHeader: PropTypes.bool,
    withStickyHeader: PropTypes.bool,
    withSwoosh: PropTypes.bool,
    getRedirects: PropTypes.func,
};

export default AuthenticatedLayoutRoute;
