import { Log, UserManager, WebStorageStateStore } from '@twigeducation/oidc-client-react';
import { addBreadcrumb, captureException } from '@sentry/react';

try {
    const storageKey = `oidc.user:${window.config.OIDC_PROVIDER_URL}/:${window.config.SSO_CLIENT_ID}`;
    const oidcUser = JSON.parse(localStorage.getItem(storageKey) ?? null);

    if (oidcUser !== null && oidcUser.access_token === oidcUser.id_token) {
        Object.keys(localStorage)
            .filter(key => key.startsWith('oidc.'))
            .forEach(key => localStorage.removeItem(key));
    }
} catch (err) {
    console.error(err);
}

function setupUserManagerEvents(userManager) {
    userManager.events.addUserSignedIn(() => {
        addBreadcrumb({
            type: 'info',
            category: 'auth',
            message: 'User signed in',
            level: 'info',
        });
    });

    userManager.events.addUserSignedOut(() => {
        addBreadcrumb({
            type: 'info',
            category: 'auth',
            message: 'User signed out',
            level: 'info',
        });
    });

    userManager.events.addUserSessionChanged(() => {
        addBreadcrumb({
            type: 'info',
            category: 'auth',
            message: 'User session changed',
            level: 'info',
        });
    });

    userManager.events.addUserLoaded(user => {
        if (!user) {
            addBreadcrumb({
                type: 'info',
                category: 'auth',
                message: 'User loaded (but not provided???)',
                level: 'info',
            });
        } else {
            addBreadcrumb({
                type: 'info',
                category: 'auth',
                message: 'User loaded',
                level: 'info',
                data: {
                    app_metadata: user.profile.app_metadata,
                    expires_at: user.expires_at,
                    expires_at_utc: new Date(user.expires_at * 1000).toUTCString(),
                    has_id_token: !!user.id_token,
                    has_access_token: !!user.access_token,
                    has_refresh_token: !!user.refresh_token,
                },
            });
        }
    });

    userManager.events.addUserUnloaded(() => {
        addBreadcrumb({
            type: 'info',
            category: 'auth',
            message: 'User unloaded',
            level: 'info',
        });
    });

    userManager.events.addAccessTokenExpiring(async () => {
        const user = await userManager.getUser();
        if (!user) {
            addBreadcrumb({
                type: 'info',
                category: 'auth',
                message: 'Access token expiring (no user found)',
                level: 'info',
            });
        } else {
            const timeToExpiry = user.expires_at - Date.now() / 1000;
            const message =
                timeToExpiry >= 0
                    ? `Access token expiring in ${timeToExpiry}s`
                    : `Access token expired ${-timeToExpiry}s ago`;

            addBreadcrumb({
                type: 'info',
                category: 'auth',
                message,
                level: 'info',
            });
        }
    });

    userManager.events.addAccessTokenExpired(() => {
        addBreadcrumb({
            type: 'info',
            category: 'auth',
            message: 'Access token expired',
            level: 'info',
        });
    });

    userManager.events.addSilentRenewError(error => {
        captureException(error);
    });
}

function getUserManager() {
    let userManager = null;
    try {
        userManager = new UserManager({
            authority: `${window.config.OIDC_PROVIDER_URL}/`,
            automaticSilentRenew: true,
            client_id: window.config.SSO_CLIENT_ID,
            post_logout_redirect_uri: window.config.SSO_LOGOUT_CALLBACK_URL,
            monitorSession: window.config.SSO_MONITOR_SESSION,
            redirect_uri: window.config.SSO_LOGIN_CALLBACK_URL,
            response_type: 'code',
            scope: 'openid read write',
            userStore: new WebStorageStateStore({ store: window.localStorage }),
        });
        setupUserManagerEvents(userManager);
    } catch (err) {
        console.error('Error in userManager:', err);
        captureException(err);
    }
    return userManager;
}

export const configureOIDCLogging = environment => {
    Log.logger = console;
    if (environment.toLowerCase() === 'production') {
        Log.level = Log.ERROR;
    } else {
        Log.level = Log.DEBUG;
    }
};
configureOIDCLogging(NODE_ENV.toLowerCase());

export const userManager = getUserManager();
