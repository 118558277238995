/* eslint-disable graphql/template-strings */
import { gql } from '@apollo/client';

const tsContentExplorersQuery = gql`
    query tsContentExplorers($filters: TsContentExplorerFiltersInput, $pagination: PaginationArg) {
        tsContentExplorers(filters: $filters, pagination: $pagination) {
            data {
                id
                attributes {
                    application
                    language
                    gradePackId
                    grade
                    modulePackId
                    moduleNumber
                    dqNumber
                    typeTitle
                    order
                    title
                    description
                    url
                    digital
                    handsOn
                    reading
                    threeD
                    video
                    art
                    courseType
                }
            }
        }
    }
`;

export default tsContentExplorersQuery;
