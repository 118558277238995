export const actions = {
    SET_SCHEDULED_CONTENT: 'SET_SCHEDULED_CONTENT',
    SET_SCHEDULED_CONTENT_ITEM_STATUS: 'SET_SCHEDULED_CONTENT_ITEM_STATUS',
};

export const setScheduledContent = value => ({
    type: actions.SET_SCHEDULED_CONTENT,
    value,
});

export const setScheduledContentItemStatus = value => ({
    type: actions.SET_SCHEDULED_CONTENT_ITEM_STATUS,
    value,
});
