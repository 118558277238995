import { actions } from './actions';

const messagesReducer = (state, action) => {
    let newState = {};

    switch (action.type) {
        case actions.SET_MESSAGE:
            newState = { message: action.value };
            break;
        case actions.REMOVE_MESSAGE:
            newState = {};
            break;
        default:
            newState = { ...state };
    }

    return newState;
};

export default messagesReducer;
