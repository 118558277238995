/* eslint-disable import/prefer-default-export  */
import { useTranslation as useTranslationNext } from 'react-i18next';
import packageInfo from '../../../package.json';

export const useTranslation = (ns = null, props = {}) => {
    const { t, ...rest } = useTranslationNext(ns, props);

    return {
        t: (translationKey, defaultValue, options) => {
            if (!translationKey) {
                return null;
            }
            return t(`${packageInfo.name}.${translationKey}`, defaultValue, options);
        },
        ...rest,
    };
};
