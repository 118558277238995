import React from 'react';
import PropTypes from 'prop-types';

const IFramePage = ({ title, src }) => (
    <iframe
        title={title}
        src={src}
        frameBorder="0"
        style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
        }}
        allowFullScreen
    />
);

IFramePage.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
};

export default IFramePage;
