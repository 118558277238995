import { actions } from '../../actions/embeds';

const initialState = [];

const embedsReducer = (state = initialState, action) => {
    let newState = [];
    switch (action.type) {
        case actions.SET_EMBEDS:
            newState = action.payload;
            break;
        default:
            newState = [...state];
    }
    return newState;
};

export default embedsReducer;
