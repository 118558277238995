import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useTranslation } from '../../hooks/useTranslation';

const UpdateLanguage = ({ children, lang }) => {
    const { i18n } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang]);

    return children;
};

const TranslationScope = ({ children, lang }) => {
    const { i18n } = useTranslation();
    const [clonedI18n] = useState(i18n.cloneInstance());

    return (
        <I18nextProvider i18n={clonedI18n}>
            <UpdateLanguage lang={lang}>{children}</UpdateLanguage>
        </I18nextProvider>
    );
};

TranslationScope.defaultProps = {
    lang: null,
};

TranslationScope.propTypes = {
    children: PropTypes.node.isRequired,
    lang: PropTypes.oneOf(['en-US', 'es-US']),
};

export default TranslationScope;
