import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFeatureFlag } from '@twigeducation/unleash-client-react';
import { gaSet } from '../../helpers/ga';
import { setIsLTI } from '../../store/actions/isLti/isLti';

const Subscriptions = ({
    territory,
    productVariantCode,
    productConfiguration,
    contentEntitlement,
    addTerritoryToStore,
    addProductVariantCodeToStore,
    addConfigurationToStore,
    addContentEntitlementToStore,
    languages,
    setAvailableLanguages,
    updatePrimaryLanguage,
    addTocsAppToStore,
    tocsApp,
    children,
}) => {
    const [updated, setUpdated] = useState(false);
    const dispatch = useDispatch();
    const isK6RtkLtiLaunchEnabled = useFeatureFlag('k6_rtk_lti_launch');

    useLayoutEffect(() => {
        dispatch(
            setIsLTI({
                productConfiguration,
                isLtiDeployment: window.config.IS_LTI,
                flagEnabled: isK6RtkLtiLaunchEnabled,
            }),
        );
    }, [dispatch, isK6RtkLtiLaunchEnabled]);

    useEffect(() => {
        updatePrimaryLanguage(languages?.length === 1 ? languages[0].code : 'en-US');
        setAvailableLanguages(languages);
        addTerritoryToStore(territory);
        addProductVariantCodeToStore(productVariantCode);
        addConfigurationToStore(productConfiguration);
        addContentEntitlementToStore(contentEntitlement);
        addTocsAppToStore(tocsApp);

        gaSet({ tocsApp, productVariantCode });
        setUpdated(true);
    }, []);

    if (updated) {
        return children ?? null;
    }
    return null;
};

export default Subscriptions;
