import React from 'react';
import { Route } from 'react-router-dom';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import interactiveUrl from './urls';
import UpdatePasswordRequired from '../../components/UpdatePasswordRequired';

const Interactive = React.lazy(() => import(/* webpackChunkName: "Interactive" */ '.'));

const routes = [
    <Route
        path={`${interactiveUrl}/:slug/:id`}
        exact
        render={props => (
            <WithAuthentication>
                <UpdatePasswordRequired>
                    <Interactive {...props} />
                </UpdatePasswordRequired>
            </WithAuthentication>
        )}
        key={Interactive}
    />,
    <Route
        path={`${interactiveUrl}/:id`}
        exact
        render={props => (
            <WithAuthentication>
                <UpdatePasswordRequired>
                    <Interactive {...props} />
                </UpdatePasswordRequired>
            </WithAuthentication>
        )}
        key={Interactive}
    />,
];

const generateInteractiveRoute = (slug, id) => `${interactiveUrl}/${slug}/${btoa(id)}`;

export { generateInteractiveRoute };

export default routes;
