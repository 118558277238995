import React from 'react';
import useUserRole from '../../../hooks/useUserRole';
import { STUDENT } from '../../../constants/userRoles';
import Layout from '../../../layout/Layout';
import PageError from '../PageError';
import { useTranslation } from '../../../hooks/useTranslation';

export const ErrorMessageComponent = () => {
    const { t } = useTranslation();

    const userRole = useUserRole();
    let errorMessage = t('error_messages.no_access_message_default', "You aren't authorised to view this page.");

    if (userRole === STUDENT) {
        errorMessage = t(
            'error_messages.no_access_message_student',
            'Please check with your teacher that they have given you the correct link.',
        );
    }
    const buttonText = t('error-messages.go_to_dashboard', 'Go to my dashboard');
    const errorTitle = t('error_messages.no_access_title', "Uh Oh! You don't have access to this page!");

    return (
        <PageError
            buttonConfig={{
                label: buttonText,
                primary: true,
                to: '/',
            }}
            message={errorMessage}
            title={errorTitle}
        />
    );
};

const RoleErrorComponent = () => <Layout component={ErrorMessageComponent} withHeader withFooter />;

export default RoleErrorComponent;
