import React from 'react';
import { AsyncComponent } from '@twigeducation/async-component';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import rosteringHeavenUrl from './urls';
import AsyncComponentError from '../../components/Error/AsyncComponentError';

const RosteringHeaven = props => (
    <AsyncComponent
        appName="ts-rostering-heaven-mfe"
        componentName="RosteringHeaven"
        errorComponent={AsyncComponentError}
        authServiceUrl={window.config.AUTHENTICATION_URL}
        classLinkSyncWorkerUrl={window.config.CLASSLINK_SYNC_WORKER_API}
        cleverSyncWorkerUrl={window.config.CLEVER_SYNC_WORKER_API}
        csvRosteringTSFWorkerUrl={window.config.CSV_ROSTERING_TSF_WORKER_API}
        csvRosteringORWorkerUrl={window.config.CSV_ROSTERING_OR_WORKER_API}
        csvRosteringJobServiceUrl={window.config.CSV_ROSTERING_JOB_SERVICE_API}
        trialAccountServiceUrl={window.config.TS_TRIAL_ACCOUNT_SERVICE_API}
        oidcProviderUrl={window.config.OIDC_PROVIDER_URL}
        ssoClientId={window.config.SSO_CLIENT_ID}
        {...props}
    />
);

const routes = [
    <AuthenticatedLayoutRoute
        path={rosteringHeavenUrl}
        component={RosteringHeaven}
        key={rosteringHeavenUrl}
        withConstellation={false}
        roles={['TWIGADMIN', 'SUPERUSER']}
    />,
];

export default routes;
