import React from 'react';
import { Translation } from '../../../components/Translation';
import { FailurePageContainer, FailurePageHeader, FailureSection, StyledInfo } from './FailurePage.styled';
import contactUsMessage from '../Assets/SharedMessages';

function FailurePage(failures) {
    return (
        <FailurePageContainer>
            <FailurePageHeader>
                <h3>
                    <StyledInfo size={47} color="#FB2162" theme={{ iconSet: 'MIDDLE_SCHOOL' }} />
                    <Translation
                        translationKey="troubleshooter.fail.header"
                        defaultValue="Houston, we may have a problem"
                    />
                </h3>
            </FailurePageHeader>
            <Translation
                translationKey="troubleshooter.fail.body_start"
                defaultValue="Please address the issues listed below and then revisit this page to verify you have fixed them. "
            />
            {contactUsMessage}
            {failures.map(failure => (
                <FailureSection key={failure.heading}>
                    <h4>{failure.heading}</h4>
                    {failure.body}
                </FailureSection>
            ))}
        </FailurePageContainer>
    );
}

export default FailurePage;
