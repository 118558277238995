import React from 'react';
import PropTypes from 'prop-types';
import './scss/DashboardPanel.scss';

// comment

const DashboardPanel = ({ children, title, subTitle, minContainerHeight }) => (
    <section className="dashboard-panel">
        <h2 className="dashboard-panel__heading">
            {title}
            {subTitle && <span className="dashboard-panel__sub-title">{subTitle}</span>}
        </h2>

        <div className="dashboard-panel__content" style={{ minHeight: minContainerHeight || 'auto' }}>
            {children}
        </div>
    </section>
);

DashboardPanel.defaultProps = {
    subTitle: '',
    minContainerHeight: '',
};

DashboardPanel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    title: PropTypes.node.isRequired,
    subTitle: PropTypes.node,
    minContainerHeight: PropTypes.string,
};

export default DashboardPanel;
