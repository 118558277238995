import { actions } from '../../actions/isLti/isLti';

const isLtiHost = (state = false, action) => {
    switch (action.type) {
        case actions.SET_IS_LTI:
            if ([undefined, true].includes(action.payload.flagEnabled)) {
                return action.payload.isLtiDeployment;
            }

            return (
                action.payload?.productConfiguration?.some(
                    config =>
                        config.__typename === 'ProductConfiguration' &&
                        config.value === 'LTI' &&
                        config.key === 'integrationType',
                ) || false
            );
        default:
            return state;
    }
};

export default isLtiHost;
