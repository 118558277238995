import React from 'react';
import AssessmentExplorer from '.';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import { AssessmentExplorerUrl, AssessmentExplorerReviewUrl } from './urls';

const routes = () => {
    const routesMap = [];
    // handling Grade & Module routes, territory can be extracted from store
    routesMap.push(
        <AuthenticatedLayoutRoute
            component={AssessmentExplorer}
            exact
            key="/assessments-explorer-review"
            path={AssessmentExplorerReviewUrl}
            title="Assessment Explorer"
        />,
        <AuthenticatedLayoutRoute
            component={AssessmentExplorer}
            exact
            key="/assessments-explorer-review/grade"
            path={`${AssessmentExplorerReviewUrl}/:gradeId`}
            title="Assessment Explorer"
        />,
        <AuthenticatedLayoutRoute
            component={AssessmentExplorer}
            exact
            key="/assessments-explorer-review/grade/module"
            path={`${AssessmentExplorerReviewUrl}/:gradeId/:moduleId`}
            title="Assessment Explorer"
        />,
        <AuthenticatedLayoutRoute
            component={AssessmentExplorer}
            exact
            key="/assessments-explorer"
            path={AssessmentExplorerUrl}
            title="Assessment Explorer"
        />,
        <AuthenticatedLayoutRoute
            component={AssessmentExplorer}
            exact
            key="/assessments-explorer/grade"
            path={`${AssessmentExplorerUrl}/:gradeId`}
            title="Assessment Explorer"
        />,
        <AuthenticatedLayoutRoute
            component={AssessmentExplorer}
            exact
            key="/assessments-explorer/grade/module"
            path={`${AssessmentExplorerUrl}/:gradeId/:moduleId`}
            title="Assessment Explorer"
        />,
    );

    return routesMap;
};

export default routes;
