import { graphql } from '@apollo/client/react/hoc';
import React from 'react';
import PropTypes from 'prop-types';

const withFeatureFlaggedQueryByCurrentState = ({
    defaultParams,
    featureParams,
    defaultQuery,
    featureQuery,
}) => Component => {
    let WithDefaultQuery = Component;
    if (defaultQuery) {
        WithDefaultQuery = graphql(defaultQuery, defaultParams)(Component);
    }
    const WithFeatureQuery = graphql(featureQuery || defaultQuery, featureParams || defaultParams)(Component);

    const WithFeatureFlaggedQuery = props =>
        props.featureState ? <WithFeatureQuery {...props} /> : <WithDefaultQuery {...props} />;
    WithFeatureFlaggedQuery.propTypes = {
        featureState: PropTypes.bool.isRequired,
    };
    return WithFeatureFlaggedQuery;
};

export default withFeatureFlaggedQueryByCurrentState;
