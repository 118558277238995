import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../components/Footer';
import Header from '../components/Header';
import './scss/LayoutRoute.scss';

const LayoutWithStickyHeader = ({ component: Component, withConstellation, withFooter, ...props }) => {
    const className = withConstellation
        ? 'layout-route__wrapper layout-route__with-constellation'
        : 'layout-route__wrapper layout-route';
    return (
        <div className={className}>
            <a href="#content" className="layout-route__skip-to-content">
                Skip to content
            </a>
            <div className="layout-route__content layout-sticky">
                <div className="layout-route__header-container">
                    <div style={{ transform: 'unset' }}>
                        <Header isSticky />
                    </div>
                </div>
                <main id="content" className="layout-route__content-main">
                    <Component {...props} />
                </main>
            </div>
            {withFooter && (
                <div className="layout-route__footer">
                    <Footer />
                </div>
            )}
        </div>
    );
};

LayoutWithStickyHeader.defaultProps = {
    withConstellation: true,
};

LayoutWithStickyHeader.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    withConstellation: PropTypes.bool,
    withFooter: PropTypes.bool.isRequired,
};

export default LayoutWithStickyHeader;
