export const actions = {
    DISABLE_TURNIN_BUTTON: 'DISABLE_TURNIN_BUTTON',
    ENABLE_TURNIN_BUTTON: 'ENABLE_TURNIN_BUTTON',
};

export const enableTurnInButton = () => ({
    type: actions.ENABLE_TURNIN_BUTTON,
});

export const disableTurnInButton = () => ({
    type: actions.DISABLE_TURNIN_BUTTON,
});
