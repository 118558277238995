/* eslint-disable graphql/template-strings */
import { gql } from '@apollo/client';

export const marketingSiteQuery = gql`
    query GetVersions($applicationId: String!) {
        navigationData: getVersions(applicationId: $applicationId) {
            marketingsites: first(filter: { type: "pack", subtype: "navigation" }) {
                relatedTo {
                    all(filter: { type: "link" }) {
                        edges {
                            node {
                                id
                                attributes: allAttributes
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const tsK6MarketingSiteUrlQuery = gql`
    query tsK6MarketingSiteUrlPages(
        $sort: [String]
        $pagination: PaginationArg
        $filters: TsK6MarketingSiteUrlPageFiltersInput
    ) {
        tsK6MarketingSiteUrlPages(sort: $sort, pagination: $pagination, filters: $filters) {
            data {
                id
                attributes {
                    tocsApp
                    title
                    url
                    isAvailable
                }
            }
        }
    }
`;

export const params = {
    skip: props => !props.tocsApp,
    options: props => ({
        variables: {
            applicationId: props.tocsApp,
        },
    }),
};

export const paramsForStrapi = {
    skip: props => !props.tocsApp,
    options: props => ({
        variables: {
            filters: {
                tocsApp: {
                    eq: props.tocsApp,
                },
                isAvailable: {
                    eq: 'TRUE',
                },
            },
            pagination: {
                limit: -1,
            },
            sort: 'id',
        },
    }),
};
