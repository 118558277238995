import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IframePage from './IframePage';

const mapStateToProps = state => ({
    territory: state.subscriptions.territory,
    sites: state.marketingSites,
});

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(IframePage);
