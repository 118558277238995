import React from 'react';
import PropTypes from 'prop-types';
import { ThemedCollapseContainer, StyledArrow } from './index.styles';

const ThemedCollapse = ({ children, arrowPosition, arrowAriaLabel, styles, ...props }) => (
    <ThemedCollapseContainer
        accordion
        expandIcon={({ isActive }) => (
            <StyledArrow active={isActive} arrowPosition={arrowPosition} aria-label={arrowAriaLabel} />
        )}
        styles={styles}
        {...props}
    >
        {children}
    </ThemedCollapseContainer>
);

ThemedCollapse.defaultProps = {
    arrowPosition: 'center',
    arrowAriaLabel: 'Expand arrow',
    styles: {
        accordionLineStyle: '2px solid #128383',
        lastPanelMarginBottom: '2.5rem',
    },
};

ThemedCollapse.propTypes = {
    children: PropTypes.node.isRequired,
    arrowPosition: PropTypes.string,
    arrowAriaLabel: PropTypes.string,
    styles: PropTypes.shape({
        accordionLineStyle: PropTypes.string, // border-bottom style
        lastPanelMarginBottom: PropTypes.string,
    }),
};

export default ThemedCollapse;
