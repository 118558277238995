import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Layout from './Layout';
import LayoutWithSwoosh from './LayoutWithSwoosh';
import LayoutWithStickyHeader from './LayoutWithStickyHeader';
import './scss/LayoutRoute.scss';

const LayoutRoute = ({
    component: Component,
    withConstellation,
    withFooter,
    withHeader,
    withStickyHeader,
    withSwoosh,
    ...props
}) => {
    let LayoutComponent = Layout;
    if (withSwoosh) {
        LayoutComponent = LayoutWithSwoosh;
    }
    if (withStickyHeader) {
        LayoutComponent = LayoutWithStickyHeader;
    }

    return (
        <Route
            {...props}
            render={matchProps => (
                <LayoutComponent
                    component={Component}
                    withConstellation={withConstellation}
                    withFooter={withFooter}
                    withHeader={withHeader}
                    {...matchProps}
                />
            )}
        />
    );
};

LayoutRoute.defaultProps = {
    withConstellation: true,
    withFooter: true,
    withHeader: true,
    withStickyHeader: false,
    withSwoosh: false,
};

LayoutRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    withConstellation: PropTypes.bool,
    withFooter: PropTypes.bool,
    withHeader: PropTypes.bool,
    withStickyHeader: PropTypes.bool,
    withSwoosh: PropTypes.bool,
};

export default LayoutRoute;
