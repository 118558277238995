export const territories = {
    CALIFORNIA: 'CA NGSS',
    NATIONAL: 'Next Gen',
    OKLAHOMA: 'OAS-Science',
    SOUTH_CAROLINA: 'SCCCR',
    WEST_VIRGINIA: 'West Virginia',
    NEW_YORK: 'New York',
    INDIANA: 'Indiana',
    PENNSYLVANIA: 'Pennsylvania',
    OREGON: 'Oregon',
};

export const assessmentsExplorerSrcs = {
    [territories.CALIFORNIA]: 'https://www.go2.twigscience.com/a-explorer/CA',
    [territories.NATIONAL]: 'https://www.go2.twigscience.com/a-explorer/NG',
    [territories.OKLAHOMA]: 'https://www.go2.twigscience.com/a-explorer/OK',
    [territories.SOUTH_CAROLINA]: 'https://www.go2.twigscience.com/a-explorer/SC',
    [territories.WEST_VIRGINIA]: 'https://www.go2.twigscience.com/a-explorer/WV',
    [territories.NEW_YORK]: 'https://go2.twigscience.com/a-explorer/NY',
    [territories.INDIANA]: 'https://go2.twigscience.com/a-explorer/IN',
    [territories.PENNSYLVANIA]: 'https://go2.twigscience.com/a-explorer/PA',
    [territories.OREGON]: 'https://go2.twigscience.com/a-explorer/OR',
};

export const contentsExplorerSrcs = {
    [territories.CALIFORNIA]: 'https://www.go2.twigscience.com/c-explorer/CA',
    [territories.NATIONAL]: 'https://www.go2.twigscience.com/c-explorer/NG',
    [territories.OKLAHOMA]: 'https://www.go2.twigscience.com/c-explorer/OK',
    [territories.SOUTH_CAROLINA]: 'https://www.go2.twigscience.com/c-explorer/SC',
    [territories.WEST_VIRGINIA]: 'https://www.go2.twigscience.com/c-explorer/WV',
    [territories.NEW_YORK]: 'https://go2.twigscience.com/c-explorer/NY',
    [territories.INDIANA]: 'https://go2.twigscience.com/c-explorer/IN',
    [territories.PENNSYLVANIA]: 'https://go2.twigscience.com/c-explorer/PA',
    [territories.OREGON]: 'https://go2.twigscience.com/c-explorer/OR',
};
export const productVariants = {
    OR: 'tscore-or',
    PA: 'tscore-pa',
    AL: 'tscore-al',
};

export const supportCenterDetails = {
    'tscore-or': {
        url: 'https://support.twigscience.com/s/article/Oregon-Elementary',
        standards: 'Oregon Science Standards',
    },
    'tscore-pa': {
        url: 'https://support.twigscience.com/s/article/Pennsylvania-Elementary',
        standards:
            'Pennsylvania Science, Technology, & Engineering, and Environmental Literacy & Sustainability Standards',
    },
    'tscore-al': {
        url: 'https://help.twigscience.com/hc/en-us/articles/23439088861847-Alabama-Elementary',
        standards: 'Alabama Course of Study: Science Standards',
    },
};
