import React from 'react';
import drivingQuestionUrl from './urls';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import RoleErrorComponent from '../../components/Error/RoleErrorComponent';
import getResourceId from '../../helpers/getResourceId';

const DrivingQuestion = React.lazy(() => import(/* webpackChunkName: "DrivingQuestion" */ '.'));

const routes = [
    <AuthenticatedLayoutRoute
        component={DrivingQuestion}
        exact
        key={DrivingQuestion}
        path={`${drivingQuestionUrl}/:slug/:id`}
        roles={['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER']}
        RoleErrorComponent={RoleErrorComponent}
    />,
];

export const generateDrivingQuestionUrl = (slug, id) => {
    const resourceId = getResourceId(id);
    return `${drivingQuestionUrl}/${slug}/${btoa(resourceId)}`;
};

export default routes;
