import { actions } from '../actions';

const assetsReducer = (state = false, action) => {
    let newState = state;
    switch (action.type) {
        case actions.SET_HIDE_ASSETS_ASSIGN_OPTIONS:
            newState = action.value;
            break;
        case actions.REMOVE_HIDE_ASSETS_ASSIGN_OPTIONS:
            newState = action.value;
            break;
        default:
            return newState;
    }
    return newState;
};

export default assetsReducer;
