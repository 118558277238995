import React from 'react';
import getResourceId from '../../helpers/getResourceId';
import subjectKnowledgeUrl from './urls';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';

const SubjectKnowledge = React.lazy(() => import(/* webpackChunkName: "SubjectKnowledge" */ '.'));

const route = (
    <AuthenticatedLayoutRoute
        component={SubjectKnowledge}
        exact
        path={`/${subjectKnowledgeUrl}/:slug/:id`}
        roles={['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER']}
        withSwoosh
    />
);

export const generateSubjectKnowledgeUrl = (slug, id) => `/${subjectKnowledgeUrl}/${slug}/${btoa(getResourceId(id))}`;

export default route;
