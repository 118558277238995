import React from 'react';
import { Route } from 'react-router-dom';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import UpdatePasswordRequired from '../../components/UpdatePasswordRequired';
import videoUrl from './urls';

const Video = React.lazy(() => import(/* webpackChunkName: "Video" */ '.'));
const Transcript = React.lazy(() => import(/* webpackChunkName: "Transcript" */ './Transcript'));

const videoRoute = <AuthenticatedLayoutRoute path={`${videoUrl}/:slug/:id`} exact component={Video} key="Video" />;

const transcriptRoute = (
    <Route
        exact
        key="Transcript"
        path={`${videoUrl}/:slug/:id/transcript/:locale`}
        render={props => (
            <WithAuthentication>
                <UpdatePasswordRequired>
                    <Transcript {...props} />
                </UpdatePasswordRequired>
            </WithAuthentication>
        )}
    />
);

const generateVideoRoute = (slug, id) => `${videoUrl}/${slug}/${btoa(id)}`;

export { generateVideoRoute };

export default [videoRoute, transcriptRoute];
