import React from 'react';
import ContentsExplorer from '.';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import contentsExplorerUrl from './urls';

const routes = () => {
    const routesMap = [];
    // handling Grade & Module routes, territory can be extracted from store

    routesMap.push(
        <AuthenticatedLayoutRoute
            component={ContentsExplorer}
            exact
            key="/contents-explorer"
            path={contentsExplorerUrl}
            title="Contents Explorer"
        />,
        <AuthenticatedLayoutRoute
            component={ContentsExplorer}
            exact
            key="/contents-explorer/grade"
            path={`${contentsExplorerUrl}/:gradeId`}
            title="Contents Explorer"
        />,
        <AuthenticatedLayoutRoute
            component={ContentsExplorer}
            exact
            key="/contents-explorer/grade/module"
            path={`${contentsExplorerUrl}/:gradeId/:moduleId`}
            title="Contents Explorer"
        />,
    );

    return routesMap;
};

export default routes;
