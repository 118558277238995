import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@twigeducation/ts-fe-components';
import { useTranslation } from '../../../hooks/useTranslation';
import {
    ParentContainer,
    DescriptionWrapper,
    LineWrapper,
    ImageWrapper,
    ContentContainer,
    ButtonContainer,
    DescriptionContainer,
    HelpImageWrapper,
    WelcomeTourGifWrapper,
} from './EndOnboardingSlide.styles';
import volcanoColor from '../../../../svg/volcano-color.svg';
import rocketShipLaunching from '../../../../svg/rocket-launching.svg';
import help from '../../../../svg/help.svg';
import gif from './welcome-tour.gif';

const EndOnboardingSlide = ({ closeFunc, finishedOnboarding }) => {
    const { t } = useTranslation();

    return (
        <ParentContainer>
            <ImageWrapper src={finishedOnboarding ? rocketShipLaunching : volcanoColor} />
            <ContentContainer>
                <h1>
                    {finishedOnboarding
                        ? t(
                              'teacher_onboarding.finished_onboarding_slide.title',
                              "You're now ready to take off with Twig Science!",
                          )
                        : t('teacher_onboarding.end_onboarding_slide.title', 'Finish tour another time?')}
                </h1>
                <LineWrapper />
                <DescriptionContainer>
                    <HelpImageWrapper src={help} />
                    <DescriptionWrapper>
                        {t(
                            'teacher_onboarding.end_onboarding_slide.description',
                            'You can find this Welcome Tour again in the Help menu, along with our ',
                        )}
                        <a href="https://support.twigscience.com/s/" target="_blank" rel="noopener noreferrer">
                            {t('teacher_onboarding.end_onboarding_slide.support_center_label', 'Support Center')}
                        </a>
                    </DescriptionWrapper>
                </DescriptionContainer>
                {!finishedOnboarding && <WelcomeTourGifWrapper src={gif} />}
                <ButtonContainer>
                    <Button onClick={closeFunc} primary>
                        {t('teacher_onboarding.end_onboarding_slide.close_button_label', 'Close')}
                    </Button>
                </ButtonContainer>
            </ContentContainer>
        </ParentContainer>
    );
};

EndOnboardingSlide.propTypes = {
    closeFunc: PropTypes.func.isRequired,
    finishedOnboarding: PropTypes.bool.isRequired,
};

export default EndOnboardingSlide;
