import React from 'react';
import { Box, IconWrapper, TextWrapper } from './index.styled';

const BoxWithIconAndText = ({ icon, label, value, isSelected, handleTileClick }) => {
    return (
        <Box onClick={() => handleTileClick(value)} style={{ backgroundColor: isSelected ? '#dc0f5d' : '#0b7777' }}>
            <IconWrapper>{icon}</IconWrapper>
            <TextWrapper>
                <span>{label}</span>
            </TextWrapper>
        </Box>
    );
};

export default BoxWithIconAndText;
