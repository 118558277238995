import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { LoadingPlaceholder } from '@twigeducation/ts-fe-components';
import { useTranslation } from '../../../../hooks/useTranslation';
import { ContentContainer } from './TableOfContents.styled';
import tsContentExplorersQuery from './TableOfContents.query';
import ThemedCollapse from '../../../../components/ThemedCollapse';
import CourseTypePanel from '../CourseTypePanel';
import HeaderIcons from '../HeaderIcons';
import ContentMissing from '../../../LessonExplorer/ContentMissing/ContentMissing';

const TableOfContents = ({ gradeId, moduleId, versionId }) => {
    const isTableOfContents = gradeId && moduleId;
    const appState = useSelector(state => state);
    const { t } = useTranslation();

    const { data, loading, error } = useQuery(tsContentExplorersQuery, {
        variables: {
            filters: {
                application: {
                    containsi: appState?.subscriptions?.tocsApp,
                },
                gradePackId: {
                    eq: versionId,
                },
                modulePackId: {
                    eq: moduleId,
                },
                language: {
                    eq: appState?.i18n?.primaryLanguage,
                },
            },
            pagination: {
                limit: -1,
            },
        },
    });

    if (loading) {
        return (
            <ContentContainer>
                <LoadingPlaceholder />
            </ContentContainer>
        );
    }

    const contentExploreData = data?.tsContentExplorers?.data;

    if (error || !contentExploreData) {
        return (
            <ContentContainer>
                <ContentMissing
                    headingKey="table_of_contents.error_heading"
                    headingValue={"Oops! We can't load this content just now."}
                    bodyKey="table_of_contents.error_body"
                    bodyValue={
                        "Sorry, something has gone wrong and we can't load this content. Please reload the page to try again."
                    }
                />
            </ContentContainer>
        );
    }

    const fullCourse = contentExploreData.filter(el => el.attributes.courseType === 'Full_Course');
    const fastTrack = contentExploreData.filter(el => el.attributes.courseType === 'Fast_Track');
    const twigCoach = contentExploreData.filter(el => el.attributes.courseType === 'Twig_Coach');

    const coursePanels = [
        {
            headerTitle: t('table_of_contents.full_course', 'Full Course'),
            data: fullCourse,
        },
        {
            headerTitle: t('table_of_contents.fast_track', 'Fast Track'),
            data: fastTrack,
        },
        {
            headerTitle: t('table_of_contents.twig_coach', 'Twig Coach'),
            data: twigCoach,
        },
    ];

    return (
        isTableOfContents && (
            <ContentContainer>
                <HeaderIcons />
                <ThemedCollapse
                    arrowPosition="center"
                    styles={{ accordionLineStyle: '1px solid #cfcdcd' }}
                    arrowAriaLabel={t('table_of_contents.expand_course', 'Expand course')}
                >
                    {coursePanels.map(
                        coursePanel =>
                            coursePanel.data.length > 0 && (
                                <CourseTypePanel key={coursePanel.headerTitle} {...coursePanel} />
                            ),
                    )}
                </ThemedCollapse>
            </ContentContainer>
        )
    );
};

TableOfContents.defaultProps = {
    gradeId: null,
    moduleId: null,
    versionId: null,
};

TableOfContents.propTypes = {
    gradeId: PropTypes.string,
    moduleId: PropTypes.string,
    versionId: PropTypes.string,
};

export default TableOfContents;
