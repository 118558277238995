import { actions } from '../../actions/articleNotes';

const articleNotesReducer = (state = {}, action) => {
    switch (action.type) {
        case actions.REMOVE_ALL_ARTICLE_NOTES:
            return {};
        case actions.UPDATE_ARTICLE_NOTE:
            return { ...state, ...action.value };
        case actions.REMOVE_ARTICLE_NOTE: {
            const articleNotesCopy = { ...state };
            if (articleNotesCopy[action.value]) {
                delete articleNotesCopy[action.value];
            }
            return {
                ...articleNotesCopy,
            };
        }
        default:
            return state;
    }
};

export default articleNotesReducer;
