import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import { Button } from '@twigeducation/ts-fe-components';
import { Print } from '@twigeducation/ts-fe-components/components/Icons';
import SafeHTML from '@twigeducation/react-safe-html';
import Collapse from '../Collapse';
import { TranscriptPanel, ButtonContainer, StyledTab } from './Styled/Transcripts.styled';
import { useTranslation } from '../../hooks/useTranslation';
import { Translation } from '../Translation';
import skipReadSpeakerClass from '../../constants/readSpeaker';

const Transcripts = ({ className, history: { createHref }, film, transcripts, supportedLanguages }) => {
    const [selectedTabIndex, setTabIndex] = useState(0);
    const { t } = useTranslation();

    const changeTab = index => {
        setTabIndex(index);
    };

    const getTranscriptUrl = (id, slug, locale) => {
        const href = createHref({ pathname: `/video/${slug}/${btoa(id)}/transcript/${locale}` });
        return `${window.location.origin}${href}`;
    };

    if (transcripts.length > 0) {
        return (
            <Collapse accordion className={className}>
                {transcripts.length > 1 ? (
                    <TranscriptPanel
                        header={t('general.transcripts_panel_header', 'Transcript')}
                        headerClass="transcripts-header"
                        showArrow
                    >
                        <Tabs selectedIndex={selectedTabIndex} onSelect={changeTab} data-at="transcript-languages-tabs">
                            <TabList data-at="transcript-languages-tabs-list">
                                {transcripts
                                    .filter(alternateVersion =>
                                        supportedLanguages.some(
                                            supportedLanguage =>
                                                supportedLanguage.code === alternateVersion.languageCode,
                                        ),
                                    )
                                    .map(transcript => (
                                        <StyledTab
                                            key={`${transcript.languageName}-tab`}
                                            data-at="transcript-language-tab"
                                            defaultFocus
                                        >
                                            {transcript.languageName}
                                        </StyledTab>
                                    ))}
                            </TabList>
                            <ButtonContainer className={skipReadSpeakerClass}>
                                <Button
                                    iconLeft
                                    secondary
                                    target="_blank"
                                    href={getTranscriptUrl(
                                        film.id,
                                        film.slug,
                                        transcripts[selectedTabIndex].languageCode,
                                    )}
                                >
                                    <Print size={15} aria-hidden />
                                    <Translation
                                        translationKey="general.transcripts_button_print_title"
                                        defaultValue="Print"
                                    />
                                </Button>
                            </ButtonContainer>
                            {transcripts
                                .filter(alternateVersion =>
                                    supportedLanguages.some(
                                        supportedLanguage => supportedLanguage.code === alternateVersion.languageCode,
                                    ),
                                )
                                .map(transcript => (
                                    <TabPanel key={`${transcript.languageName}-tab`}>
                                        <SafeHTML>{transcript.transcript}</SafeHTML>
                                    </TabPanel>
                                ))}
                        </Tabs>
                    </TranscriptPanel>
                ) : (
                    <TranscriptPanel
                        header={t('general.transcripts_panel_header', 'Transcript')}
                        headerClass="transcripts-header"
                    >
                        <ButtonContainer className={skipReadSpeakerClass}>
                            <Button
                                iconLeft
                                secondary
                                target="_blank"
                                href={getTranscriptUrl(film.id, film.slug, transcripts[selectedTabIndex].languageCode)}
                            >
                                <Print size={15} aria-hidden />
                                <Translation
                                    translationKey="components.transcripts_button_print_title"
                                    defaultValue="Print"
                                />
                            </Button>
                        </ButtonContainer>
                        <SafeHTML>{transcripts[0].transcript}</SafeHTML>
                    </TranscriptPanel>
                )}
            </Collapse>
        );
    }
    return null;
};

Transcripts.defaultProps = {
    className: '',
    supportedLanguages: [{ code: 'en-US' }, { code: 'es-US' }],
};

Transcripts.propTypes = {
    className: PropTypes.string,
    film: PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
        createHref: PropTypes.func.isRequired,
    }).isRequired,
    transcripts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            transcript: PropTypes.string.isRequired,
            languageCode: PropTypes.string.isRequired,
            languageName: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    supportedLanguages: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string.isRequired,
        }),
    ),
};
export default Transcripts;
