import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@twigeducation/ts-fe-components';
import {
    ParentContainer,
    TitleWrapper,
    DescriptionWrapper,
    HeaderPanel,
    ButtonPanel,
    ContentContainer,
    SlideCounter,
} from './OnboardingSlides.styles';
import { useTranslation } from '../../../hooks/useTranslation';
import AssetModalContent from '../../ContentHandler/Components/Asset/AssetModalContent';

const OnboardingSlides = ({
    title,
    description,
    assetId,
    nextSlideFunc,
    previousSlideFunc,
    currentSlide,
    totalSlides,
}) => {
    const { t } = useTranslation();
    const calculatedCurrentSlide = currentSlide + 1;

    return (
        <ParentContainer>
            <HeaderPanel>
                <TitleWrapper>{title}</TitleWrapper>
                <DescriptionWrapper>{description}</DescriptionWrapper>
            </HeaderPanel>
            <ContentContainer className="asset-modal-content__main-file">
                <AssetModalContent
                    maxHeight="55vh"
                    asset={{
                        id: assetId,
                        title,
                        classification: { mediaType: 'film' },
                        slug: '',
                    }}
                />
            </ContentContainer>
            <ButtonPanel>
                <Button onClick={previousSlideFunc} secondary>
                    {t('teacher_onboarding.buttons.previous', 'Previous')}
                </Button>
                <SlideCounter>{`${calculatedCurrentSlide}/${totalSlides}`}</SlideCounter>
                <Button onClick={nextSlideFunc} primary>
                    {calculatedCurrentSlide === totalSlides
                        ? t('teacher_onboarding.buttons.finish', 'Finish')
                        : t('teacher_onboarding.buttons.next', 'Next')}
                </Button>
            </ButtonPanel>
        </ParentContainer>
    );
};

OnboardingSlides.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    assetId: PropTypes.string.isRequired,
    nextSlideFunc: PropTypes.func.isRequired,
    previousSlideFunc: PropTypes.func.isRequired,
    currentSlide: PropTypes.number.isRequired,
    totalSlides: PropTypes.number.isRequired,
};

export default OnboardingSlides;
