import PropTypes from 'prop-types';

const UpdatePasswordRequired = ({ data, children }) => {
    if (data?.currentUser?.passwordResetRequired) {
        window.location.assign(
            `${window.config.OIDC_PROVIDER_URL}/accounts/password-change/` +
                `?next=${encodeURIComponent(window.location.href)}`,
        );
        return null;
    }
    return children;
};

UpdatePasswordRequired.propTypes = {
    data: PropTypes.shape({
        currentUser: PropTypes.shape({
            passwordResetRequired: PropTypes.bool,
        }),
    }).isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default UpdatePasswordRequired;
