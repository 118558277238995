import { actions } from '../../../actions/answers';

const hasUnsavedAnswersReducer = (state = false, action) => {
    switch (action.type) {
        case actions.REMOVE_ALL_ANSWERS:
            return false;
        case actions.UPDATE_ANSWER:
            return true;
        default:
            return state;
    }
};

export default hasUnsavedAnswersReducer;
