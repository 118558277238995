import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { captureException } from '@sentry/react';
import { initLibraryTranslations as initCCLLibraryTranslations } from '@twigeducation/content-component-library';
import { initLibraryTranslations as initTsFecLibraryTranslations } from '@twigeducation/ts-fe-components';
import americanEnglish from '../../../i18n/English-US/translation.json';
import { updatePrimaryLanguage as updatePrimaryLanguageAction } from '../../store/actions/i18n';
import { userPreferencesQuery } from '../../queries/userPreferences.query';
import { gaSet } from '../../helpers/ga';

if (NODE_ENV !== '') {
    const i18nEditorEnabled = window.config.I18N_EDITOR_ENABLED;
    const i18nextConfig = {
        lng: 'en-US',
        fallbackLng: 'en-US',
        interpolation: {
            escapeValue: false,
        },
        load: 'currentOnly',
        debug: NODE_ENV !== 'production',
    };

    i18next.use(initReactI18next);

    if (i18nEditorEnabled) {
        Promise.all([import('../../../../.phrase.yml'), import('i18next-phrase-in-context-editor-post-processor')])
            .then(([{ default: phraseConfig }, { default: PhraseInContextEditorPostProcessor }]) => {
                i18next.use(
                    new PhraseInContextEditorPostProcessor({
                        phraseEnabled: i18nEditorEnabled,
                        projectId: phraseConfig.phrase.project_id,
                        debugMode: NODE_ENV !== 'production',
                    }),
                );
            })
            .catch(error => {
                captureException(error);
            });
    }

    if (window.config.I18N_BACKEND_ENABLED && window.config.I18N_BACKEND_LOAD_PATH) {
        i18next.use(HttpApi);
        i18nextConfig.backend = {
            loadPath: window.config.I18N_BACKEND_LOAD_PATH,
            crossDomain: true,
        };

        if (i18nEditorEnabled) {
            i18nextConfig.postProcess = ['phraseInContextEditor'];
        }
    } else {
        i18nextConfig.resources = {
            'en-US': {
                translation: americanEnglish,
            },
        };
    }

    i18next.init(i18nextConfig);

    initCCLLibraryTranslations();
    initTsFecLibraryTranslations();
}

const I18n = ({ children, updatePrimaryLanguage, primaryLanguage }) => {
    const { i18n, ready } = useTranslation();
    const { data: userPreferencesData } = useQuery(userPreferencesQuery);
    const userPreferences = userPreferencesData?.currentUser?.userPreferences;
    const userPrefencesLanguage = userPreferences?.settings?.primary_language;
    const language = userPrefencesLanguage || primaryLanguage;

    useEffect(() => {
        if (ready && language) {
            i18n.changeLanguage(language);
            gaSet({ i18n_primaryLanguage: language });
            document.documentElement.setAttribute('lang', language);

            if (userPrefencesLanguage) {
                updatePrimaryLanguage(language);
            }
        }
    }, [language]);

    return children;
};

I18n.defaultProps = {
    children: null,
    primaryLanguage: 'en-US',
};

I18n.propTypes = {
    children: PropTypes.node,
    updatePrimaryLanguage: PropTypes.func.isRequired,
    primaryLanguage: PropTypes.string,
};

const mapStateToProps = state => ({
    primaryLanguage: state.i18n.primaryLanguage,
});

const mapDispatchToProps = {
    updatePrimaryLanguage: updatePrimaryLanguageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(I18n);
