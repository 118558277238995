import React from 'react';
import { AsyncComponent } from '@twigeducation/async-component';
import ErrorBoundary from '../../components/ErrorBoundary';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import { presenterUrl, presenterPrintUrl } from './urls';
import store from '../../store/store';
import getResourceId from '../../helpers/getResourceId';
import PageError from '../../components/Error/PageError';
import AsyncComponentError from '../../components/Error/AsyncComponentError';

const Presenter = ErrorBoundary(
    'An error occurred when trying to render the presenter.',
    [],
    PageError,
)(props => (
    <AsyncComponent
        appName="twig-presenter"
        componentName="Presenter"
        errorComponent={AsyncComponentError}
        {...props}
    />
));

const allowedRoles = ['TEACHER', 'SUPERUSER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER'];

const routes = [
    <AuthenticatedLayoutRoute
        path="/presenter/*"
        component={() => <Presenter store={store} />}
        key="/presenter"
        withHeader={false}
        withFooter={false}
        roles={allowedRoles}
    />,
];

const generatePresenterUrl = (lessonId, position) => {
    if (lessonId) {
        // locale should be removed from the id if in the old format
        const resourceId = lessonId ? getResourceId(lessonId) : null;

        if (position) {
            return `${presenterUrl}/${btoa(resourceId)}/${position}`;
        }

        return `${presenterUrl}/${btoa(resourceId)}`;
    }

    return presenterUrl;
};

const generatePresenterPrintUrl = (lessonId, position) => {
    if (lessonId) {
        // locale should be removed from the id if in the old format
        const resourceId = lessonId ? getResourceId(lessonId) : null;

        if (position) {
            return `${presenterPrintUrl}/${btoa(resourceId)}/${position}`;
        }

        return `${presenterPrintUrl}/${btoa(resourceId)}`;
    }
    return presenterPrintUrl;
};

export { generatePresenterUrl, generatePresenterPrintUrl };
export default routes;
