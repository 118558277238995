import React from 'react';
import lessonTypesEnum from '../lessonTypesEnum';
import { Translation } from '../../components/Translation';

const getDecoratorInitials = (mediaType, lessonType = null) => {
    switch (mediaType) {
        case 'assessment_link':
        case 'ExternalLinkAssessment':
        case 'TSAssessment':
            return 'A';
        case 'TSCoreLesson':
            switch (lessonType) {
                case lessonTypesEnum.LESSON:
                    return 'L';
                case lessonTypesEnum.FAST_TRACK_LESSON:
                    return 'FT';
                case lessonTypesEnum.TWIG_COACH_LESSON:
                    return 'GO';
                default:
                    return '';
            }
        case 'TSAncillaryLesson':
            return 'LR';
        case 'DrivingQuestion':
        case 'TSDrivingQuestion':
            return 'DQ';
        default:
            return '';
    }
};

export const getTranslatedDecoratorInitials = (mediaType, lessonType = null) => {
    switch (mediaType) {
        case 'assessment_link':
        case 'ExternalLinkAssessment':
        case 'TSAssessment':
            return (
                <Translation translationKey="teacher_dashboard.curriculum_progress_assessment_label" defaultValue="A" />
            );
        case 'TSCoreLesson':
            switch (lessonType) {
                case lessonTypesEnum.LESSON:
                    return 'L';
                case lessonTypesEnum.FAST_TRACK_LESSON:
                    return (
                        <Translation
                            translationKey="teacher_dashboard.curriculum_progress_ft_label"
                            defaultValue="FT"
                        />
                    );
                case lessonTypesEnum.TWIG_COACH_LESSON:
                    return 'GO';
                default:
                    return '';
            }
        case 'TSAncillaryLesson':
            return 'LR';
        case 'DrivingQuestion':
        case 'TSDrivingQuestion':
            return <Translation translationKey="teacher_dashboard.curriculum_progress_dq_label" defaultValue="DQ" />;
        default:
            return '';
    }
};

// eslint-disable-next-line max-len
export const getDecorator = (order, mediaType, lessonType = null, acronym = '') =>
    `${acronym || getDecoratorInitials(mediaType, lessonType)}${order || ''}.`;

export const getTranslatedDecorator = (order, mediaType, lessonType = null, acronym = '') => {
    if (acronym) {
        return `${acronym}${order || ''}`;
    }
    return getTranslatedDecoratorInitials(mediaType, lessonType);
};

export const generateTitle = (title, order, mediaType, lessonType = null, acronym = '') => {
    const hasInitial = acronym || getDecoratorInitials(mediaType, lessonType).length > 0;
    return hasInitial ? `${getDecorator(order, mediaType, lessonType, acronym)} ${title}` : title;
};

export const generateTranslatedTitle = (title, order, mediaType, lessonType = null, acronym = '') => {
    const Decorator = getTranslatedDecoratorInitials(mediaType, lessonType);
    if (typeof Decorator === 'string') {
        const hasInitial = acronym || getDecoratorInitials(mediaType, lessonType).length > 0;
        return hasInitial ? `${getDecorator(order, mediaType, lessonType, acronym)} ${title}` : title;
    }
    return (
        <>
            {Decorator}
            {order}. {title}
        </>
    );
};
