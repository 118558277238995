import styled from 'styled-components';
import { Button as StyledButton } from '@twigeducation/ts-fe-components';
import breakpoints from '../../../helpers/breakpoint';

export const AssessmentContainer = styled.div`
    height: 750px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
`;

export const MainContainer = styled.div`
    width: 80%;
    margin: 0 auto;
    @media screen and (max-width: ${breakpoints.medium.max}px) {
        width: 100%;
        margin: 0 auto;
    }
`;
export const Section = styled.div`
    border-top: ${props => `3px solid ${props.theme.primaryColor}`};
    &:first-of-type {
        border-top: none;
    }
`;

export const BodyContainer = styled.div`
    padding-top: 30px;
`;

export const HeadSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 20px 0;
`;
export const HeaderTitle = styled.h2`
    font-size: 24px;
`;

export const BackTopButton = styled(StyledButton)`
    font-family: sans-serif;
    font-size: small;
    box-shadow: 0 1px 4px #00000099;
    &:hover {
        background-color: #38ba92;
        transform: none;
        color: #fff;
    }
`;

export const StyledDiv = styled.div`
    margin-left: 26px;
    padding-bottom: 25px;
    color: ${props => props.theme.primaryColor};
    font-style: italic;
    font-size: 18px;
    font-weight: normal;
`;
