import React from 'react';
import PropTypes from 'prop-types';

const withContentEntitlement = Component => {
    const WithContentEntitlement = props => {
        if (!props.data) return <Component {...props} />;
        const { types } = props.data.getUserProductContentEntitlement;
        return (
            <Component
                {...props}
                contentEntitlement={{
                    purchased: types.map(type => type.purchased),
                    identifier: types.map(type => type.identifier),
                }}
            />
        );
    };
    WithContentEntitlement.defaultProps = {
        data: null,
    };
    WithContentEntitlement.propTypes = {
        data: PropTypes.shape({
            getUserProductContentEntitlement: PropTypes.shape({
                types: PropTypes.arrayOf(
                    PropTypes.shape({
                        purchased: PropTypes.bool,
                        identifier: PropTypes.string,
                    }),
                ),
            }),
        }),
    };
    return WithContentEntitlement;
};
export default withContentEntitlement;
