import styled from 'styled-components';

const ContentContainer = styled.div`
    width: 90%;
    margin: auto;
    overflow: scroll;
    max-height: 100vh;
    height: 100%;
`;

const UnderlineSection = styled.div`
    height: 1px;
    width: 100%;
    background-color: rgba(219, 219, 233);
    margin-top: 10px;
`;

export { UnderlineSection, ContentContainer };
