import { generateAudioClipRoute } from '../../apps/Audio/routes';
import { generateDrivingQuestionUrl } from '../../apps/DrivingQuestion/routes';
import { generateFileViewerRoute } from '../../apps/FileViewer/routes';
import { generateGameRoute } from '../../apps/Game/routes';
import { generateInteractiveRoute } from '../../apps/Interactive/routes';
import { generateImageRoute } from '../../apps/Image/routes';
import { generateLessonUrl } from '../../apps/Lesson/routes';
import { generateLessonExplorerUrl } from '../../apps/LessonExplorer/routes';
import { generateLessonObjectivesUrl } from '../../apps/LessonObjectives/routes';
import { generateModuleUrl } from '../../apps/Module/routes';
import { generatePresenterUrl, generatePresenterPrintUrl } from '../../apps/Presenter/routes';
import { generateQuizRoute } from '../../apps/Quiz/routes';
import { generateScienceGlossaryUrl } from '../../apps/ScienceGlossary/routes';
import { generateStudentLessonUrl } from '../../apps/StudentLesson/routes';
import { generateSubjectKnowledgeUrl } from '../../apps/SubjectKnowledge/routes';
import { generateVideoRoute } from '../../apps/Video/routes';
import { generateVisualRoute } from '../../apps/Visual/routes';

export const generateBreadcrumbsRoute = (contentType, ...rest) => {
    const [firstId, firstSlug, secondId] = rest;
    switch (contentType) {
        case 'core_driving_question':
            return generateDrivingQuestionUrl(firstSlug, firstId);
        case 'core_grade':
            return generateLessonExplorerUrl(firstId);
        case 'core_lesson':
        case 'core_distance_learning_lesson':
        case 'core_fast_track_lesson':
        case 'core_ancillary_lesson':
            return generateLessonUrl(firstSlug, firstId, contentType);
        case 'core_module':
            return generateLessonExplorerUrl(firstId, secondId);
        default:
            return '/';
    }
};

export default {
    generateAudioClipRoute,
    generateBreadcrumbsRoute,
    generateDrivingQuestionUrl,
    generateFileViewerRoute,
    generateGameRoute,
    generateImageRoute,
    generateInteractiveRoute,
    generateLessonUrl,
    generateLessonExplorerUrl,
    generateLessonObjectivesUrl,
    generateModuleUrl,
    generatePresenterUrl,
    generatePresenterPrintUrl,
    generateQuizRoute,
    generateScienceGlossaryUrl,
    generateStudentLessonUrl,
    generateSubjectKnowledgeUrl,
    generateVideoRoute,
    generateVisualRoute,
};
