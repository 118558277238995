import React from 'react';
import { RightArrow } from '@twigeducation/ts-fe-components/components/Icons';
import { PropTypes } from 'prop-types';
import { HeadBreadcrumb, BreadcrumbItem } from './AssessmentPanel.styled';
import { useTranslation } from '../../../../../hooks/useTranslation';

const AssessmentBreadcrumb = ({ item }) => {
    const { t } = useTranslation();
    return (
        <HeadBreadcrumb>
            {item.dqOrLr === 'LR' ? (
                <BreadcrumbItem>{t('assessment_panel.leveled_Reader', 'Leveled Reader')}</BreadcrumbItem>
            ) : (
                <BreadcrumbItem>
                    {t('assessment_panel.dq', 'DQ')} {item.dqOrLr}
                </BreadcrumbItem>
            )}
            {item.lessonOrChapter && item.dqOrLr === 'LR' ? (
                <>
                    <RightArrow color="#ff126d" size={8} />
                    <BreadcrumbItem>{item.lessonOrChapter}</BreadcrumbItem>
                </>
            ) : (
                item.lessonOrChapter && (
                    <>
                        <RightArrow color="#ff126d" size={8} />
                        <BreadcrumbItem>
                            {' '}
                            {t('assessment_panel.lesson', 'Lesson ')} {item.lessonOrChapter}
                        </BreadcrumbItem>
                    </>
                )
            )}
            {item.location && (
                <>
                    <RightArrow color="#ff126d" size={8} />
                    <BreadcrumbItem>{item.location}</BreadcrumbItem>
                </>
            )}
        </HeadBreadcrumb>
    );
};

AssessmentBreadcrumb.propTypes = {
    item: PropTypes.shape({
        dq: PropTypes.string,
        id: PropTypes.string,
        lesson: PropTypes.string,
        result: PropTypes.string,
    }).isRequired,
};

export default AssessmentBreadcrumb;
