import { actions } from '../../../actions/teacherOnboarding/showWelcomeTour';

const showWelcomeTourReducer = (state = false, action) => {
    switch (action.type) {
        case actions.START_WELCOME_TOUR:
            return true;
        case actions.CLOSE_WELCOME_TOUR:
            return false;
        default:
            return state;
    }
};

export default showWelcomeTourReducer;
