import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

const SentryWrapper = ({ children }) => {
    const userInfo = useSelector(store => store.userInfo);
    const subscriptions = useSelector(store => store.subscriptions);
    const { tocsApp, productVariantCode } = subscriptions;

    if (userInfo) {
        Sentry.setUser({
            id: userInfo.userId,
            ...userInfo,
        });
        const { schoolId, districtId, role, isTestUser } = userInfo;
        Sentry.setTags({ schoolId, districtId, role, isTestUser });
    }
    if (subscriptions) {
        Sentry.setTags({ tocsApp, productVariantCode });
    }
    return children;
};

export default SentryWrapper;
