import { ADD_MARKETINGSITES } from '../../actions/marketingsites/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case ADD_MARKETINGSITES:
            return {
                ...state,
                marketingSites: action.value,
            };

        default:
            return state;
    }
};
