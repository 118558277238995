import React from 'react';
import PropTypes from 'prop-types';

const withProductConfiguration = Component => {
    const withProductConfigurationObj = props => {
        if (!props.data) return <Component {...props} />;
        const { configuration } = props.data.getUserProductSubscription;

        return <Component {...props} productConfiguration={configuration} />;
    };

    withProductConfigurationObj.defaultProps = {
        data: null,
    };

    withProductConfigurationObj.propTypes = {
        data: PropTypes.shape({
            getUserProductSubscription: PropTypes.shape({
                configuration: PropTypes.array,
            }),
        }),
    };

    return withProductConfigurationObj;
};

export default withProductConfiguration;
