import styled from 'styled-components';

const ResourcesWrapper = styled.div`
    font-family: 'Bariol-Bold';
    width: 90vw;
    margin: 1rem auto;
`;
const BodyWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
const HeadingText = styled.h1``;
const SubheadingText = styled.h2`
    font-size: 1.2rem;
    display: flex;
    gap: 4px;
    margin-top: 1rem;
`;
const ContentSection = styled.div`
    width: 100%;
`;

const FiltersSection = styled.div`
    width: 30%;
`;
const TilesSection = styled.div`
    max-width: fit-content;
    overflow-x: scroll;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 0.8rem;
`;
const DisplayDataSection = styled.div`
    margin-top: 1rem;
`;
const ColoredText = styled.div`
    color: #dc0f5d;
`;

export {
    ResourcesWrapper,
    BodyWrapper,
    ContentSection,
    FiltersSection,
    TilesSection,
    DisplayDataSection,
    HeadingText,
    SubheadingText,
    ColoredText,
};
