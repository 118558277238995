import React from 'react';
import { Translation } from '../../../components/Translation';
import { StyledLink } from '../Successful/ChecksSuccessful.styled';

const contactUsUrl = 'https://help.twigscience.com/hc/en-us/requests/new';

const contactUsMessage = (
    <>
        <Translation translationKey="troubleshooter.success_contact_start" defaultValue="Check out the " />
        <StyledLink
            href="https://help.twigscience.com/hc/en-us/articles/14682502726423-System-Requirements"
            target="_blank"
            rel="noreferrer"
        >
            <Translation translationKey="troubleshooter.system_requirements_link" defaultValue="System requirements" />
        </StyledLink>
        <Translation translationKey="troubleshooter.page_or" defaultValue=" page or " />
        <StyledLink href={contactUsUrl} target="_blank" rel="noreferrer">
            <Translation translationKey="troubleshooter.contact_us" defaultValue="Contact us" />
        </StyledLink>
        <Translation
            translationKey="troubleshooter.success_contact"
            defaultValue=" if you are experiencing other issues."
        />
    </>
);

export default contactUsMessage;
