import React from 'react';
import gameUrl from './urls';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';

const Game = React.lazy(() => import(/* webpackChunkName: "Game" */ '.'));

const route = <AuthenticatedLayoutRoute component={Game} exact path={`${gameUrl}/:gameType/:id`} />;

const generateGameRoute = (slug, id, type) => `${gameUrl}/${type}/${btoa(id)}`;

export { generateGameRoute };

export default route;
