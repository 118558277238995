import store from '../../store/store';

export const setHeader = ({ headers = {} }) => ({
    headers: {
        ...headers,
        'x-tocs-app-name': store.getState().subscriptions.tocsApp,
    },
});

export default (operation, forward) => {
    const hasTocsApp = store.getState().subscriptions && store.getState().subscriptions.tocsApp;
    if (hasTocsApp) {
        operation.setContext(setHeader);
    }
    return forward(operation);
};
