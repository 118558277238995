export const actions = {
    DISABLE_ANSWER_INPUTS: 'DISABLE_ANSWER_INPUTS',
    ENABLE_ANSWER_INPUTS: 'ENABLE_ANSWER_INPUTS',
};

export const disableAnswerInputs = () => ({
    type: actions.DISABLE_ANSWER_INPUTS,
});

export const enableAnswerInputs = () => ({
    type: actions.ENABLE_ANSWER_INPUTS,
});
