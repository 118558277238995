import React from 'react';
import { Route } from 'react-router-dom';
import querystringify from 'querystringify';
import { loginCallbackRoute, logoutCallbackRoute, RedirectToLogin } from '@twigeducation/oidc-client-react';
import RedirectToShortCodeUrl from './RedirectToShortCodeUrl';

const loginRoute = (
    <Route
        path="/login/:districtId?"
        key="login"
        component={props => {
            if (props.match.params.districtId) {
                return <RedirectToShortCodeUrl districtId={props.match.params.districtId} />;
            }
            const querystring = querystringify.parse(props.location.search);
            const nextUrl = querystring.next || '/';
            return <RedirectToLogin nextUrl={nextUrl} />;
        }}
    />
);

export { loginCallbackRoute, logoutCallbackRoute, loginRoute };
