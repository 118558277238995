import styled from 'styled-components';
import { activeIcon } from '../../../../constants/tableOfContents';

const HeadingContainer = styled.div`
    font-size: 14px;
    color: #343a72;
    display: flex;
    justify-content: space-between;
    margin: 0 40px 40px 0;
    flex-wrap: wrap;

    @media screen and (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        flex-wrap: nowrap;
    }
`;

const StyledHeading = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0px;
    }

    @media screen and (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        flex-wrap: nowrap;
        margin-bottom: 0px;
    }
`;

const StyledIconLabel = styled.span`
    font-size: 12px;
`;

const StyledIconThreeDLabel = styled.span`
    font-size: 14px;
    color: ${activeIcon};
`;

export { HeadingContainer, StyledHeading, StyledIconLabel, StyledIconThreeDLabel };
