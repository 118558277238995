import React from 'react';
import { Route } from 'react-router-dom';
import { AsyncComponent } from '@twigeducation/async-component';
import LayoutRoute from '../../layout/LayoutWithSwoosh';
import AsyncComponentError from '../../components/Error/AsyncComponentError';

const Accounts = props => (
    <AsyncComponent
        appName="ts-accounts"
        authUrl={window.config.AUTHENTICATION_URL}
        componentName="Accounts"
        errorComponent={AsyncComponentError}
        unleash={{
            clientId: window.config.UNLEASH_CLIENT_ID,
            url: window.config.UNLEASH_URL,
        }}
        {...props}
    />
);

const routes = [
    <Route
        path="/accounts/*"
        component={() => <Accounts Layout={LayoutRoute} ssoClientId={window.config.SSO_CLIENT_ID} />}
        key="/accounts"
    />,
];

export default routes;
