import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'rc-collapse';
import styled from 'styled-components';
import ThemedCollapse from '../../../../components/ThemedCollapse';
import DQItem from '../DQItem/DQItem';
import LessonItem from '../LessonItem/LessonItem';
import { useTranslation } from '../../../../hooks/useTranslation';

export const CourseTypeHeader = styled.span`
    font-size: 20px;
    margin-bottom: 1.5rem;
`;

const DqHeader = styled.span`
    color: ${props => props.theme.primaryColor};
    margin-bottom: 1rem;
`;

const CourseTypePanel = ({ headerTitle, data, ...props }) => {
    const header = <CourseTypeHeader>{headerTitle}</CourseTypeHeader>;
    const { t } = useTranslation();

    const dqMap = data?.reduce((acc, obj) => {
        if (!acc[obj.attributes.dqNumber]) {
            acc[obj.attributes.dqNumber] = [];
        }
        acc[obj.attributes.dqNumber].push({ ...obj.attributes, id: obj.id });
        return acc;
    }, {});

    Object.values(dqMap).forEach(value =>
        value.sort((a, b) => {
            if (b.typeTitle === 'Assessment' || b.typeTitle === 'Evaluación' || Number(a.order) < Number(b.order)) {
                return -1;
            }
            return 0;
        }),
    );

    const lessonRender = dq =>
        dqMap[dq].map(ele =>
            ele.typeTitle.includes('Driving Question') || ele.typeTitle.includes('Pregunta guía') ? (
                <DQItem key={ele.id} dq={ele} />
            ) : (
                <LessonItem key={ele.id} lesson={ele} />
            ),
        );

    return (
        <Panel {...props} header={header} style={{ marginRight: '40px' }}>
            <ThemedCollapse
                arrowPosition="right"
                arrowAriaLabel={t('table_of_contents.expand_driving_question', 'Expand driving question')}
            >
                {Object.keys(dqMap).map(dq =>
                    // Entries without dqNumber
                    dq === 'null' ? (
                        lessonRender(dq)
                    ) : (
                        <Panel
                            key={dq}
                            header={
                                <DqHeader>
                                    {t('table_of_contents.driving_question', 'Driving Question')} {dq}
                                </DqHeader>
                            }
                        >
                            {lessonRender(dq)}
                        </Panel>
                    ),
                )}
            </ThemedCollapse>
        </Panel>
    );
};

CourseTypePanel.propTypes = {
    headerTitle: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            attributes: PropTypes.shape({
                application: PropTypes.string,
                art: PropTypes.string,
                description: PropTypes.string,
                digital: PropTypes.string,
                dqNumber: PropTypes.string,
                grade: PropTypes.string,
                gradePackId: PropTypes.string,
                handsOn: PropTypes.string,
                language: PropTypes.string,
                moduleNumber: PropTypes.string,
                modulePackId: PropTypes.string,
                order: PropTypes.string,
                reading: PropTypes.string,
                threeD: PropTypes.string,
                title: PropTypes.string,
                typeTitle: PropTypes.string,
                url: PropTypes.string,
                video: PropTypes.string,
            }),
        }),
    ).isRequired,
};

export default CourseTypePanel;
