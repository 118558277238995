import React from 'react';
import { Translation } from '../../../components/Translation';
import { StyledLink, StyledTick, SuccessHeader } from './ChecksSuccessful.styled';
import contactUsMessage from '../Assets/SharedMessages';

function ChecksSuccessful() {
    const speedTestUrl = 'https://www.speedtest.net/';

    return (
        <>
            <SuccessHeader>
                <h3>
                    <StyledTick size={47} color="#15a19a" />
                    <Translation translationKey="troubleshooter.success_title" defaultValue="Everything looks good!" />
                </h3>
            </SuccessHeader>

            <p>
                <Translation
                    translationKey="troubleshooter.success_blurb_v2"
                    defaultValue="Your browser and resolution are configured correctly."
                />
                <Translation
                    translationKey="troubleshooter.success_internet"
                    defaultValue=" If videos and images get stuck loading, stop playing, or are blurry, ensure you have sufficient Internet speed. You can run a "
                />
                <StyledLink href={speedTestUrl} target="_blank" rel="noreferrer">
                    <Translation
                        translationKey="troubleshooter.success_connection_link"
                        defaultValue="connection speed test"
                    />
                </StyledLink>
                <Translation
                    translationKey="troubleshooter.success_internet_end"
                    defaultValue=" to see how fast it is. Our Twig Science platforms require a download speed of at least 3Mbps."
                />
                <br /> <br />
                {contactUsMessage}
            </p>
        </>
    );
}

export default ChecksSuccessful;
