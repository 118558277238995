import styled from 'styled-components';

export const ParentContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 3rem 3rem 3rem;
`;

export const TitleWrapper = styled.h3``;

export const DescriptionWrapper = styled.div``;

export const ContentContainer = styled.div`
    flex: 1;
    padding: 1.5rem 0;
`;

export const HeaderPanel = styled.div``;

export const SlideCounter = styled.div`
    color: ${props => props.theme.twigPinkColor};
    font-weight: bold;
`;

export const ButtonPanel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
