/* eslint-disable graphql/template-strings */
import { gql } from '@apollo/client';

const k6AssessmentsExplorerQuery = gql`
    query k6AssessmentExplorers($filters: K6AssessmentExplorerFiltersInput, $pagination: PaginationArg) {
        k6AssessmentExplorers(filters: $filters, pagination: $pagination) {
            data {
                id
                attributes {
                    application
                    language
                    grade
                    moduleNumber
                    modulePackId
                    dqOrLr
                    lessonOrChapter
                    assessmentType
                    location
                    assessmentTool
                    toolUrl
                    whatStudentsDo
                    responseType
                    whatsBeingAssessed
                    teacherLessonPrintPage
                    teacherLessonPrintLink
                    teacherLessonUrl
                    apUrl
                    studentLessonPrintPage
                    studentLessonPrintLink
                }
            }
        }
    }
`;

export default k6AssessmentsExplorerQuery;
