import styled from 'styled-components';

export const ParentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 2rem;
`;

export const ContentContainer = styled.div`
    padding: 2rem;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem 0;
`;

export const DescriptionWrapper = styled.div`
    padding-top: 1rem;
`;

export const LineWrapper = styled.div`
    width: 2rem;
    height: 0.5rem;
    border-radius: 10px;
    background-color: ${props => props.theme.twigPinkColor};
`;

export const ImageWrapper = styled.img`
    width: 17.5rem;
    height: 15rem;
`;
