import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'querystringify';

const withForwardedPath = Component => {
    const WithForwardedPath = props => {
        const search = parse(props.location.search);
        const path = search.path || search.PATH;
        if (!path) return <Component {...props} />;
        return <Component {...props} forwardedPath={encodeURI(path)} />;
    };

    WithForwardedPath.defaultProps = {
        location: {
            search: null,
        },
    };

    WithForwardedPath.propTypes = {
        location: PropTypes.shape({
            search: PropTypes.string,
        }),
    };

    return WithForwardedPath;
};

export default withForwardedPath;
