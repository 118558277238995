import './sentry';
import { ApolloProvider } from '@apollo/client';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider, connect } from 'react-redux';
import { elementScrollIntoViewPolyfill, windowScrollToPolyfill } from 'seamless-scroll-polyfill';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'styled-components';
import { LoadingPlaceholder, theme } from '@twigeducation/ts-fe-components';
import { LoadManifests } from '@twigeducation/async-component';
import { AuthenticationProvider } from '@twigeducation/oidc-client-react';
import { UnleashConsumer } from '@twigeducation/unleash-client-react';
import ErrorBoundary from './components/ErrorBoundary';
import client from './apolloClient';
import store from './store/store';
import Routes from './routes';
import CustomUnleash from './components/Unleash';
import { userManager } from './apps/Authentication/userManager';
import App from './App';
import Subscriptions from './components/Subscriptions';
import Marketingsites from './components/Marketingsites';
import Messages from './components/Messages';
import { I18n } from './components/I18n';
import ScrollToTop from './components/ScrollToTop';
import history from './history';
import { setFeatureFlags } from './store/actions/featureFlags';
import './microExport';
import './font';
import '../scss/main.scss';
import SentryWrapper from './SentryWrapper';
import PageError from './components/Error/PageError';
import TeacherOnBoardingModal from './components/TeacherOnboardingModal';
import { initGA } from './helpers/ga';
import { setIsLTI } from './store/actions/isLti/isLti';

elementScrollIntoViewPolyfill();
windowScrollToPolyfill();

window.app = new App();

const ContentComponent = connect(null, { setFeatureFlags, setIsLTI })(props => (
    <CustomUnleash>
        <UnleashConsumer>
            {featureFlags => {
                props.setFeatureFlags(featureFlags);
                return (
                    <Suspense fallback={<p>Loading...</p>}>
                        <Subscriptions>
                            <Marketingsites />
                            <Suspense fallback={<LoadingPlaceholder />}>
                                <I18n>
                                    <ScrollToTop />
                                    <LoadManifests manifestServiceUrl={window.config.MANIFEST_SERVICE_URL}>
                                        <Routes />
                                        {featureFlags['teacher-onboarding'] && <TeacherOnBoardingModal />}
                                    </LoadManifests>
                                    <Messages />
                                </I18n>
                            </Suspense>
                        </Subscriptions>
                    </Suspense>
                );
            }}
        </UnleashConsumer>
    </CustomUnleash>
));

const RootLevelError = () => (
    <div style={{ display: 'flex', height: '100vh', alignItems: 'center' }}>
        <PageError
            buttonConfig={{
                label: 'Go to my Dashboard',
                href: '/',
            }}
            message="We're sorry about that, we'll try to stop it from happening again"
            title="Oops! Something went wrong!"
        />
    </div>
);

const MainComponent = Sentry.withProfiler(
    ErrorBoundary(
        'Top level page error',
        [],
        RootLevelError,
    )(() => (
        <ApolloProvider client={client}>
            <Provider store={store}>
                <SentryWrapper>
                    <Router history={history}>
                        <AuthenticationProvider store={store} userManager={userManager}>
                            <ContentComponent />
                        </AuthenticationProvider>
                    </Router>
                </SentryWrapper>
            </Provider>
        </ApolloProvider>
    )),
);

// Please do not remove this. It is here to ensure at least one component is
// rendered to the DOM while the React app is running. We need this to detect if
// React is mounted or not for the Sentry logging.
const MountIndicator = () => <div style={{ display: 'none' }} />;

const appElement = document.getElementById('app');
initGA(appElement);
ReactDOM.render(
    <>
        <MountIndicator />
        <ThemeProvider theme={theme}>
            <MainComponent />
        </ThemeProvider>
    </>,
    appElement,
);
