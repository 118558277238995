import { ApolloClient, InMemoryCache, ApolloLink, from as compileLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import fetch from 'unfetch';
import setProductCode from './apolloClient/middleware/setProductCode';
import setTocsAppName from './apolloClient/middleware/setTocsAppName';
import setPVAFeatureFlag from './apolloClient/middleware/setPVAFeatureFlag';
import setProductVariantCode from './apolloClient/middleware/setProductVariantCode';
import setTerritory from './apolloClient/middleware/setTerritory';
import possibleTypes from '../../possibleTypes.json';
import { userManager } from './apps/Authentication/userManager';

const graphqlUrl = window.config.CREATE_GRAPHQL_URL_FROM_HOSTNAME
    ? `${window.location.protocol}//${window.location.host}/svc/graphql-gateway/graphql`
    : window.config.PUBLIC_GRAPHQL_URL;

const httpLink = new BatchHttpLink({
    uri: graphqlUrl,
    credentials: 'omit',
    fetch,
});

const OIDCAuthHeadersLink = setContext(() =>
    userManager.getUser().then(user => {
        if (user && user.id_token) {
            return {
                headers: {
                    authorization: `Bearer ${user.id_token}`,
                },
            };
        }
        return {};
    }),
);

const links = [
    OIDCAuthHeadersLink,
    new ApolloLink(setProductCode),
    new ApolloLink(setProductVariantCode),
    new ApolloLink(setTerritory),
    new ApolloLink(setPVAFeatureFlag),
    new ApolloLink(setTocsAppName),
    httpLink,
];

const link = compileLink(links);

const client = new ApolloClient({
    connectToDevTools: window.config.ENABLE_APOLLO_DEV_TOOLS,
    link,
    cache: new InMemoryCache({
        possibleTypes,
        typePolicies: {
            Cloudinary: {
                keyFields: ['publicId'],
            },
        },
    }),
    name: 'twigscience',
    version: window.config.IMAGE_GIT_COMMIT_SHA,
});

export default client;
