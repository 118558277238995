export const actions = {
    UPDATE_ARTICLE_NOTE: 'UPDATE_ARTICLE_NOTE',
    REMOVE_ARTICLE_NOTE: 'REMOVE_ARTICLE_NOTE',
    REMOVE_ALL_ARTICLE_NOTES: 'REMOVE_ALL_ARTICLE_NOTES',
};

export const updateArticleNote = ({ articleId, note, sectionId }) => ({
    type: actions.UPDATE_ARTICLE_NOTE,
    value: { [`${sectionId}-${articleId}`]: { text: note, sectionId } },
});

export const removeArticleNote = ({ articleId, sectionId }) => ({
    type: actions.REMOVE_ARTICLE_NOTE,
    value: `${sectionId}-${articleId}`,
});

export const removeAllArticleNotes = () => ({
    type: actions.REMOVE_ALL_ARTICLE_NOTES,
});
