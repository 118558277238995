import styled from 'styled-components';
import { LoadingPlaceholder } from '@twigeducation/ts-fe-components';
import { Search } from '@twigeducation/ts-fe-components/components/Icons';

export const LoadingHeader = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;

    h3 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img {
        transform: scale(75%);
    }
`;

export const StyledLoadingSpinner = styled(LoadingPlaceholder)`
    left: 50%;
    top: 50%;
`;

export const StyledMagnifyingGlass = styled(Search)`
    margin-right: 5px;
`;

export const StyledLink = styled.a`
    text-decoration: none;
`;
