import React, { createRef, useRef, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { LoadingPlaceholder } from '@twigeducation/ts-fe-components';
import { useTranslation } from '../../../hooks/useTranslation';
import {
    AssessmentContainer,
    MainContainer,
    BodyContainer,
    HeadSection,
    HeaderTitle,
    BackTopButton,
    Section,
    StyledDiv,
} from './Assessments.styled';
import HeaderComponent from './components/HeaderComponent/HeaderComponent';
import AssessmentPanel from './components/AssessmentPanel/AssessmentPanel';
import k6AssessmentsExplorerQuery from './Assessments.query';

const Assessments = ({ gradeId, moduleId, assessmentTabRef }) => {
    const isAssessment = gradeId && moduleId;
    const ref = useRef(null);
    const appState = useSelector(state => state);
    const { t } = useTranslation();
    const [assessmentsExplorers, setAssessmentsExplorers] = useState([]);
    const { data, loading } = useQuery(k6AssessmentsExplorerQuery, {
        variables: {
            filters: {
                application: {
                    containsi: appState?.subscriptions?.tocsApp,
                },
                gradePackId: {
                    eq: gradeId,
                },
                modulePackId: {
                    eq: moduleId,
                },
                language: {
                    eq: appState?.i18n?.primaryLanguage,
                },
            },
            pagination: {
                limit: -1,
            },
        },
    });
    const getData = type =>
        data?.k6AssessmentExplorers?.data
            .filter(elm => elm.attributes.assessmentType.includes(type))
            .sort((a, b) => a.id - b.id);

    const setupAssessmentData = () => {
        const preExplorationData = {
            id: '1',
            title: t('assessment_panel_title.pre_explorations', 'Pre-Explorations (Diagnostic Pre-Assessment)'),
            navigationTitle: t('assessment_header.pre_exploration', 'Pre-Exploration'),
            data: getData('Pre_Exploration'),
        };
        const formativeAssessmentData = {
            id: '2',
            title: t('assessment_panel_title.formative_assessments', 'Formative Assessments (Informal Assessment)'),
            navigationTitle: t('assessment_header.formative_assessment', 'Formative Assessment'),
            data: getData('Formative_Assessment'),
        };
        const proficiencyData = {
            id: '3',
            title: t('assessment_panel_title.key_language_proficiency', 'Key Language Proficiency Assessment'),
            navigationTitle: t('assessment_header.key_language_proficiency', 'Key Language Proficiency'),
            data: getData('Key_Language_Proficiency'),
        };
        const performanceData = {
            id: '4',
            title: t('assessment_panel_title.performance_task', 'Performance Tasks (Summative Assessment)'),
            navigationTitle: t('assessment_header.performance_task', 'Performance Task'),
            data: getData('Performance_Task'),
        };
        const benchmarkData = {
            id: '5',
            title: t('assessment_panel_title.benchmarks_and_mcas', 'Benchmark and Multiple Choice Assessments'),
            navigationTitle: t('assessment_header.benchmarks_and_mcas', 'Benchmark and MCAs'),
            data: getData('Benchmark_and_Multiple_Choice'),
        };
        const assessmentsExplorersData = [
            preExplorationData,
            formativeAssessmentData,
            proficiencyData,
            performanceData,
            benchmarkData,
        ];
        setAssessmentsExplorers(assessmentsExplorersData);
    };

    useEffect(() => {
        if (data) {
            setupAssessmentData();
        }
    }, [data]);

    if (loading) {
        return <LoadingPlaceholder />;
    }

    const refs = assessmentsExplorers.reduce((acc, value) => {
        acc[value.id] = createRef();
        return acc;
    }, {});

    const handleNavClick = id =>
        refs[id].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });

    const backToTop = () => {
        ref.current.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        assessmentTabRef.current.scrollIntoView(true);
    };

    return (
        isAssessment && (
            <AssessmentContainer ref={ref}>
                <MainContainer>
                    <HeaderComponent assessmentData={assessmentsExplorers} handleNavClick={handleNavClick} />
                    <BodyContainer>
                        {assessmentsExplorers.map(dataItem => (
                            <Section ref={refs[dataItem.id]} key={dataItem.id}>
                                <HeadSection>
                                    <HeaderTitle>{dataItem.title}</HeaderTitle>
                                    <BackTopButton key="backToTop" secondary onClick={backToTop}>
                                        {t('general.contents_navigator_back_to_the_top', 'Back to Top')}
                                    </BackTopButton>
                                </HeadSection>
                                {dataItem.data.length > 0 ? (
                                    dataItem.data.map(item => <AssessmentPanel item={item.attributes} key={item.id} />)
                                ) : (
                                    <StyledDiv>
                                        {t('assessment_panel.no_assessment', 'No assessments in the section')}
                                    </StyledDiv>
                                )}
                            </Section>
                        ))}
                    </BodyContainer>
                </MainContainer>
            </AssessmentContainer>
        )
    );
};
Assessments.defaultProps = {
    gradeId: '',
    moduleId: '',
};
Assessments.propTypes = {
    gradeId: PropTypes.string,
    moduleId: PropTypes.string,
    assessmentTabRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
        .isRequired,
};
export default Assessments;
