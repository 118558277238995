export const actions = {
    SET_LESSON_ANSWERS: 'SET_LESSON_ANSWERS',
    UPDATE_LESSON_ANSWERS: 'UPDATE_LESSON_ANSWERS',
};

export const setLessonAnswers = payload => ({
    type: actions.SET_LESSON_ANSWERS,
    payload,
});

export const updateLessonAnswers = payload => ({
    type: actions.UPDATE_LESSON_ANSWERS,
    payload,
});
