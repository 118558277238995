import { actions } from '../../../actions/answers/answersInputs';

const answersInputsReducer = (state = false, action) => {
    switch (action.type) {
        case actions.DISABLE_ANSWER_INPUTS:
            return false;
        case actions.ENABLE_ANSWER_INPUTS:
            return true;
        default:
            return state;
    }
};

export default answersInputsReducer;
