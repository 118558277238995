import isVersionId from '../isVersionId';

const localePrefixLength = 4;

const getResourceId = contentId => {
    if (!contentId) {
        return null;
    }

    const isLocaled = isVersionId(contentId);
    return isLocaled ? contentId.substring(localePrefixLength) : contentId;
};

export default getResourceId;
