import React from 'react';
import termsOfUseUrl from './urls';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import RoleErrorComponent from '../../components/Error/RoleErrorComponent';

const allowedRoles = ['TEACHER', 'STUDENT', 'SCHOOL_ADMIN', 'DISTRICT_OWNER'];

const TermsOfUse = React.lazy(() => import(/* webpackChunkName: "TermsOfUse" */ './TermsOfUse'));
const routes = () => {
    const routesMap = [];
    routesMap.push(
        <AuthenticatedLayoutRoute
            path={termsOfUseUrl}
            component={TermsOfUse}
            key={TermsOfUse}
            RoleErrorComponent={RoleErrorComponent}
            roles={allowedRoles}
            withSwoosh
        />,
    );
    return routesMap;
};
export default routes;
