import { gql } from '@apollo/client';

export const userPreferencesFragment = gql`
    fragment Preferences on UserPreferences {
        id
        settings
    }
`;

export const userPreferencesQuery = gql`
    query TSUser {
        currentUser {
            ... on TSTeacherUser {
                id
                userPreferences {
                    ...Preferences
                }
            }
            ... on TSStudentUser {
                id
                userPreferences {
                    ...Preferences
                }
            }
        }
    }
    ${userPreferencesFragment}
`;

export const userPreferenciesMutation = gql`
    mutation changePreferences($input: UserPreferencesByUserIdInput!) {
        upsertUserPreferencesByUserId(input: $input) {
            id
            settings
        }
    }
`;
