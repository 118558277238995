import { actions } from '../../actions/printModeForLesson';

const printModeForLessonReducer = (state = false, action) => {
    switch (action.type) {
        case actions.DISABLE_PRINT_MODE_FOR_LESSON:
            return false;
        case actions.ENABLE_PRINT_MODE_FOR_LESSON:
            return true;
        default:
            return state;
    }
};

export default printModeForLessonReducer;
