import { actions } from '../../../actions/answers/isUnsavedAnswersConfirmationDialogOpen';

const isUnsavedAnswersConfirmationDialogOpenReducer = (state = false, action) => {
    switch (action.type) {
        case actions.UNSAVED_ANSWERS_CONFIRMATION_DIALOG_OPEN:
            return true;
        case actions.UNSAVED_ANSWERS_CONFIRMATION_DIALOG_CLOSE:
            return false;
        default:
            return state;
    }
};

export default isUnsavedAnswersConfirmationDialogOpenReducer;
