import React from 'react';
import { Film, ClickOnScreen, GraphInScreen, Document } from '@twigeducation/ts-fe-components/components/Icons';

const RESOURCE_TYPES = [
    {
        label: 'Family Outreach Letters',
        value: 'familyLetters',
        icon: <Document size={30} color="#fff" />,
    },
    {
        label: 'Guides',
        value: 'guides',
        icon: <Document size={30} color="#fff" />,
    },
    {
        label: 'Handouts',
        value: 'handouts',
        icon: <Document size={30} color="#fff" />,
    },
    {
        label: 'Interactives',
        value: 'interactives',
        icon: <ClickOnScreen size={30} color="#fff" />,
    },
    {
        label: 'Lab Kit Materials',
        value: 'labKitMaterials',
        icon: <Document size={30} color="#fff" />,
    },
    {
        label: 'PE Progressions',
        value: 'peProgressions',
        icon: <Document size={30} color="#fff" />,
    },
    {
        label: 'Trackers',
        value: 'trackers',
        icon: <Document size={30} color="#fff" />,
    },
    {
        label: 'Videos',
        value: 'videos',
        icon: <Film size={30} color="#fff" />,
    },
    {
        label: 'Visuals',
        value: 'visuals',
        icon: <GraphInScreen size={30} color="#fff" />,
    },
    {
        label: 'Rubrics',
        value: 'rubrics',
        icon: <Document size={30} color="#fff" />,
    },
];

export default RESOURCE_TYPES;
