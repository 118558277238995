import { actions } from '../../actions/lessonAnswers';

const lessonAnswers = (state = {}, action) => {
    switch (action.type) {
        case actions.SET_LESSON_ANSWERS:
            return action.payload;
        case actions.UPDATE_LESSON_ANSWERS: {
            return {
                answers: [
                    ...state.answers.filter(answer => !action.payload.find(newAnswer => newAnswer.id === answer.id)),
                    ...action.payload,
                ],
            };
        }
        default:
            return state;
    }
};

export default lessonAnswers;
