import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import styled, { ThemeProvider } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { ReactModal, LoadingPlaceholder, theme } from '@twigeducation/ts-fe-components';
import { closeWelcomeTour, startWelcomeTour } from '../../store/actions/teacherOnboarding/showWelcomeTour';
import StartOnboardingSlide from './StartOnboardingSlide';
import EndOnboardingSlide from './EndOnboardingSlide';
import OnboardingSlides from './OnboardingSlides';
import { userPreferencesQuery, userPreferenciesMutation } from '../../queries/userPreferences.query';
import { useTranslation } from '../../hooks/useTranslation';
import { getSlideData } from './slideData';
import useUserRole from '../../hooks/useUserRole';

const ParentContainer = styled.div`
    height: 100%;
    border-radius: 0 0 ${props => props.theme.modalBorderRadius} ${props => props.theme.modalBorderRadius};
    background-size: cover;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjUuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9ImIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA5NDAgNzA5LjMiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDk0MCA3MDkuMzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiNGRkZGRkY7fQoJLnN0MXtmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtmaWxsOiNGMEVDRjc7fQo8L3N0eWxlPgo8ZyBpZD0iYyI+Cgk8ZyBpZD0iZCI+CgkJPHJlY3QgY2xhc3M9InN0MCIgd2lkdGg9Ijk0MCIgaGVpZ2h0PSI3MDkuMyIvPgoJCTxnIGlkPSJlIj4KCQkJPGcgaWQ9ImYiPgoJCQkJPGcgaWQ9ImciPgoJCQkJCTxwYXRoIGlkPSJoIiBjbGFzcz0ic3QxIiBkPSJNMC40LDI0Mi4xYzEzOC41LTcuOSwyOTIuMSw0MC45LDQ2MC43LDE0Ni40YzE0OC43LDkzLDMwOC4zLDEwOS44LDQ3OC45LDUwLjV2MjcwLjNIMC41TDAuNCwyNDIuMQoJCQkJCQlMMC40LDI0Mi4xTDAuNCwyNDIuMXoiLz4KCQkJCTwvZz4KCQkJPC9nPgoJCTwvZz4KCTwvZz4KPC9nPgo8L3N2Zz4K');
`;

const TeacherOnboardingModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const showWelcomeTour = useSelector(state => state.showWelcomeTour);
    const [selectedSlide, setCurrentSlide] = useState('start');
    const [slideData, setSlideData] = useState();
    const [setUserPreferencies] = useMutation(userPreferenciesMutation);
    const [
        getUserPreferencesData,
        { called: getUserPreferencesDataCalled, data: userPreferencesData, error, loading },
    ] = useLazyQuery(userPreferencesQuery);
    const currentUser = userPreferencesData?.currentUser;
    const userPreferences = currentUser?.userPreferences;
    const userSettings = userPreferences?.settings;
    const teacherOnboarded = userSettings?.teacher_onboarded;
    const role = useUserRole();
    const primaryLanguage = useSelector(store => store.i18n.primaryLanguage);

    useEffect(() => {
        setSlideData(getSlideData(t));
    }, []);

    const breakpointsModalSize = {
        l: {
            width: '65vw',
            // NOTE: If the modal is showing videos, the height needs to be auto, otherwise it looks bad when resizing
            height: typeof selectedSlide === 'number' ? 'auto' : '80vh',
        },
        m: {
            width: '85vw',
            // NOTE: If the modal is showing videos, the height needs to be auto, otherwise it looks bad when resizing
            height: typeof selectedSlide === 'number' ? 'auto' : '80vh',
        },
    };

    useEffect(() => {
        if (userPreferencesData) {
            if (!teacherOnboarded && primaryLanguage === 'en-US') {
                dispatch(startWelcomeTour());

                setUserPreferencies({
                    variables: {
                        input: {
                            userId: currentUser.id,
                            settings: {
                                ...userSettings,
                                teacher_onboarded: true,
                            },
                        },
                    },
                });
            }
        } else if (role === 'TEACHER' && !getUserPreferencesDataCalled) {
            getUserPreferencesData();
        }
    }, [userPreferencesData]);

    const doItLaterOnClickHandler = () => {
        setCurrentSlide('end');
    };

    const closeModalHandler = () => {
        if (selectedSlide === 'start' || typeof selectedSlide === 'number') {
            setCurrentSlide('end');
            return;
        }

        dispatch(closeWelcomeTour());

        // Reset when closed
        setCurrentSlide('start');
    };

    const nextSlideOnClickHandler = () => {
        let result = selectedSlide;

        if (selectedSlide === 'start') {
            result = 0;
        } else if (selectedSlide + 1 === slideData.length) {
            result = 'finished';
        } else {
            result += 1;
        }

        setCurrentSlide(result);
    };

    const previousSlideOnClickHandler = () => {
        let result = selectedSlide;

        if (selectedSlide === 0) {
            result = 'start';
        } else {
            result -= 1;
        }

        setCurrentSlide(result);
    };

    const getSlideComponent = () => {
        switch (selectedSlide) {
            case 'start':
                return (
                    <StartOnboardingSlide
                        nextSlideFunc={nextSlideOnClickHandler}
                        doItLaterFunc={doItLaterOnClickHandler}
                    />
                );
            case 'end':
            case 'finished':
                return (
                    <EndOnboardingSlide
                        finishedOnboarding={selectedSlide === 'finished'}
                        closeFunc={closeModalHandler}
                    />
                );
            default:
                return (
                    <OnboardingSlides
                        currentSlide={selectedSlide}
                        totalSlides={slideData.length}
                        nextSlideFunc={nextSlideOnClickHandler}
                        previousSlideFunc={previousSlideOnClickHandler}
                        {...slideData[selectedSlide]}
                    />
                );
        }
    };

    if (loading) {
        return <LoadingPlaceholder />;
    }

    if (!showWelcomeTour || !userPreferencesData || error) {
        return null;
    }

    return (
        <ThemeProvider theme={{ ...theme, modalHorizontalPadding: 0 }}>
            <ReactModal
                deactivateModal={closeModalHandler}
                breakpointsModalSize={breakpointsModalSize}
                titleVisible={false}
            >
                <ParentContainer>{getSlideComponent()}</ParentContainer>
            </ReactModal>
        </ThemeProvider>
    );
};

export default TeacherOnboardingModal;
