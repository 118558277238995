import React from 'react';
import { Route } from 'react-router-dom';
import aboutUrl from './urls';

class Redirect extends React.Component {
    constructor(props) {
        super(props);
        const landingDomain = window.config.LANDING_DOMAIN || 'https://www.twigscience.com';
        window.location.replace(`https://${landingDomain}${aboutUrl}`);
    }

    render() {
        return null;
    }
}

const route = <Route path={aboutUrl} exact render={() => <Redirect />} />;

export default route;
