import React from 'react';
import { AsyncComponent } from '@twigeducation/async-component';
import AsyncComponentError from '../Error/AsyncComponentError';

const GameProvider = props => (
    <AsyncComponent
        appName="twig-games"
        componentName="TwigGames"
        errorComponent={AsyncComponentError}
        providerType="GAME"
        {...props}
    />
);

export default GameProvider;
