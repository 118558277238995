import React from 'react';
import PropTypes from 'prop-types';
import { VisualProvider } from '@twigeducation/react-visuals';
import { cloudinary } from '@twigeducation/react-cloudinary-image';
import { LoadingPlaceholder } from '@twigeducation/ts-fe-components';
import { NotFound, PageError, PageHeadAttributes } from '@twigeducation/allsorts';
import breakpoint from '../../helpers/breakpoint';
import DownloadButton from '../../components/DownloadButton';
import './scss/Visual.scss';

const visualSizes = {
    small: {
        width: breakpoint.small.max,
        height: 1000,
    },
    medium: {
        width: breakpoint.medium.max,
        height: 1000,
    },
    large: {
        width: 1600,
        height: 1000,
    },
};

const PRINT_VISUAL_SIZE_OPTIONS = {
    if: 'iw_gt_3508',
    crop: 'scale',
    width: 3508,
};

const PRINT_VISUAL_DEFAULT_OPTION = {
    format: 'jpg',
    secure: true,
    quality: '80',
};

const getDownloadVisualOptions = transformationName => ({
    transformation: [
        PRINT_VISUAL_SIZE_OPTIONS,
        PRINT_VISUAL_DEFAULT_OPTION,
        transformationName || 'top_left_white_watermark',
    ],
});

const Visual = ({
    assetId,
    match,
    data: {
        visual: { title, summary, image },
    },
    t,
}) => {
    const downloadVisualUrl = cloudinary.url(
        image.cloudinary.publicId,
        getDownloadVisualOptions(image.cloudinary.overlay.watermark),
    );

    return (
        <div className="visual__container">
            <PageHeadAttributes title={`${t('page_titles.visual', 'Visual')} - ${title}`} description={summary} />
            <h1>{title}</h1>
            {summary && <p className="visual__summary">{summary}</p>}
            <DownloadButton
                fileUrl={downloadVisualUrl}
                title={title}
                fileExtension="jpg"
                data-at="visual-download-button"
                buttonText={t('lesson.file_download', 'Download')}
            />
            <VisualProvider
                id={assetId}
                type={match.params.type}
                Loading={() => <LoadingPlaceholder />}
                NotFound={NotFound}
                options={{ crop: 'fit' }}
                PageError={PageError}
                sizes={visualSizes}
            />
        </div>
    );
};

Visual.propTypes = {
    assetId: PropTypes.string.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            type: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    data: PropTypes.shape({
        visual: PropTypes.shape({
            title: PropTypes.string.isRequired,
            image: PropTypes.shape({
                cloudinary: PropTypes.shape({
                    publicId: PropTypes.string.isRequired,
                }),
            }),
            summary: PropTypes.string,
        }).isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
};

export { getDownloadVisualOptions };
export default Visual;
