import store from '../../store/store';

export const setHeader = ({ headers = {} }) => ({
    headers: {
        ...headers,
        'x-product-variant-code': store.getState().subscriptions.productVariantCode,
    },
});

export default (operation, forward) => {
    const hasProductVariantCode = store.getState().subscriptions && store.getState().subscriptions.productVariantCode;
    if (hasProductVariantCode) {
        operation.setContext(setHeader);
    }
    return forward(operation);
};
