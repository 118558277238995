import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../components/Footer';
import Header from '../components/Header';
import './scss/LayoutRoute.scss';
import './scss/LayoutRouteSwooosh.scss';

const LayoutWithSwoosh = ({ component: Component, withConstellation, ...props }) => {
    const className = withConstellation
        ? 'layout-route__wrapper layout-route__with-constellation'
        : 'layout-route__wrapper layout-route';
    return (
        <div className={className}>
            <a href="#content" className="layout-route__skip-to-content">
                Skip to content
            </a>
            <div className="layout-route__content">
                <Header />
                <main id="content" className="layout-route__content-main layout-route__content-main-swooosh">
                    <Component {...props} />
                </main>
            </div>
            <div className="layout-route__footer layout-route__footer-swooosh">
                <Footer />
            </div>
        </div>
    );
};

LayoutWithSwoosh.defaultProps = {
    withConstellation: true,
};

LayoutWithSwoosh.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    withConstellation: PropTypes.bool,
};

export default LayoutWithSwoosh;
