import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@twigeducation/ts-fe-components';
import { useTranslation } from '../../../hooks/useTranslation';
import {
    ParentContainer,
    DescriptionWrapper,
    ImageWrapper,
    LineWrapper,
    ContentContainer,
    ButtonContainer,
} from './StartOnboardingSlide.styles';
import rocketShipLanding from '../../../../svg/rocket-landing.svg';

const StartOnboardingSlide = ({ nextSlideFunc, doItLaterFunc }) => {
    const { t } = useTranslation();

    return (
        <ParentContainer>
            <ImageWrapper src={rocketShipLanding} />
            <ContentContainer>
                <h1>
                    {t(
                        'teacher_onboarding.start_onboarding_slide.title',
                        'Get ready to begin your journey with Twig Science!',
                    )}
                </h1>
                <LineWrapper />
                <DescriptionWrapper>
                    {t(
                        'teacher_onboarding.start_onboarding_slide.description',
                        "We've put together a series of short videos to help you learn the basics",
                    )}
                </DescriptionWrapper>
                <ButtonContainer>
                    <Button onClick={nextSlideFunc} primary>
                        {t('teacher_onboarding.start_onboarding_slide.start_button_label', 'Start')}
                    </Button>
                    <Button onClick={doItLaterFunc} link>
                        {t('teacher_onboarding.start_onboarding_slide.do_it_later_button_label', "I'll do this later")}
                    </Button>
                </ButtonContainer>
            </ContentContainer>
        </ParentContainer>
    );
};

StartOnboardingSlide.propTypes = {
    doItLaterFunc: PropTypes.func.isRequired,
    nextSlideFunc: PropTypes.func.isRequired,
};

export default StartOnboardingSlide;
