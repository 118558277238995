import { useEffect, useState } from 'react';

const useElementSize = ref => {
    const [elementSize, setElementSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = () => {
            setElementSize({
                width: ref.current ? ref.current.offsetWidth : undefined,
                height: ref.current ? ref.current.offsetHeight : undefined,
            });
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return elementSize;
};

export default useElementSize;
