import React from 'react';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import visualUrl from './urls';

const Visual = React.lazy(() => import(/* webpackChunkName: "Visual" */ '.'));

const route = <AuthenticatedLayoutRoute path={`${visualUrl}/:type/:slug/:id`} exact component={Visual} />;

const generateVisualRoute = (slug, id, type = '') => {
    const imageType = type || 'default';
    return `${visualUrl}/${imageType}/${slug}/${btoa(id)}`;
};

export { generateVisualRoute };

export default route;
