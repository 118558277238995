import React from 'react';
import PropTypes from 'prop-types';

const withTocsApp = Component => {
    const WithTocsApp = props => {
        if (!props.data) return <Component {...props} />;
        const { tocsApp } = props.data.getUserProductSubscription;

        return <Component {...props} tocsApp={tocsApp} />;
    };

    WithTocsApp.defaultProps = {
        data: null,
    };

    WithTocsApp.propTypes = {
        data: PropTypes.shape({
            getUserProductSubscription: PropTypes.shape({
                tocsApp: PropTypes.string,
            }),
        }),
    };

    return WithTocsApp;
};

export default withTocsApp;
