export const actions = {
    DISABLE_PRINT_MODE_FOR_LESSON: 'DISABLE_PRINT_MODE_FOR_LESSON',
    ENABLE_PRINT_MODE_FOR_LESSON: 'ENABLE_PRINT_MODE_FOR_LESSON',
};

export const enablePrintModeForLesson = () => ({
    type: actions.ENABLE_PRINT_MODE_FOR_LESSON,
});

export const disablePrintModeForLesson = () => ({
    type: actions.DISABLE_PRINT_MODE_FOR_LESSON,
});
