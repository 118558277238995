import { actions } from '../../../actions/answers/exampleAnswers';

const examplesAnswersReducer = (state = false, action) => {
    switch (action.type) {
        case actions.HIDE_EXAMPLE_ANSWERS:
            return false;
        case actions.SHOW_EXAMPLE_ANSWERS:
            return true;
        default:
            return state;
    }
};

export default examplesAnswersReducer;
