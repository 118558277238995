import update from 'immutability-helper';
import { actions } from './actions';
import getResourceId from '../../../../helpers/getResourceId';

const updateItemStatus = (state, item) => {
    const resourceId = getResourceId(item.itemId);
    return update(state, {
        [item.classId]: classRoster =>
            update(classRoster || {}, {
                [resourceId]: resId =>
                    update(resId || {}, {
                        active: { $set: item.active },
                        complete: { $set: item.complete },
                    }),
            }),
    });
};

const updateItem = (state, item) => {
    const resourceId = getResourceId(item.itemId);
    return update(state, {
        [item.classId]: classRoster =>
            update(classRoster || {}, {
                [resourceId]: resId =>
                    update(resId || {}, {
                        active: { $set: item.active },
                        className: { $set: item.className },
                        complete: { $set: item.complete },
                        itemType: { $set: item.itemType },
                    }),
            }),
    });
};

const scheduledContentReducer = (state, action) => {
    let newState = {};

    switch (action.type) {
        case actions.SET_SCHEDULED_CONTENT:
            action.value.forEach(item => {
                newState = updateItem(newState, item);
            });

            break;
        case actions.SET_SCHEDULED_CONTENT_ITEM_STATUS:
            newState = updateItemStatus(state, action.value);
            break;
        default:
            newState = { ...state };
    }

    return newState;
};

export default scheduledContentReducer;
