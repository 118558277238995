import React from 'react';
import creditsUrl from './urls';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';

import RoleErrorComponent from '../../components/Error/RoleErrorComponent';

const Credits = React.lazy(() => import(/* webpackChunkName: "Credits" */ './Credits'));
const allowedRoles = ['TEACHER', 'STUDENT', 'SCHOOL_ADMIN', 'DISTRICT_OWNER'];

const routes = [
    <AuthenticatedLayoutRoute
        component={Credits}
        exact
        key={Credits}
        path={creditsUrl}
        RoleErrorComponent={RoleErrorComponent}
        roles={allowedRoles}
        withFooter
    />,
];

export default routes;
