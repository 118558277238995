import React from 'react';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import getResourceId from '../../helpers/getResourceId';
import lessonUrl from './urls';
import RoleErrorComponent from '../../components/Error/RoleErrorComponent';

const Lesson = React.lazy(() => import(/* webpackChunkName: "Lesson" */ '.'));
const LeveledReaders = React.lazy(() => import(/* webpackChunkName: "LeveledReaders" */ './LeveledReaders'));

const allowedRoles = ['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER'];

const routes = [
    <AuthenticatedLayoutRoute
        path={`${lessonUrl}/core_ancillary_lesson/:slug/:id`}
        exact
        component={LeveledReaders}
        key={LeveledReaders}
        roles={allowedRoles}
        withSwoosh
        RoleErrorComponent={RoleErrorComponent}
    />,
    <AuthenticatedLayoutRoute
        path={`${lessonUrl}/:lessonType/:slug/:id`}
        exact
        component={Lesson}
        key={Lesson}
        roles={allowedRoles}
        withSwoosh
        RoleErrorComponent={RoleErrorComponent}
    />,
    <AuthenticatedLayoutRoute
        path={`${lessonUrl}/:lessonType/:slug/:id/:mode(overview|full-lesson|digital-resources)`}
        exact
        component={Lesson}
        key={Lesson}
        roles={allowedRoles}
        withSwoosh
        RoleErrorComponent={RoleErrorComponent}
    />,
];

export const generateLessonUrl = (slug, id, lessonType) =>
    `${lessonUrl}/${lessonType}/${slug}/${btoa(getResourceId(id))}`;

export default routes;
