import { connect } from 'react-redux';
import { compose } from 'redux';
import { GraphQLStateHandlerFactory } from '@twigeducation/graphql-state-handler';
import Marketingsites from './Marketingsites';
import { marketingSiteQuery, params, tsK6MarketingSiteUrlQuery, paramsForStrapi } from './Marketingsites.query';
import { addMarketingsites } from '../../store/actions/marketingsites';
import withMarketingSites from './withMarketingSites';
import withFeatureFlaggedQueryByCurrentState from '../withFeatureFlaggedQueryByCurrentState';

const mapStateToProps = state => ({
    tocsApp: state.subscriptions.tocsApp,
});

const mapDispatchToProps = dispatch => ({
    addMarketingsites: sites => dispatch(addMarketingsites(sites)),
});
const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFeatureFlaggedQueryByCurrentState({
        defaultParams: params,
        featureParams: paramsForStrapi,
        defaultQuery: marketingSiteQuery,
        featureQuery: tsK6MarketingSiteUrlQuery,
    }),
    GraphQLStateHandlerFactory('data', true),
    withMarketingSites,
);

export default enhance(Marketingsites);
