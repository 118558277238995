/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const gradeSummaryQuery = gql`
    query TSGradeSummaries($language: String) {
        TSGradeSummaries(language: $language) {
            colorCode
            id
            title
            versionId
            modules {
                id
                title
                order
            }
            __typename
        }
    }
`;
