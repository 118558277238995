import { actions } from '../../actions/notifications';

export const initialState = {
    type: null,
    inProgress: false,
    success: false,
    error: false,
    noNetwork: false,
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case actions.CUSTOM:
            return { ...initialState, ...payload };

        case actions.SUCCESS: {
            return {
                type: payload,
                success: true,
                inProgress: false,
                error: false,
                noNetwork: false,
            };
        }

        case actions.ERROR: {
            return {
                type: payload,
                success: false,
                inProgress: false,
                error: true,
                noNetwork: false,
            };
        }

        case actions.NO_NETWORK: {
            return {
                type: payload,
                success: false,
                inProgress: false,
                error: false,
                noNetwork: true,
            };
        }

        case actions.IN_PROGRESS: {
            return {
                type: payload,
                success: false,
                inProgress: true,
                error: false,
                noNetwork: false,
            };
        }

        case actions.RESET_NOTIFICATIONS:
            return initialState;

        default:
            return state;
    }
};
