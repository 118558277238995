import React from 'react';
import { Icon } from '@twigeducation/ts-fe-components';
import PropTypes from 'prop-types';
import {
    StyledLesson,
    StyledContentIcons,
    LessonNumber,
    LessonHeading,
    StyledLessonsIcons,
    StyledLink,
    StyledIconText,
} from './LessonItem.styled';
import { activeIcon, fadeIcon } from '../../../../constants/tableOfContents';
import { useTranslation } from '../../../../hooks/useTranslation';
import { UnderlineSection } from '../TableOfContents/TableOfContents.styled';

const LessonItem = ({ lesson }) => {
    const { t } = useTranslation();

    return (
        <>
            <StyledLesson>
                <LessonNumber>{lesson.typeTitle}</LessonNumber>
                <LessonHeading>{lesson.title}</LessonHeading>
                <StyledLessonsIcons>
                    {lesson.url !== null && (
                        <a
                            href={lesson.url}
                            rel="noopener noreferrer"
                            target="_blank"
                            aria-label={t('table_of_contents.open_lesson', 'Open lesson')}
                        >
                            <StyledLink>
                                <Icon.RightArrow title="" color="#ffffff" size={10} aria-hidden />
                            </StyledLink>
                        </a>
                    )}
                    <StyledContentIcons>
                        <Icon.ClickOnScreen
                            aria-label={t('table_of_contents.digital_investigation', 'Digital Investigation')}
                            size={30}
                            color={lesson.digital === 'Y' ? activeIcon : fadeIcon}
                            aria-hidden={lesson.digital !== 'Y'}
                        />
                    </StyledContentIcons>
                    <StyledContentIcons>
                        <Icon.HandsOnActivity
                            aria-label={t('table_of_contents.hands_on_investigation', 'Hands-On Investigation')}
                            size={30}
                            color={lesson.handsOn === 'Y' ? activeIcon : fadeIcon}
                            aria-hidden={lesson.handsOn !== 'Y'}
                        />
                    </StyledContentIcons>
                    <StyledContentIcons>
                        <Icon.BookMagnifyingGlass
                            aria-label={t('table_of_contents.reading_for_evidence', 'Reading for Evidence')}
                            size={30}
                            color={lesson.reading === 'Y' ? activeIcon : fadeIcon}
                            aria-hidden={lesson.reading !== 'Y'}
                        />
                    </StyledContentIcons>
                    <StyledContentIcons isAssessment={lesson.isAssessment}>
                        <Icon.CheckedBox
                            aria-label={t('table_of_contents.three_d_assessment', '3-D Assessment')}
                            size={14}
                            color={lesson.threeD === 'Y' ? activeIcon : fadeIcon}
                            aria-hidden={lesson.threeD !== 'Y'}
                        />
                        <StyledIconText isActive={lesson.threeD === 'Y'} aria-hidden>
                            {t('table_of_contents.three_d', '3-D')}
                        </StyledIconText>
                    </StyledContentIcons>
                    <StyledContentIcons>
                        <Icon.Film
                            aria-label={t('video_investigation.three_d_assessment', 'Video Investigation')}
                            size={30}
                            color={lesson.video === 'Y' ? activeIcon : fadeIcon}
                            aria-hidden={lesson.video !== 'Y'}
                        />
                    </StyledContentIcons>
                    <StyledContentIcons>
                        <Icon.PaintPallet
                            aria-label={t('video_investigation.art_and_design', 'Art and Design')}
                            size={30}
                            color={lesson.art === 'Y' ? activeIcon : fadeIcon}
                            aria-hidden={lesson.art !== 'Y'}
                        />
                    </StyledContentIcons>
                </StyledLessonsIcons>
            </StyledLesson>
            <UnderlineSection className="underline_section" />
        </>
    );
};

LessonItem.propTypes = {
    lesson: PropTypes.shape({
        application: PropTypes.string,
        art: PropTypes.string,
        description: PropTypes.string,
        digital: PropTypes.string,
        dqNumber: PropTypes.string,
        grade: PropTypes.string,
        gradePackId: PropTypes.string,
        handsOn: PropTypes.string,
        language: PropTypes.string,
        moduleNumber: PropTypes.string,
        modulePackId: PropTypes.string,
        order: PropTypes.string,
        reading: PropTypes.string,
        threeD: PropTypes.string,
        title: PropTypes.string,
        typeTitle: PropTypes.string,
        url: PropTypes.string,
        video: PropTypes.string,
        id: PropTypes.string,
    }).isRequired,
};

export default LessonItem;
