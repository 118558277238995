import { actions } from '../../../actions/turnIn/turnInButtonState';

const turnInReducer = (state = true, action) => {
    switch (action.type) {
        case actions.DISABLE_TURNIN_BUTTON:
            return false;
        case actions.ENABLE_TURNIN_BUTTON:
            return true;
        default:
            return state;
    }
};

export default turnInReducer;
