import { gql } from '@apollo/client';

export const query = gql`
    query productSubForProduct($userId: String!) {
        getUserProductSubscription(userId: $userId, productCode: "TSK6") {
            productVariantCode
            languages {
                ietfTag
                purchased
            }
            territoryName
            territoryCode
            tocsApp
            configuration {
                value
                key
            }
        }
        getUserProductContentEntitlement(userId: $userId, productCode: "TSK6") {
            types {
                purchased
                identifier
            }
        }
    }
`;

export const params = {
    skip: props => !props.userId,
    options: props => ({
        variables: {
            userId: props.userId,
        },
    }),
};
