import styled from 'styled-components';
import breakpoints from '../../helpers/breakpoint';

const StyledContainer = styled.div`
    width: 100vw;
    padding-top: 2.5rem;
`;

const StyledContents = styled.div`
    width: 60%;
    margin: auto;
    @media screen and (max-width: ${breakpoints.medium.max}px) {
        width: 90%;
    }
`;

const DropdownsContainer = styled.div`
    display: flex;
    gap: 18px;
    margin-bottom: 3rem;
    & > div {
        width: 50%;
    }
`;

const StyledContentBackground = styled.div`
    background: #fefefe;
`;

const StyledContent = styled.div`
    width: 75%;
    margin: auto;
    @media screen and (max-width: ${breakpoints.medium.max}px) {
        width: 100%;
    }
`;

export { StyledContainer, StyledContents, DropdownsContainer, StyledContentBackground, StyledContent };
