/* eslint-disable import/prefer-default-export */
import {
    ADD_TERRITORY,
    ADD_PRODUCT_VARIANT_CODE,
    ADD_CONTENT_ENTITLEMENT,
    ADD_TOCS_APP,
    ADD_PRODUCT_CONFIGURATION,
} from './actionTypes';

export const addTerritory = territory => ({
    type: ADD_TERRITORY,
    value: territory,
});

export const addProductVariantCode = productVariantCode => ({
    type: ADD_PRODUCT_VARIANT_CODE,
    value: productVariantCode,
});

export const addContentEntitlement = contentEntitlement => ({
    type: ADD_CONTENT_ENTITLEMENT,
    value: contentEntitlement,
});

export const addTocsApp = tocsApp => ({
    type: ADD_TOCS_APP,
    value: tocsApp,
});
export const addConfiguration = config => ({
    type: ADD_PRODUCT_CONFIGURATION,
    value: config,
});
