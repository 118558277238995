import styled from 'styled-components';
import { activeIcon, fadeIcon } from '../../../../constants/tableOfContents';

const StyledLesson = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        height: 100px;
        flex-direction: row;
    }
`;

const StyledLessonsIcons = styled.div`
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    padding-top: 20px;

    @media screen and (min-width: ${props => props.theme.grid.breakpoints[1]}) {
        width: 40%;
        justify-content: right;
        flex-wrap: nowrap;
    }
`;

const StyledLink = styled.div`
    background-color: #0d8382;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    transition: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px 6px 0px 0px;

    &:hover {
        cursor: pointer;
        background-color: #33ba92;
    }
`;

const LessonNumber = styled.span`
    padding-top: 20px;

    @media screen and (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        text-align: right;
        padding: 20px;
        width: 20%;
    }
`;

const LessonHeading = styled.span`
    text-align: left;
    padding-top: 20px;
    color: #343a72;
    font-weight: bold;

    @media screen and (min-width: ${props => props.theme.grid.breakpoints[0]}) {
        width: 40%;
    }
`;

const StyledContentIcons = styled.div`
    margin: 5px;
    color: ${props => (props.isAssessment ? activeIcon : fadeIcon)};
`;

const StyledIconText = styled.span`
    color: ${props => (props.isActive ? activeIcon : fadeIcon)};
`;

export {
    StyledLesson,
    StyledContentIcons,
    LessonNumber,
    LessonHeading,
    StyledLessonsIcons,
    StyledLink,
    StyledIconText,
};
