import React from 'react';
import { Route } from 'react-router-dom';
import { WithAuthentication } from '@twigeducation/oidc-client-react';
import fileUrl from './urls';
import UpdatePasswordRequired from '../../components/UpdatePasswordRequired';

const FileViewer = React.lazy(() => import(/* webpackChunkName: "FileViewer" */ '.'));

const route = (
    <Route
        path={`${fileUrl}/:slug/:id`}
        exact
        render={props => (
            <WithAuthentication>
                <UpdatePasswordRequired>
                    <FileViewer {...props} />
                </UpdatePasswordRequired>
            </WithAuthentication>
        )}
    />
);

const generateFileViewerRoute = (slug, id) => `${fileUrl}/${slug}/${btoa(id)}`;

export { generateFileViewerRoute };

export default route;
