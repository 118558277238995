export const actions = {
    UPDATE_ANSWER: 'UPDATE_ANSWER',
    REMOVE_ALL_ANSWERS: 'REMOVE_ALL_ANSWERS',
    REMOVE_ONE_ANSWER: 'REMOVE_ONE_ANSWER',
    UPDATE_EDITABLE_TABLE_ANSWER: 'UPDATE_EDITABLE_TABLE_ANSWER',
};

export const updateAnswer = ({ answer, itemId }) => ({
    type: actions.UPDATE_ANSWER,
    value: { [itemId]: { answer, itemId } },
});

export const removeOneAnswer = key => ({
    type: actions.REMOVE_ONE_ANSWER,
    value: { key },
});

export const removeAllAnswers = () => ({
    type: actions.REMOVE_ALL_ANSWERS,
});

export { disableAnswerInputs, enableAnswerInputs } from './answersInputs';
export { hideExampleAnswers, showExampleAnswers } from './exampleAnswers';
export { default as setAnswersUserId } from './setAnswersUserId';
export {
    unsavedAnswersConfirmationDialogOpen,
    unsavedAnswersConfirmationDialogClose,
} from './isUnsavedAnswersConfirmationDialogOpen';
