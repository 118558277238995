import React from 'react';
import Route from '../../layout/AuthenticatedLayoutRoute';
import teacherDashboardUrl from './urls';

const TeacherDashboard = React.lazy(() => import(/* webpackChunkName: "TeacherDashboard" */ '.'));

const allowedRoles = ['TEACHER', 'SUPERUSER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER'];

const routes = [
    <Route
        path={`${teacherDashboardUrl}/:classid?`}
        exact
        component={TeacherDashboard}
        key={TeacherDashboard}
        withConstellation={false}
        roles={allowedRoles}
        withSwoosh
    />,
];

export default routes;
