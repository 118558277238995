import React from 'react';
import { AsyncComponent } from '@twigeducation/async-component';
import AsyncComponentError from '../Error/AsyncComponentError';

const TSVideoProvider = props => (
    <AsyncComponent
        appName="twig-video-mfe"
        componentName="VideoProvider"
        errorComponent={AsyncComponentError}
        {...props}
    />
);

export default TSVideoProvider;
