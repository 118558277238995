import { useEffect } from 'react';

const Marketingsites = props => {
    useEffect(() => {
        props.addMarketingsites(props.marketingSites);
    }, [props.marketingSites]);

    return null;
};

export default Marketingsites;
