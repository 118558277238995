import React from 'react';
import PropTypes from 'prop-types';
import { AsyncComponent } from '@twigeducation/async-component';
import { withSignoutRedirect } from '@twigeducation/oidc-client-react';
import { connect, useDispatch, useSelector } from 'react-redux';
import onLogout from '../../helpers/onLogout';
import { startWelcomeTour } from '../../store/actions/teacherOnboarding/showWelcomeTour';
import AsyncComponentError from '../Error/AsyncComponentError';

const Header = ({ territory, disableHeader, ...props }) => {
    const dispatch = useDispatch();
    const primaryLanguage = useSelector(store => store.i18n.primaryLanguage);

    return (
        !disableHeader && (
            <AsyncComponent
                {...props}
                assessmentsPlatformUrl={window.config.ASSESSMENTS_PLATFORM_URL}
                authenticationUrl={window.config.AUTHENTICATION_URL}
                twigScienceToolsUrl={window.config.TWIG_SCIENCE_TOOLS_URL}
                twigScienceReporterUrl={window.config.TWIG_SCIENCE_REPORTER_URL}
                teacherOnboardingFunc={primaryLanguage === 'en-US' ? () => dispatch(startWelcomeTour()) : null}
                appName="ts-header"
                componentName="Header"
                errorComponent={AsyncComponentError}
                territory={territory}
                product="twigscienceK-6"
            />
        )
    );
};

Header.defaultProps = {
    territory: 'unknown-territory',
    disableHeader: false,
};

Header.propTypes = {
    territory: PropTypes.string,
    disableHeader: PropTypes.bool,
};

export default withSignoutRedirect(onLogout)(
    connect(state => ({
        territory: state?.subscriptions?.territory?.name,
        disableHeader: state?.isLti,
    }))(Header),
);
