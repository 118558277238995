import { actions } from '../../actions/answers';

export const answersReducer = (state = {}, action) => {
    switch (action.type) {
        case actions.REMOVE_ALL_ANSWERS:
            return {};
        case actions.UPDATE_ANSWER:
            return { ...state, ...action.value };
        case actions.REMOVE_ONE_ANSWER: {
            const { key } = action.value;
            const answersCopy = { ...state };
            if (answersCopy[key]) {
                delete answersCopy[key];
            }
            return {
                ...answersCopy,
            };
        }
        default:
            return state;
    }
};

export { default as answersInputsReducer } from './answersInputs';
export { default as answersUserIdReducer } from './answersUserId';
export { default as exampleAnswersReducer } from './exampleAnswers';
export { default as hasUnsavedAnswersReducer } from './hasUnsavedAnswers';
export { default as isUnsavedAnswersConfirmationDialogOpenReducer } from './isUnsavedAnswersConfirmationDialogOpen';
