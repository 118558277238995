import React from 'react';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import getResourceId from '../../helpers/getResourceId';
import moduleUrl from './urls';

const Module = React.lazy(() => import(/* webpackChunkName: "Module" */ '.'));

const routes = [
    <AuthenticatedLayoutRoute
        component={Module}
        exact
        key={Module}
        path={`${moduleUrl}/:slug/:id`}
        roles={['TEACHER', 'SCHOOL_ADMIN', 'DISTRICT_OWNER']}
    />,
];

export const generateModuleUrl = (slug, id) => {
    const resourceId = btoa(getResourceId(id));
    return `${moduleUrl}/${slug}/${resourceId}`;
};

export default routes;
