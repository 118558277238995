import React from 'react';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import scienceGlossaryUrl from './urls';

const ScienceGlossary = React.lazy(() => import(/* webpackChunkName: "ScienceGlossary" */ '.'));

const routes = [
    <AuthenticatedLayoutRoute
        path={`${scienceGlossaryUrl}/grade/:gradeVersionId`}
        exact
        component={ScienceGlossary}
        key="science_glossary_grade_version_id"
        withConstellation={false}
        withSwoosh
    />,
    // deprecated
    <AuthenticatedLayoutRoute
        path={`${scienceGlossaryUrl}/:gradeId`}
        exact
        component={ScienceGlossary}
        key="science_glossary_grade_id"
        withConstellation={false}
        withSwoosh
    />,
];

export const generateScienceGlossaryUrl = (gradeId, gradeVersionId) => {
    if (gradeVersionId) return `${scienceGlossaryUrl}/grade/${btoa(gradeVersionId)}`;

    // deprecated
    return `${scienceGlossaryUrl}/${btoa(gradeId)}`;
};

export default routes;
