import { connect } from 'react-redux';
import { removeMessage } from './store/actions';
import Messages from './Messages';

const mapStateToProps = state => ({
    message: state.messages.message || '',
});

const mapDispatchToProps = dispatch => ({
    removeMessage: () => {
        dispatch(removeMessage());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
