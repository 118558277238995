import React from 'react';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import { spanishUrl, spanishStudentUrl } from './urls';
import IframePage from '../IframePages';
import SpanishStudentIFrame from './SpanishStudentIFrame';
import { DISTRICT_OWNER, SCHOOL_ADMIN, TEACHER, STUDENT } from '../../constants/userRoles';
import RoleErrorComponent from '../../components/Error/RoleErrorComponent';

const SPANISH_WIX_URL = 'https://www.go4.twigscience.com/esv1?mode=t1';

const routes = [
    <AuthenticatedLayoutRoute
        key={spanishUrl}
        exact
        path={spanishUrl}
        component={() => <IframePage title="Español" urls={{ default: SPANISH_WIX_URL }} />}
        roles={[DISTRICT_OWNER, SCHOOL_ADMIN, TEACHER]}
        RoleErrorComponent={RoleErrorComponent}
    />,
    <AuthenticatedLayoutRoute
        key={spanishStudentUrl}
        exact
        path={spanishStudentUrl}
        component={SpanishStudentIFrame}
        roles={[DISTRICT_OWNER, SCHOOL_ADMIN, TEACHER, STUDENT]}
        RoleErrorComponent={RoleErrorComponent}
    />,
];

export default routes;
