import { actions } from '../../../actions/answers/setAnswersUserId';

const answersUserIdReducer = (state = null, action) => {
    switch (action.type) {
        case actions.SET_ANSWERS_USER_ID:
            return action.payload;
        default:
            return state;
    }
};

export default answersUserIdReducer;
