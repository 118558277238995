import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Layout = ({ withConstellation, withHeader, withFooter, component: Component, ...props }) => {
    const className = withConstellation
        ? 'layout-route__wrapper layout-route__with-constellation'
        : 'layout-route__wrapper layout-route';
    return (
        <div className={className}>
            <a href="#content" className="layout-route__skip-to-content">
                Skip to content
            </a>
            <div className="layout-route__content">
                {withHeader && <Header />}
                <main id="content" className="layout-route__content-main">
                    <Component {...props} />
                </main>
            </div>
            {withFooter && (
                <div className="layout-route__footer">
                    <Footer />
                </div>
            )}
        </div>
    );
};

Layout.defaultProps = {
    withConstellation: true,
    withFooter: true,
    withHeader: true,
};

Layout.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    withConstellation: PropTypes.bool,
    withFooter: PropTypes.bool,
    withHeader: PropTypes.bool,
};

export default Layout;
