import React from 'react';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import audioClipUrl from './urls';

const AudioClip = React.lazy(() => import(/* webpackChunkName: "AudioClip" */ '.'));

const route = (
    <AuthenticatedLayoutRoute path={`${audioClipUrl}/:slug/:id`} exact component={AudioClip} key={AudioClip} />
);

const generateAudioClipRoute = (slug, id) => `${audioClipUrl}/${slug}/${btoa(id)}`;

export { generateAudioClipRoute };

export default route;
