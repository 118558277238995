import React from 'react';
import { Redirect } from 'react-router';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import pages from './pages';
import legacyPages from './legacyPages';

const IframePage = React.lazy(() => import(/* webpackChunkName: "IframePage" */ '.'));

const routes = () => {
    const redirectRoutes = legacyPages.map(({ from, to }) => <Redirect key={from} from={from} to={to} />);
    const pageRoutes = pages.map(({ path, ...pageProps }) => (
        <AuthenticatedLayoutRoute
            path={path}
            exact
            component={() => <IframePage {...pageProps} />}
            key={path}
            withConstellation={false}
            withStickyHeader
            withFooter={false}
        />
    ));
    return [...redirectRoutes, ...pageRoutes];
};

export default routes;
