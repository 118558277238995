import React from 'react';
import evaluationRubricsUrl from './url';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import RoleErrorComponent from '../../components/Error/RoleErrorComponent';

const allowedRoles = ['TEACHER', 'STUDENT', 'SCHOOL_ADMIN', 'DISTRICT_OWNER'];
const EvaluationRubrics = React.lazy(() => import(/* webpackChunkName: "EvaluationRubrics" */ './EvaluationRubrics'));
const routes = [
    <AuthenticatedLayoutRoute
        component={EvaluationRubrics}
        exact
        key={EvaluationRubrics}
        path={evaluationRubricsUrl}
        RoleErrorComponent={RoleErrorComponent}
        roles={allowedRoles}
    />,
];
export default routes;
