export const actions = {
    HIDE_EXAMPLE_ANSWERS: 'HIDE_EXAMPLE_ANSWERS',
    SHOW_EXAMPLE_ANSWERS: 'SHOW_EXAMPLE_ANSWERS',
};

export const hideExampleAnswers = () => ({
    type: actions.HIDE_EXAMPLE_ANSWERS,
});

export const showExampleAnswers = () => ({
    type: actions.SHOW_EXAMPLE_ANSWERS,
});
