import React from 'react';
import { useSelector } from 'react-redux';
import systemRequirementsUrl from './url';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import RoleErrorComponent from '../../components/Error/RoleErrorComponent';

const allowedRoles = ['TEACHER', 'STUDENT', 'SCHOOL_ADMIN', 'DISTRICT_OWNER'];
const SystemRequirements = React.lazy(() =>
    import(/* webpackChunkName: "SystemRequirements" */ './SystemRequirements'),
);
const routes = () => {
    const routesMap = [];
    const wixSystemrequiRementsFF = useSelector(state => state.featureFlags['wix-system-requirements']);
    if (wixSystemrequiRementsFF) {
        routesMap.push(
            <AuthenticatedLayoutRoute
                component={SystemRequirements}
                exact
                key={SystemRequirements}
                path={systemRequirementsUrl}
                RoleErrorComponent={RoleErrorComponent}
                roles={allowedRoles}
                withSwoosh
            />,
        );
    } else {
        routesMap.push(
            <AuthenticatedLayoutRoute
                key={SystemRequirements}
                path={systemRequirementsUrl}
                component={SystemRequirements}
                RoleErrorComponent={RoleErrorComponent}
                roles={allowedRoles}
            />,
        );
    }
    return routesMap;
};
export default routes;
