import React from 'react';
import Route from '../../layout/AuthenticatedLayoutRoute';
import twigAdminDashboardUrl from './urls';

const TwigAdminDashboard = React.lazy(() => import(/* webpackChunkName: "TwigAdminDashboard" */ '.'));

const routes = [
    <Route
        path={twigAdminDashboardUrl}
        exact
        component={TwigAdminDashboard}
        key="/TwigAdminDashboard"
        roles={['TWIGADMIN']}
        withConstellation={false}
        withSwoosh
    />,
];

export default routes;
