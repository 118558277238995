import requirements from '../Assets/requirements.json';

export function versionChecker(version, minRequiredVersion) {
    const current = version.split('.').map(currVersion => parseInt(currVersion, 10));
    const required = minRequiredVersion.split('.').map(minVersion => parseInt(minVersion, 10));
    const length = Math.min(current.length, required.length);

    for (let i = 0; i < length; i += 1) {
        if (current[i] > required[i]) {
            return true;
        }
        if (current[i] < required[i]) {
            return false;
        }
    }
    return current.length >= required.length;
}

export function browserChecker(browser, browserVersion, systemType) {
    const version = requirements[systemType].browsers[browser];
    if (version === undefined) {
        return [false, false];
    }
    return [true, versionChecker(browserVersion, version)];
}

export function checkifTablet(UA) {
    return /iPad|Android(?!.*Mobile)|Tablet|Kindle|Silk|PlayBook|Windows.*Touch(?!.*Phone)|Puffin(?!.*(IP|AP|WP))/i.test(
        UA,
    );
}
