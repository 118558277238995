export default [
    {
        title: 'Authors',
        urls: { default: 'https://www.twigscience.com/authors-ca-internal-link/' },
        path: '/authors',
    },
    {
        title: 'Privacy Policy',
        urls: { default: 'https://twigeducation.com/privacy/' },
        path: '/privacy-policy',
    },
    {
        title: 'Program Overview',
        urls: { default: 'https://www.go.twigscience.com/program-overview-framework-alignmen' },
        path: '/program-overview',
    },
    {
        title: 'Resources',
        urls: { default: 'https://www.go.twigscience.com/resources-trailers' },
        path: '/resources',
    },
    {
        title: 'Review Program',
        urls: {
            default: 'https://go3.twigscience.com/docs/california/',
        },
        path: '/review-program',
    },
    {
        title: 'Correlations Maps',
        urls: {
            default:
                'https://go3.twigscience.com/doc/delivering-the-three-dimensions-of-the-oas-s/' +
                'correlations-and-standards-maps/',
        },
        path: '/correlations-maps',
    },
];
