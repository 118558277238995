import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'redux';
import { GraphQLStateHandlerFactory } from '@twigeducation/graphql-state-handler';
import UpdatePasswordRequired from './UpdatePasswordRequired';

export const passwordResetRequiredQuery = gql`
    query currentUser {
        currentUser {
            ... on TSTeacherUser {
                id
                passwordResetRequired
                __typename
            }
            ... on TSSchoolAdminUser {
                id
                passwordResetRequired
                __typename
            }
            ... on TSDistrictOwnerUser {
                id
                passwordResetRequired
                __typename
            }
            ... on TSStudentUser {
                id
                passwordResetRequired
                __typename
            }
            ... on TSSchoolAdminUser {
                id
                passwordResetRequired
                __typename
            }
            __typename
        }
    }
`;

const enhance = compose(
    graphql(passwordResetRequiredQuery, { fetchPolicy: 'network-only' }),
    GraphQLStateHandlerFactory('data', true),
);

export default enhance(UpdatePasswordRequired);
