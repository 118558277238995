export const actions = {
    START_WELCOME_TOUR: 'START_WELCOME_TOUR',
    CLOSE_WELCOME_TOUR: 'CLOSE_WELCOME_TOUR',
};

export const startWelcomeTour = () => ({
    type: actions.START_WELCOME_TOUR,
});

export const closeWelcomeTour = () => ({
    type: actions.CLOSE_WELCOME_TOUR,
});
