import styled from 'styled-components';
import { Button as FECButton } from '@twigeducation/ts-fe-components';

export const Layout = styled.div`
    width: 700px;
    max-width: calc(100% - 2rem);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Image = styled.img`
    margin-top: 64px;
    margin-bottom: 32px;
`;

export const Button = styled(FECButton)`
    margin: 32px;
`;

export const Heading = styled.h4`
    line-height: 1.4;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0.72222rem;

    @media print, screen and (min-width: 40em) {
        font-size: 1.38889rem;
    }
`;
