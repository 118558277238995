const breakpoint = {
    small: {
        min: 0,
        max: 639,
    },
    medium: {
        min: 640,
        max: 1023,
    },
    large: {
        min: 1024,
    },
};

export default breakpoint;
